import * as React from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Table from "@material-ui/core/Table/Table";
import {TableCell, TableRow, IconButton, Icon, Grid, TablePagination} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody/TableBody";
import {IAnyProps} from "../../../interfaces/any-props.interface";
import selector from "../../../selectors/question";
import {amendmentsSearchAction, questionLoader, questionRequest} from "../../../actions/question";
import EnhancedTableHead from "../../../components/table/EnhancedTableHead";
import {IQuestion} from "../../../models/question.interface";
import AmendmentsModal from "./AmendmentsModal";
import {amendmentsAnswerModalOpen} from "../../../actions/modals";
import {ROLE_TESTER, ROLE_ACCOUNT_MANAGER} from "../../../constants/roles";
import PermissionBlock from "../../../components/auth/PermissionBlock";
import {formatDateWithTime} from "../../../helpers/date-formatter";
import {isChangesExist} from "../../../helpers/props-checker";
import {TableNoItems} from "../../../components/table/TableNoItems";

interface IProps {
  questionSearch: (id: string, pageNumber: number, itemsPerPage: number) => void;
  data: IQuestion[];
  questionModal: (item) => void;
}

class AmendmentsTable extends React.Component<IProps & RouteComponentProps<IAnyProps> & IAnyProps, {}> {
  componentDidMount() {
    this.props.questionSearch(
      this.props.match.params.test,
      this.props.pageNumber,
      this.props.itemsPerPage
    );
  }

  componentWillReceiveProps(nextProps) {
    if (isChangesExist(["pageNumber", "itemsPerPage",], nextProps, this.props)) {
      this.props.questionSearch(
        this.props.match.params.test,
        this.props.pageNumber,
        this.props.itemsPerPage
      );
    }
  }

  handleChangePage = (event, page) => {
    this.props.setPageNumber(page + 1);
  };

  handleChangeRowsPerPage = event => {
    this.props.setItemsPerPage(event.target.value);
  };

  openMessage = (item: IQuestion) => () => {
    this.props.questionModal(item);
  };

  render() {
    if (!this.props.isLoaded) {
      return <TableNoItems asLoading />;
    }

    if (!this.props.data.length) {
      return <TableNoItems />;
    }

    return (
      <div className="table-responsive styled-table">
        <Grid container>
          <Grid item md={12}>
            <AmendmentsModal/>
            <Table padding={"dense"}>
              <EnhancedTableHead columns={["Date submitted", "By", "Question", "Answer", "",]}/>
              <TableBody>
                {this.props.data.map(item => (
                  <TableRow key={item.id}>
                    <TableCell>
                      {item.createdAt && formatDateWithTime(item.createdAt)}
                      {item.dateAnswered && ` to ${formatDateWithTime(item.dateAnswered)}`}
                    </TableCell>
                    <TableCell>
                      {item.authorName}
                    </TableCell>
                    <TableCell style={{wordWrap: "break-word", whiteSpace: "normal"}}>
                      {item.content}
                    </TableCell>
                    <TableCell style={{wordWrap: "break-word", whiteSpace: "normal"}}>
                      {item.answer}
                    </TableCell>
                    <PermissionBlock roles={[ROLE_TESTER, ROLE_ACCOUNT_MANAGER]}>
                      <TableCell>
                        {!item.answer &&
                        <IconButton onClick={this.openMessage(item)}>
                          <Icon>textsms</Icon>
                        </IconButton>
                        }
                      </TableCell>
                    </PermissionBlock>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={this.props.totalItems}
              rowsPerPage={this.props.itemsPerPage}
              page={this.props.pageNumber - 1}
              backIconButtonProps={{
                "aria-label": "Previous Page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page",
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default connect<IAnyProps, IAnyProps, IAnyProps>(
  (state) => ({
    data: selector.getItems(state),
    isLoaded: selector.getIsLoaded(state),
    itemsPerPage: selector.getItemsPerPage(state),
    pageNumber: selector.getPageNumber(state),
    totalItems: selector.getTotalItems(state),
  }),
  {
    loadItems: questionRequest.getItems,
    setItemsPerPage: questionLoader.setItemsPerPage,
    setPageNumber: questionLoader.setPageNumber,
    questionSearch: amendmentsSearchAction,
    questionModal: amendmentsAnswerModalOpen,
  }
)(withRouter(AmendmentsTable));
