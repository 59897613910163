import {ITesterIncoming} from "models/tester-profile.interface";
import {postImage, postSecuredImage} from "api/requests/image";
import {IAnyProps} from "interfaces/any-props.interface";
import {updateTesterProfile} from "./testers";
import {API_SECURE_DOCUMENTS_ENDPOINT_URL} from "constants/urls";
import {handleHttpErrors} from "helpers/errors/http-error-handler";
import * as Types from 'constants/action-type';

const crudType = Types.USERS;

export const putTesterProfileAsync = (values: ITesterIncoming & IAnyProps, backRoute: string, FORM_NAME: string) => async dispatch => {
  try {
    let profilePictureId, certificateId;

    if (values.picture instanceof File) {

      profilePictureId = (await postImage(values.picture)).id;
      values.picture = profilePictureId;
    }

    if (values.companyCrestCertificate instanceof File) {
      certificateId = (await postSecuredImage(values.companyCrestCertificate)).id;
      values.companyCrestCertificate = API_SECURE_DOCUMENTS_ENDPOINT_URL + certificateId;
    }
    dispatch(updateTesterProfile(values, backRoute, FORM_NAME));
  } catch (e) {
    handleHttpErrors(e, dispatch);
  }
};
