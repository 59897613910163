import * as React from "react";
import { connect } from "react-redux";
import {Redirect, Route, RouteComponentProps, Switch} from "react-router-dom";
import Profile from "./profile/Profile";
import ExternalProfile from "./external-profile/ExternalProfile";
import Bookings from "./bookings/Bookings";
import BookingRequests from "./booking-requests/BookingRequests";
import SideNav from "../common/SideNav";
import PrivateRoute from "../../components/auth/PrivateRoute";
import TestDetails from "../client-tester/test-details/TestDetails";
import {ROLE_TESTER, ROLE_EMPLOYEE, ROLE_EXTERNAL, ROLE_MANAGER, ROLE_CONSULTANT, ROLE_ACCOUNT_MANAGER} from "../../constants/roles";
import Test from "../client-tester/test-details/Test";
import RulesAndEngagement from "./rules-and-engagement/RulesAndEngagement";
import Header from "../guest/Header";
import Footer from "../guest/Footer";
import { getRoles } from "../../selectors/auth";
import { ROLE } from "../../constants/roles";
import Admin from "./admin/Admin";
import TesterDetails from "./testers/TesterDetails";
import {Group} from "../../constants/group";
import {isInputConsultant} from "../client-tester/test-details/BookingProposal";
import { Permission } from "constants/permission";

const settingsUrl = "/tester";

interface IStateToProps {
  roles: ROLE[];
}

class Tester extends React.Component<IStateToProps & RouteComponentProps<{}>, {}> {
  isExcudedRole(roles, excludedRoles) {
    return roles.filter(role => excludedRoles.includes(role)).length > 0;
  }

  canEditProfile() {
    const {roles} = this.props
    return roles.indexOf(ROLE_EMPLOYEE) === -1 && roles.indexOf(ROLE_EXTERNAL) === -1 && roles.indexOf(ROLE_CONSULTANT) === -1
  }

  render() {
    const roles = this.props.roles;
    const baseUrl = this.props.match.path;
    return (
      <div>
        <Header/>
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 col-xl-2 bg-white pt-4">
                <div className="">
                  <SideNav baseUrl={baseUrl} />
                </div>
              </div>
              <div className="col-lg-9 col-xl-10 content">
                <Switch>
                  <Redirect from="/tester" exact to="/tester/account"/>
                  <PrivateRoute
                    path="/tester/booking-requests/:test"
                    component={TestDetails}
                    roles={[ROLE_TESTER, ROLE_MANAGER]}
                  />
                  <PrivateRoute
                    path="/tester/bookings/:test"
                    component={Test}
                    roles={[ROLE_TESTER, ROLE_MANAGER, ROLE_ACCOUNT_MANAGER]}
                    roles_with_permissions={{
                      [ROLE_ACCOUNT_MANAGER]: [Permission.CAN_MANAGE_AVORD_CONSULTANCY_COMPANY_MANAGER],
                    }}
                  />

                  <Route path="/tester/booking-requests" component={BookingRequests}/>
                  <Route path="/tester/bookings" component={Bookings}/>
                  <PrivateRoute exact path="/tester/admin/:id" component={TesterDetails}  group={Group.MANAGER}/>
                  <PrivateRoute path="/tester/admin" component={Admin} group={Group.MANAGER}/>


                  {this.canEditProfile() ? (
                      <>
                        <Route path="/tester/account" component={Profile} />
                        <Route
                            path="/tester/rules-and-engagement"
                            component={RulesAndEngagement}
                        />
                      </>
                  ) : (
                      <>
                        <Route
                            path="/tester/account"
                            component={ExternalProfile}
                        />
                        {isInputConsultant(roles) && (
                            <Route
                                path="/tester/rules-and-engagement"
                                component={RulesAndEngagement}
                            />
                        )}
                      </>
                  )}
                </Switch>
                <Footer/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    roles: getRoles(state),
  };
}

export default connect<IStateToProps, {}>(mapStateToProps, {})(Tester);
