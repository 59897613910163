import * as React from "react";
import ReduxTextField from "../../../components/forms/ReduxTextField";
import { FieldGeneric } from "../../../components/forms/FieldGeneric";
import Grid from "@material-ui/core/Grid/Grid";
import { Link } from "react-router-dom";
import ReduxSelectField from "../../../components/forms/ReduxSelectField";
import { MenuItem } from "@material-ui/core";
import PermissionBlock from "../../../components/auth/PermissionBlock";
import * as Roles from "../../../constants/roles";
import {
  FIELD_TITLE_MAX_LENGTH,
  MAX_RISK_FIELD_LENGTH,
  MIN_RISK_FIELD_LENGTH,
} from "./const";

interface IRiskForm {
  isVulnerabilities?: number;
  backRoute: string;
}

const RiskForm: React.SFC<IRiskForm> = ({ backRoute, isVulnerabilities }) => {
  const renderItem = () => {
    return [1, 2, 3, 4, 5].map((item) => (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    ));
  };

  const handleKeyPressOnNumberField = (event: React.ChangeEvent<HTMLInputElement> & KeyboardEvent): void => {
    if (event.ctrlKey || event.metaKey || event.altKey) {
      return;
    }

    const char = event.key;
    const fieldValue = event.target.value;
    const maxFieldValueLength = 2;
    const maxFieldValueLengthWithDot = 3;

    if (!char) {
      return;
    }

    if ((char < "0" || char > "9") && char !== ".") {
      event.preventDefault();
    }

    if (fieldValue.includes(".")) {
      if (fieldValue.length >= maxFieldValueLengthWithDot) {
        event.preventDefault();
      }
    } else {
      if (fieldValue.length >= maxFieldValueLength) {
        event.preventDefault();
      }
    }
  };

  const checkInputData = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const inputValue = +event.target.value;
    const maxInputValue = 10;

    if (inputValue > maxInputValue) {
      event.preventDefault();
    }
  };

  return (
    <Grid container spacing={40}>
      <Grid item xs={12}>
        <FieldGeneric
          inputProps={{
            minLength: MIN_RISK_FIELD_LENGTH,
            maxLength: FIELD_TITLE_MAX_LENGTH,
          }}
          name="title"
          label="Heading *"
          multiline
          component={ReduxTextField}
        />
        <FieldGeneric
          inputProps={{
            minLength: MIN_RISK_FIELD_LENGTH,
            maxLength: MAX_RISK_FIELD_LENGTH,
            style: { overflow: "hidden" },
          }}
          name="description"
          label="Detail *"
          multiline
          component={ReduxTextField}
        />
        <FieldGeneric
          name="impact"
          label="Impact *"
          component={ReduxSelectField}
        >
          {renderItem()}
        </FieldGeneric>
        <FieldGeneric
          name="likelihood"
          label="Likelihood *"
          component={ReduxSelectField}
        >
          {renderItem()}
        </FieldGeneric>
        <PermissionBlock roles={[Roles.ROLE_TESTER, Roles.ROLE_ACCOUNT_MANAGER]}>
          {!!isVulnerabilities && (
            <FieldGeneric
              name="vulnerabilityScore"
              label="CVSS Score *"
              component={ReduxTextField}
              onKeyPress={handleKeyPressOnNumberField}
              onChange={checkInputData}
            />
          )}
        </PermissionBlock>
        <div className="text-right mt-4">
          <Link className="btn-cancel" to={backRoute}>
            Cancel
          </Link>
          <button type="submit" className="btn-accept">Save</button>
        </div>
      </Grid>
    </Grid>
  );
};

export default RiskForm;
