import * as React from 'react';
import {RouteComponentProps, withRouter} from "react-router-dom";
import {IAnyProps} from "interfaces/any-props.interface";
import {Button, MenuItem, Select} from "@material-ui/core";
import {ISpecialism} from "models/specialism.interface";
import Paper from "@material-ui/core/Paper";
import {downloadDocumentFile} from "api/requests/document-download";
import {getItem} from "api/requests/specialisms";
import {extractId} from "helpers/converter-helper";
import {connect} from "react-redux";
import {openSnackbar} from "actions/snackbar";
import { API_DOCUMENTS_ENDPOINT_URL } from "constants/urls";

type IProps = RouteComponentProps<any> & IAnyProps & {
  specialisms: ISpecialism[];
  generalSpecialism: ISpecialism;
  downloadDocumentFile: (id: number) => any;
  openSnackbar: (...any) => void;
};

class ReportTemplateDownload extends React.Component<IProps, {}> {
  baseUrl = this.props.match.url;
  state = {
    value: 0,
  };

  handleDownloadClick = (event) => {
    const specialism = this.props.specialisms[event.currentTarget.dataset.specialismindex];
    let specialismId = specialism['@id'] && extractId(specialism['@id']);

    if (specialism.parentType !== null) {
      specialismId = specialism.parentType && specialism.parentType['@id']
        && extractId(specialism.parentType['@id']);
    }

    this.downloadFile(specialismId);
  };

  downloadFile = (specialismId) => {
    getItem(specialismId)
      .then(data => {
        if (data.reportTemplateDocument) {
          this.props.downloadDocumentFile(
            data.reportTemplateDocument.toString().replace(API_DOCUMENTS_ENDPOINT_URL, '')
          );
        } else {
          this.props.openSnackbar('There is no report template for the specified specialism.', 2000);
        }
      });
  };

  handleChange = (event) => {
    this.setState({value: event.target.value});
  };

  render() {
    const {specialisms} = this.props;

    return (
      <Paper style={{overflowX: 'auto', width: '100%'}}><br/>
      <div className="container">
        <div className="d-flex justify-content-between mt-4">
          <Button
            variant="contained"
            color="primary"
            size="medium"
            data-specialismindex={this.state.value}
            onClick={this.handleDownloadClick}>
            Download
          </Button>
          <Select
            className="col-md-10"
            fullWidth={true}
            onChange={this.handleChange}
            value={this.state.value}
            style={{display: "none"}}
          >
            {specialisms.map((item: ISpecialism, index) => {
              return (
                <MenuItem
                  key={`${item.id}-${index}`}
                  value={index}>
                  {item.parentType && (item.parentType.name + ' -> ')}{item.name}
                </MenuItem>
              )
            })}
          </Select>
        </div>
        <br/>
        <div>
          <h5 style={{fontSize: 14}}>Click here if you would like to download the AVORD Testing Report template or continue to use your current report template</h5>
        </div>
        <br/>
      </div>
      </Paper>
    );
  }
}

const connected = connect<IAnyProps, IAnyProps, IAnyProps>(undefined, {
  downloadDocumentFile,
  openSnackbar,
})(ReportTemplateDownload);

export default withRouter(connected as any) as any;

