import React from "react";
import { Checkbox, TableCell } from "@material-ui/core";
import selector from "../../../../selectors/time-expenses";
import { bindActionCreators, Dispatch } from "redux";
import {
  addSelectedProjectWeeksAction,
  removeSelectedProjectWeeksAction
} from "../../../../actions/time-expenses";
import { connect } from "react-redux";
import { TE_STATUS } from "../../../../constants/timesheet-expenses";
import { TIME_SHEETS_USER_TYPE } from "../../../../types/timesheets-and-expenses";

type WeekProjectCheckboxProps = {
  projectWeekId?: number;
  addItems?: (ids: number[]) => void;
  removeItems?: (ids: number[]) => void;
  selectedProjectWeeks: number[];
  totalDays: number;
  totalExpensesAmount: number;
  status: TE_STATUS;
  userType: TIME_SHEETS_USER_TYPE;
};

const WeekProjectCheckbox = (props: WeekProjectCheckboxProps) => {
  const {
    projectWeekId,
    addItems,
    removeItems,
    selectedProjectWeeks,
    totalDays,
    totalExpensesAmount,
    status,
    userType
  } = props;

  const hasTimeExpensesLogs = totalDays > 0 || totalExpensesAmount > 0;
  let isChecked = false;
  let isDisabled = true;

  if (userType === TIME_SHEETS_USER_TYPE.CONSULTANT) {
    isDisabled =
      !hasTimeExpensesLogs ||
      (status !== TE_STATUS.STATUS_DRAFT &&
        status !== TE_STATUS.STATUS_REJECTED);
    isChecked =
      hasTimeExpensesLogs &&
      !!projectWeekId &&
      selectedProjectWeeks.includes(projectWeekId);
  }
  if (userType === TIME_SHEETS_USER_TYPE.CLIENT) {
    isDisabled = status !== TE_STATUS.STATUS_SUBMITTED;
    isChecked =
      hasTimeExpensesLogs &&
      status === TE_STATUS.STATUS_SUBMITTED &&
      selectedProjectWeeks.includes(projectWeekId as number);
  }
  if (userType === TIME_SHEETS_USER_TYPE.ADMIN) {
    isDisabled = (status !== TE_STATUS.STATUS_APPROVED && status !== TE_STATUS.STATUS_SUBMITTED);

    isChecked =
      hasTimeExpensesLogs &&
      (status === TE_STATUS.STATUS_APPROVED || status === TE_STATUS.STATUS_SUBMITTED) &&
      selectedProjectWeeks.includes(projectWeekId as number);
  }

  const toggleHandler = () => {
    if (isChecked && removeItems) {
      removeItems([projectWeekId as number]);
    }
    if (!isChecked && addItems) {
      addItems([projectWeekId as number]);
    }
  };

  return (
    <TableCell align="center" className="timesheet-cell-checkbox">
      <Checkbox
        onChange={toggleHandler}
        disabled={isDisabled}
        checked={isChecked}
      />
    </TableCell>
  );
};

function mapStateToProps(state) {
  return {
    selectedProjectWeeks: selector.getSelectedProjectWeeks(state)
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      addItems: addSelectedProjectWeeksAction,
      removeItems: removeSelectedProjectWeeksAction
    },
    dispatch
  );
};

const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(WeekProjectCheckbox);

export default connected;
