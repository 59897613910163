import * as React from "react";
import {Redirect, RouteComponentProps, withRouter} from "react-router-dom";
import {Menu, TableCell, Tooltip} from "@material-ui/core";
import {BASE_URL} from "constants/urls";
import {ITesterProfileGet} from "models/tester-profile.interface";
import {connect} from "react-redux";
import {IAnyProps} from "interfaces/any-props.interface";
import {getFavoriteTestersFiltered} from "actions";
import MenuItem from "@material-ui/core/MenuItem";
import {putItem} from "api/requests/favorite-testers";
import TesterProfileModal from "../client/virtual-team/TesterProfileModal";
import PermissionBlock from "components/auth/PermissionBlock";
import {ROLE_OWNER, ROLE_PLANNER} from "constants/roles";

interface IOwnProps {
  tester: ITesterProfileGet,
  testerEmail: string,
  Wrapper: React.ComponentType,
  isAvordFirst?: boolean,
}

type IProps = IOwnProps & RouteComponentProps<string>;

class TesterTableCell extends React.Component<IProps, {}> {
  state = {
    redirect: false,
    isTesterModalOpen: false,
    isFavorite: true,
    anchorEl: null
  };

  handleBook = () => {
    if (this.props.tester.isFavorite) {
      this.setState({redirect: true});
    } else {
      putItem(this.props.tester.id)
        .then(
          () => {
            this.setState({redirect: true});
          }
        );
    }
  };

  toggleTesterModal = () => {
    this.setState({
      isTesterModalOpen: !this.state.isTesterModalOpen,
      anchorEl: null
    });
  };

  handleClick = event => {
    this.setState({anchorEl: event.currentTarget});
  };

  handleClose = () => {
    this.setState({anchorEl: null});
  };

  renderViewModalProfile = (tester) => {
      const profileImage = tester.profileImagePath ? (
              <img src={BASE_URL + tester.profileImagePath}
                   alt={tester.testerFirstName + " " + tester.testerLastName}/>)
          : (
              <img src="/images/user.svg"
                   alt="tester"
              />)

      const ifFavorite = tester.isFavorite ? "Book again" : "Add to favorites and Book again"

      const isFavoriteBtnVisible = (tester.isXeroConnected || tester.isOwnedByCompany) && tester.user && !tester.user.deletedAt && !this.props.isAvordFirst;

        return  (
            <>
                <TesterProfileModal
                    open={this.state.isTesterModalOpen}
                    onClose={this.toggleTesterModal}
                    tester={tester}
                />
                <div className="btn-tester w-100" onClick={this.handleClick}>
                    <div className="tester-image-name">
                        {profileImage}
                        <span className="tester-cell">{tester.testerFirstName} {tester.testerLastName}</span>
                    </div>
                </div>
                <Menu
                    id="simple-menu"
                    anchorEl={this.state.anchorEl}
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleClose}
                >
                    <MenuItem onClick={this.toggleTesterModal}>View Profile</MenuItem>
                    <PermissionBlock roles={[ROLE_OWNER, ROLE_PLANNER]}>
                        {isFavoriteBtnVisible &&
                        <MenuItem onClick={this.handleBook}>
                            {ifFavorite}
                        </MenuItem>
                        }
                    </PermissionBlock>
                </Menu>
            </>
        )
    }

  render() {
    const {tester, testerEmail, Wrapper = TableCell, isAvordFirst = false} = this.props;
    const hasTesterEmail = tester && tester.user && tester.user.email

    if (this.state.redirect) {
      return <Redirect push to={`/client/schedule/test/pre-fill/${tester.id}`}/>;
    }

    return (
      <Tooltip title={testerEmail || ""}>
      <Wrapper>
        {  hasTesterEmail && this.renderViewModalProfile(tester)  }
      </Wrapper>
      </Tooltip>
    );
  }
}

export default React.memo(withRouter(TesterTableCell));
