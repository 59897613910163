import {getApi} from '../swagger/api-factory';
import {convertorFromSwagger} from "../convertors/default-convertor";
import {fetchRequestBody} from 'helpers/request-helper';
import { ITest } from "models/test.interface";

export const postDocument = (file: File) => {
  return getApi()
    .then(api => {
      return api.postDocumentCollection({file});
    })
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const createTestDocument = (test: ITest, file: File) => {
  return getApi()
    .then(api => {
      return api.createTestDocument({testId: test.id, file});
    })
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const createAdditionalTestDocument = (test: ITest, file: File) => {
  return getApi()
    .then(api => {
      return api.createAdditionalTestDocument({testId: test.id, file});
    })
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const deleteTestDocument = (testId: number, documentId: number) => {
  return getApi()
    .then(api => api.deleteTestDocument({ testId, documentId}))
    .then(fetchRequestBody);
};

export const getItem = (id) => {
  return getApi()
    .then(api => api.getDocumentItem({ id }))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const documentSearchRequest = (
  id: string | number,
) => {
  return getApi()
    .then(api => api.api_tests_test_documents_get_subresource({
      id,
    }))
    .then((response) => {
      if (response.body && response.body["hydra:member"] !== undefined) {
        return {
          items: response.body["hydra:member"],
          totalItems: response.body["hydra:totalItems"] || response.body["hydra:member"].length,
        };
      }

      return {
        items: Array.isArray(response.body) ? response.body : [response.body],
        totalItems: Array.isArray(response.body) ? response.body.length : 1,
      };
    });
};

export const additionalDocumentSearchRequest = (
  id: string | number,
) => {
  return getApi()
    .then(api => api.api_tests_additional_documents_get_subresource({
      id,
    }))
    .then((response) => {
      if (response.body && response.body["hydra:member"] !== undefined) {
        return {
          items: response.body["hydra:member"],
          totalItems: response.body["hydra:totalItems"] || response.body["hydra:member"].length,
        };
      }

      return {
        items: response.body,
        totalItems: response.body.length,
      };
    });
};

export const deleteDayDocument = (dayId: string|number, documentId: number) => {
  return getApi()
    .then(api => api.deleteDayDocument({ dayId, documentId}))
    .then(fetchRequestBody);
};

export const deleteAdditionalReportDocument = (reportId: number|string, additionalDocumentId: number|string) => {
  return getApi()
    .then(api => api.deleteAdditionalReportDocument({ reportId, additionalDocumentId }))
    .then(fetchRequestBody);
};

export const deleteReportDocument = (reportId: number|string, documentId: number|string) => {
  return getApi()
    .then(api => api.deleteReportDocument({ reportId, documentId }))
    .then(fetchRequestBody);
};

export const deleteAdditionalTestDocument = (testId: number, documentId: number) => {
  return getApi()
    .then(api => api.deleteAdditionalTestDocument({ testId, documentId }))
    .then(fetchRequestBody);
};


export const getSoWDocumentIdRequest = (id: any) => {
    return getApi().then(api => api.getDocumentId({id}));
}
