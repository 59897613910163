import {convertorFromSwagger, convertorToSwagger} from '../convertors/test';
import {fetchRequestBody} from '../../helpers/request-helper';
import { getApi, getApiJSONLD } from "api/swagger/api-factory";
import {IProposalGet} from "../../models/proposal.interface";


export const postProposalRequest = (model: IProposalGet) => {
  return getApiJSONLD()
    .then(api => api.postProposalCollection({
      proposal: convertorToSwagger(model),
    }))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const putProposalRequest = (id: string, model: IProposalGet) => {
  return getApiJSONLD()
    .then(api => api.putProposalItem({
      id,
      proposal: convertorToSwagger(model),
    }))
    .then(fetchRequestBody)
    .then(convertorFromSwagger);
};

export const updateProposalDayRateRequest = (DayRate, proposalId) => {
  return getApi()
    .then(api => api.updateProposalDayRate({
      rate: { rate: DayRate },
      id: proposalId,
    }))
    .then(fetchRequestBody);
};
