import * as React from "react";
import { Modal } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

type ComponentProps = {
  id?: number|string;
  open: boolean;
  onConfirm: (id) => void;
  onClose: () => void;
  message?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  confirmHint?: string;
  cancelHint?: string;
}

class AreYouSureModal extends React.Component<ComponentProps> {
  onClickHandler = () => {
    this.props.onConfirm(this.props.id);
    this.props.onClose();
  };

  getConfirmButton() {
    const {confirmButtonText = 'Yes'} = this.props;

    const button =  <button
      type="submit"
      className="btn-save"
      onClick={this.onClickHandler}
    >
      {confirmButtonText}
    </button>

    if (!this.props.confirmHint) {
      return button;
    }

    return <Tooltip title={this.props.confirmHint}>
      {button}
    </Tooltip>
  }


  getCancelButton = () => {
    const {onClose, cancelButtonText = 'No'} = this.props;

    const button =  <button className="btn-cancel" onClick={onClose}>
      {cancelButtonText}
    </button>;

    if (!this.props.cancelHint) {
      return button;
    }

    return <Tooltip title={this.props.cancelHint}>
      {button}
    </Tooltip>
  }

  render() {
    const {
      open,
      onClose,
      message,
    } = this.props;

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={onClose}
        className="d-flex overflow-auto"
      >
        <div className="styled-modal sm">
          <h4>Are you sure?</h4>
          {message && <p>{message}</p>}
          <hr />
          <div className="text-right">
            {this.getCancelButton()}
            {this.getConfirmButton()}
          </div>
        </div>
      </Modal>
    );
  }
}

export default AreYouSureModal;
