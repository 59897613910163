import * as React from "react";
import { NavLink, Route, Switch, withRouter } from "react-router-dom";
import RiskChart from "../risk-chart/RiskChart";
import TestDetails from "./TestDetails";
import { IAnyProps } from "interfaces/any-props.interface";
import BookingReport from "../../tester/bookings/booking-report/BookingReport";
import CompletedReports from "../../client/reports/completed-reports/CompletedReports";
import {
  ROLE_ACCOUNT_MANAGER,
  ROLE_ADMIN,
  ROLE_PLANNER,
  ROLE_TESTER,
  ROLE_VIEWER
} from "constants/roles";
import { Permission } from "constants/permission";
import PermissionBlock from "components/auth/PermissionBlock";
import BaseReportPage from "../../client/reports/report-info/BaseReportPage";
import { testRequest } from "actions/test";
import selector from "selectors/test";
import { result } from "lodash";
import { connect } from 'react-redux';
import { isEqual } from 'lodash';

interface TestProps extends IAnyProps {
  test: any;
  auth: any;
  fetchTest: (id: string) => void;
  location: any;
}

interface TestState {
  value: string;
  test?: any;
  isLoading: boolean;
  canAddReports: boolean;
}

class TestComponent extends React.Component<
  TestProps,
  TestState
> {
  baseUrl = this.props.match.url;

  state = {
    value: "details",
    isLoading: true,
    canAddReports: false,
  };

  componentDidMount() {
    const testId = result(this.props, "match.params.test", false);
    if (testId) {
      this.props.fetchTest(testId).finally(() => {
        this.setState({ isLoading: false }, this.updateCanAddReports);
      });
    }
  }

  componentDidUpdate(prevProps: TestProps) {
    const { location, test, auth } = this.props;

    if (
      location.pathname !== prevProps.location.pathname ||
      !isEqual(test, prevProps.test) ||
      !isEqual(auth, prevProps.auth)
    ) {
      this.updateCanAddReports();
    }
  }

  updateCanAddReports = () => {
    const { auth, test } = this.props;

    if (!test || !auth || !test.tester) {
      this.setState({ canAddReports: false });
      return;
    }

    const canAddReports =
      auth.roles.includes(ROLE_ACCOUNT_MANAGER) &&
      auth.permissions.includes(Permission.CAN_MANAGE_AVORD_CONSULTANCY_COMPANY_MANAGER) &&
      test.tester.isAvordConsultancyCompany;

    this.setState({ canAddReports });
  };

  ExecutiveSummaryPage = React.memo(() => <BaseReportPage pageName="Executive Summary" test={this.props.test} />);
  TechnicalReportPage = React.memo(() => <BaseReportPage pageName="Technical Report" test={this.props.test} />);

  render() {
    const { isLoading, canAddReports } = this.state;

    if (isLoading) {
      return <div>Loading...</div>;
    }

    return (
      <div>
        <PermissionBlock roles={[ROLE_PLANNER, ROLE_ADMIN, ROLE_ACCOUNT_MANAGER]}>
          <div className="styled-tabs flex-column-in-mobile">
            {canAddReports ? (
              <>
                <NavLink
                  to={`/client/reports/${this.props.match.params.test}/details`}
                  className="tab"
                  activeClassName="active"
                >
                  Details
                </NavLink>
                <NavLink
                  to={`/client/reports/${this.props.match.params.test}/risk-chart`}
                  className="tab"
                  activeClassName="active"
                >
                  Risk Chart
                </NavLink>
                <NavLink
                  to={`/tester/bookings/${this.props.match.params.test}/report`}
                  className="tab"
                  activeClassName="active"
                >
                  Completed Report(s)
                </NavLink>
              </>
            ) : (
              <>
                <NavLink
                  to={`${this.props.match.url}/details`}
                  className="tab"
                  activeClassName="active"
                >
                  Details
                </NavLink>
                <NavLink
                  to={`${this.props.match.url}/risk-chart`}
                  className="tab"
                  activeClassName="active"
                >
                  Risk Chart
                </NavLink>
                <NavLink
                  to={`${this.props.match.url}/report`}
                  className="tab"
                  activeClassName="active"
                >
                  Completed Report(s)
                </NavLink>
                <NavLink
                  to={`${this.props.match.url}/executive-summary`}
                  className="tab"
                  activeClassName="active"
                >
                  Executive Summary
                </NavLink>
                <NavLink
                  to={`${this.props.match.url}/technical-report`}
                  className="tab"
                  activeClassName="active"
                >
                  Technical Report
                </NavLink>
              </>
            )}
          </div>
        </PermissionBlock>
        <PermissionBlock roles={[ROLE_VIEWER]}>
          <div className="styled-tabs flex-column-in-mobile">
            <NavLink
              to={`${this.props.match.url}/risk-chart`}
              className="tab"
              activeClassName="active"
            >
              Risk Chart
            </NavLink>
            <NavLink
              to={`${this.props.match.url}/executive-summary`}
              className="tab"
              activeClassName="active"
            >
              Executive Summary
            </NavLink>
            <NavLink
              to={`${this.props.match.url}/technical-report`}
              className="tab"
              activeClassName="active"
            >
              Technical Report
            </NavLink>
          </div>
        </PermissionBlock>
        <PermissionBlock roles={[ROLE_TESTER]}>
          <div className="styled-tabs flex-column-in-mobile">
            <NavLink
              to={`${this.props.match.url}/details`}
              className="tab"
              activeClassName="active"
            >
              Details
            </NavLink>
            <NavLink
              to={`${this.props.match.url}/risk-chart`}
              className="tab"
              activeClassName="active"
            >
              Risk Chart
            </NavLink>
            <NavLink
              to={`${this.props.match.url}/report`}
              className="tab"
              activeClassName="active"
            >
              Completed Report(s)
            </NavLink>
          </div>
        </PermissionBlock>
        <Switch>
          <Route
            path="/tester/bookings/:test/details"
            component={TestDetails}
            key={this.props.match.params.test}
          />
          <Route
            path="/test/:testId"
            render={(props) => <RiskChart {...props} />}
            key={this.props.match.params.test}
          />
          <Route
            path="/tester/bookings/:test/report"
            component={BookingReport}
            key={this.props.match.params.test}
          />
          <Route path="/client/reports/:test/details" component={TestDetails} />
          <Route
            path="/tester/bookings/:test/risk-chart"
            render={(props) => <RiskChart canExportTable {...props} />}
            component={RiskChart}
            key={this.props.match.params.test}
          />
          <Route
            path="/client/reports/:test/risk-chart"
            render={(props) => <RiskChart canExportTable {...props} />}
            key={this.props.match.params.test}
          />
          <Route
            path="/client/reports/:test/report"
            render={(props) => <CompletedReports {...props} />}
            key={this.props.match.params.test}
          />
          <Route
            path="/client/reports/:test/executive-summary"
            render={(props) => <this.ExecutiveSummaryPage {...props} />}
            key={this.props.match.params.test}
          />
          <Route
            path="/client/reports/:test/technical-report"
            render={(props) => <this.TechnicalReportPage {...props} />}
            key={this.props.match.params.test}
          />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  test: selector.getItemById(result(props, "match.params.test", false))(state),
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchTest: (id: string) => dispatch(testRequest.getItem(id)),
});

const areEqual = (prevProps: TestProps, nextProps: TestProps) => {
  return (
    isEqual(prevProps.test, nextProps.test) &&
    isEqual(prevProps.auth, nextProps.auth) &&
    prevProps.location.pathname === nextProps.location.pathname
  );
};

const Test = React.memo(connect(mapStateToProps, mapDispatchToProps)(TestComponent), areEqual);

export default withRouter(Test);
