import * as React from "react";
import { NavLink } from "react-router-dom";
import {
    clientsMenuItems,
    plannerMenuItems,
    ownerMenuItems,
    testerMenuItems,
    companyManagerMenuItems
} from "../../constants/menus";
import PermissionBlock from "../../components/auth/PermissionBlock";
import {
    ROLE_PLANNER,
    ROLE_VIEWER,
    ROLE_OWNER,
    ROLE_TESTER,
} from "../../constants/roles";
import {Group} from "../../constants/group";

class SideNav extends React.Component<{ baseUrl: string, isAvordFirst: boolean }, {}> {
  renderItem = (item) => {
    return (
      <NavLink to={item.url} key={item.url}>
        <i className="material-icons">{item.icon}</i> {item.title}
      </NavLink>
    );
  };

  render() {
    const { baseUrl, isAvordFirst } = this.props;
    return (
      <div className="side-nav">
        <PermissionBlock roles={[ROLE_TESTER]} group={Group.TESTER}>
          {testerMenuItems(baseUrl).map(this.renderItem)}
        </PermissionBlock>
        <PermissionBlock roles={[ROLE_TESTER]} group={Group.AVORD_CONSULTANT_INPUT}>
          {testerMenuItems(baseUrl).map(this.renderItem)}
        </PermissionBlock>
        <PermissionBlock roles={[ROLE_TESTER]} group={Group.EXTERNAL_THIRD_PARTY}>
          {testerMenuItems(baseUrl).map(this.renderItem)}
        </PermissionBlock>
        <PermissionBlock roles={[ROLE_TESTER]} group={Group.EMPLOYEE}>
          {testerMenuItems(baseUrl).map(this.renderItem)}
        </PermissionBlock>
        <PermissionBlock roles={[ROLE_PLANNER]} group={Group.PLANNER}>
          {plannerMenuItems(baseUrl).map(this.renderItem)}
        </PermissionBlock>
        <PermissionBlock roles={[ROLE_VIEWER]}>
          {clientsMenuItems(baseUrl).map(this.renderItem)}
        </PermissionBlock>
        <PermissionBlock roles={[ROLE_OWNER]}>
          {ownerMenuItems(baseUrl, isAvordFirst).map(this.renderItem)}
        </PermissionBlock>
        <PermissionBlock group={Group.MANAGER}>
          {companyManagerMenuItems(baseUrl).map(this.renderItem)}
        </PermissionBlock>
      </div>
    );
  }
}

export default SideNav;
