import * as React from "react";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Icon from "@material-ui/core/Icon/Icon";
import TableCell from "@material-ui/core/TableCell";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AreYouSureModal from "./AreYouSureModal";
import { TableCellProps } from "@material-ui/core/TableCell/TableCell";
interface TableCellDeleteProps extends TableCellProps {
  onDelete: () => any;
  item: { [key: string]: any };
  iconName?: string;
  isOwner?: boolean;
}
class TableCellDelete extends React.Component<TableCellDeleteProps, any> {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  openConfirmation = () => {
    this.setState({
      isOpen: true
    });
  };

  closeConfirmation = () => {
    this.setState({
      isOpen: false
    });
  };

  delete = () => {
    this.closeConfirmation();
    this.props.onDelete();
  };

  renderMessage = () => {
    if (this.props.iconName === "link_off") {
      return "User will be removed from company";
    }
    if (this.props.isOwner) {
      return "The account will be completely deleted, including sub-accounts and Tests";
    }
    return "The account will be completely deleted";
  };

  render() {
    const { iconName, item, isOwner, onDelete, ...rest } = this.props;
    return (
      <>
        <AreYouSureModal
          open={this.state && this.state.isOpen}
          onClose={this.closeConfirmation}
          onConfirm={this.delete}
          id={item.id}
          message={this.renderMessage()}
          confirmButtonText={iconName === "link_off" ? "" : "DELETE FOREVER"}
        />
        <TableCell {...rest}>
          <IconButton onClick={this.openConfirmation}>
            <Icon style={{ color: "#778596" }}>{iconName || "delete"}</Icon>
          </IconButton>
        </TableCell>
      </>
    );
  }
}

export default TableCellDelete;
