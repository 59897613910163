import {IOptionItem} from "../interfaces/option-item.interface";

export enum Expenses {
  NO_EXPENSES = 1,
  EXPECTED_EXPENSES_AMOUNT = 2,
  DAILY_EXPENSES = 3,
}

export const EXPENSES_LIST: IOptionItem[] = [
  {
    key: Expenses.NO_EXPENSES,
    value: 'No expenses',
  },
  {
    key: Expenses.EXPECTED_EXPENSES_AMOUNT,
    value: 'Expected Expenses Amount',
  },
  {
    key: Expenses.DAILY_EXPENSES,
    value: 'Daily Expenses',
  },
];
