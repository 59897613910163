import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IAnyProps } from "interfaces/any-props.interface";
import CompleteReports from "./CompleteReports";
import ReportTemplateDownload from "./ReportTemplateDownload";
import { ISpecialism } from "models/specialism.interface";
import { Specialisms } from "constants/specialisms";
import selectorSpecialism from "selectors/specialisms";
import { specialismsRequest } from "actions/specialisms";
import { dataFetcher } from "components/dataFetcher";
import { testRequest } from "actions/test";
import selector from "selectors/test";
import { result } from "lodash";
import { ITest } from "models/test.interface";
import AmendmentsTable from "../../../client-tester/amendments/AmendmentsTable";
import AdditionalDocuments
  from "../booking-report/AdditionalDocuments/AdditionalDocuments";
import { TestStatus } from "constants/test-status";
import StatusBlock from "../../../common/StatusBlock";

const ADDITIONAL_DOCUMENT_VISIBILITY_STATUSES = [
  TestStatus.STATUS_REPORT_SUBMITTED,
  TestStatus.STATUS_REPORT_ACCEPTED,
  TestStatus.STATUS_COMPLETED,
  TestStatus.STATUS_AMENDMENT_SENT,
  TestStatus.STATUS_AMENDMENT_RESPONDED,
];

type Props = RouteComponentProps<{}> &
  IAnyProps & {
  generalSpecialism: ISpecialism;
  test: ITest;
};

class BookingReport extends React.Component<Props, {}> {
  baseUrl = this.props.match.url;

  render() {
    const { test, generalSpecialism } = this.props;
    return (
      <div className="container-fluid">
        <h4 className="main-content-header">
          {test.name}
          <span className="status-label ml-3">
            {test.status && <StatusBlock status={test.status} />}
          </span>
        </h4>
        <h4 className="mt-4">Report Guidelines</h4>
        <ReportTemplateDownload
          specialisms={test.specialisms}
          generalSpecialism={generalSpecialism}
        />
        <div className="mt-4">
          <CompleteReports testSpecialisms={test.specialisms} test={test} />
        </div>

        <h4 className="mt-4">Clarifications</h4>
        <div className="styled-block">
          <AmendmentsTable />
        </div>
        {ADDITIONAL_DOCUMENT_VISIBILITY_STATUSES.includes(test.status) && (
          <AdditionalDocuments test={test} />
        )}
      </div>
    );
  }
}

const fetched = dataFetcher(
  BookingReport,
  [
    {
      key: "test",
      selector: (state, props) =>
        selector.getItemById(result(props, "match.params.test", false))(state),
      action: props =>
        testRequest.getItem(result(props, "match.params.test", false))
    }
  ],
  [
    {
      key: "generalSpecialism",
      selector: (state, props) =>
        selectorSpecialism.getItemById(Specialisms.GENERAL_SPECIALISM_ID)(
          state
        ),
      action: props =>
        specialismsRequest.getItem(Specialisms.GENERAL_SPECIALISM_ID)
    }
  ]
);

export default withRouter(fetched as any);
