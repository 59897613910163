import * as React from "react";
import {withRouter} from "react-router-dom";
import { connect } from "react-redux";
import { ConnectedProps } from "types";
import {Dispatch} from "redux";
import { IReport } from "../../../../models/report.interface";
import { dataFetcher } from "../../../../components/dataFetcher";
import { getTestReport } from "../../../../actions/reports";
import selector from "../../../../selectors/reports";
import { downloadReportFile } from "../../../../api/requests/document-download";
import AdminBackToTest from "../../../client-tester/test-details/button/AdminBackToTest";
import {IWithRouter} from "../../../../interfaces/with-router.interface";
import StatusBlock from "../../../common/StatusBlock";
import { testRequest } from "actions/test";
import { result } from "lodash";

interface IBaseReportPageProps {
  pageName: string;
  report?: IReport;
  test?: any; // Add test prop
  downloadReportFile: (id: number) => (dispatch: Dispatch) => Promise<void>;
  match: {
    params: { test: string };
  };
}

type BaseReportPageProps = IBaseReportPageProps & IWithRouter

class BaseReportPage extends React.Component<BaseReportPageProps & PropsFromRedux> {
  componentDidMount() {
    const testId = this.props.match.params.test;
    if (!this.props.test) {
      this.props.loadTestById(testId);
    }
  }

  render() {
    console.log(this.props);
    const { report, pageName, downloadReportFile, match, test } = this.props;
    const showIntroduction = pageName === "Executive Summary" ? report && report.summaryIntroduction : report && report.technicalIntroduction;
    const showInformationGathering = pageName === "Executive Summary" ? report && report.summaryGathering : report && report.technicalGathering;
    const noDataReport = !report || !report.summaryIntroduction || !report.technicalIntroduction || +match.params.test !== report.id;

    const downloadFile = (): void => {
      const documentId = report && report.id;
      if (documentId) {
        if (downloadReportFile) {
          downloadReportFile(documentId);
        }
      }
    };

    const renderIfNotSubmitted = (): JSX.Element => {
      if (!test || !test.name || !test.status) {
        return <div>Loading...</div>;
      }

      return (
        <>
          <AdminBackToTest />
          <h4 className="main-content-header">
            {test.name}
            <span className="status-label ml-3">
              {test.status && <StatusBlock status={test.status} />}
            </span>
          </h4>
          <div className="styled-block table-no-items">
            Report not submitted yet!
          </div>
        </>
      );
    };

    const renderReportInformation = (): JSX.Element => {
      if (!test || !test.name || !test.status) {
        return <div>Loading...</div>;
      }

      return (
        <>
          <AdminBackToTest />
          <h4 className="main-content-header">
            {test.name}
            <span className="status-label ml-3">
              {test.status && <StatusBlock status={test.status} />}
            </span>
          </h4>
          <div className="styled-block">
            <div className="block-header-wrapper mb-4">
              <h4 className="block-header">{pageName}</h4>
              <button className="btn-download-pdf" onClick={downloadFile}>
                Download PDF
              </button>
            </div>
            <p>
              Introduction:{" "}
              {showIntroduction}
            </p>
            {showInformationGathering && (
                <p>
                  Information gathering:{" "}
                  {showInformationGathering}
                </p>
            )}
          </div>
        </>
      );
    };

    return (
      <>
        {noDataReport
          ? renderIfNotSubmitted()
          : renderReportInformation()}
      </>
    );
  }
}

function mapStateToProps(state, props) {
  const testId = result(props, "match.params.test", false);
  const reportById = state.reports.items.items.findIndex((item) => item.id === +testId);

  return {
    report: state.reports.items.items[reportById],
    test: props.test || selector.getItemById(testId)(state),
  };
}

const mapDispatchToProps = {
  downloadReportFile,
  loadTestById: testRequest.getItem,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const connectedComponent = connector(BaseReportPage);

const fetched = dataFetcher<IBaseReportPageProps>(connectedComponent, [
  {
    key: "report",
    selector: (state) => selector.getItems(state)[0],
    action: (props) => getTestReport(props.match.params.test),
    alwaysReceiveFreshData: true,
  },
]);

// @ts-ignore There is a known issue in TypeScript, which doesn't allow decorators to change the signature of the classes
export default withRouter(fetched);
