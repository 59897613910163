import * as React from "react";
import DocumentTable from "./tables/DocumentTable";
import {
  additionalDocumentTableColumns,
  documentTableColumns
} from "./tables/config";
import documentsSelector from "selectors/test-document";
import additionalDocumentsSelector from "selectors/test-additional-document";
import {
  downloadTestAdditionalDocumentFile,
  downloadTestDocumentFile
} from "api/requests/document-download";
import {
  additionalDocumentSearchAction,
  documentSearchAction,
  archiveTestDocument,
  archiveAdditionalTestDocument
} from "actions";
import { connect } from "react-redux";
import { RootState } from "reducers";
import { ConnectedProps } from "types";
import { ITest } from "models/test.interface";
import { TestStatus } from "constants/test-status";

type Props = ConnectedProps<typeof connector> & {
  test: ITest;
};

const STATUSES = [
  TestStatus.STATUS_DRAFT,
  TestStatus.STATUS_SCHEDULED,
  TestStatus.STATUS_BOOKING_REQUESTED,
  TestStatus.STATUS_BOOKING_REQUESTED_EDITED
];

class DocumentsBlock extends React.Component<Props> {
  componentDidMount(): void {
    const { test, getAllDocuments, getAllAdditionalDocuments } = this.props;

    getAllDocuments(test.id);

    if (!STATUSES.includes(test.status)) {
      getAllAdditionalDocuments(test.id);
    }
  }

  render() {
    const {
      test,
      isDocumentsLoaded = false,
      isAdditionalDocumentsLoaded = false,
      downloadTestDocumentFile,
      downloadTestAdditionalDocumentFile,
      deleteTestDocument,
      deleteAdditionalTestDocument,
      additionalDocuments = [],
      documents = []
    } = this.props;

    return (
      <>
        <h6 className="document-block-title">Original</h6>
        <DocumentTable
          index="id"
          data={documents}
          loading={!isDocumentsLoaded}
          columns={documentTableColumns(
            test,
            downloadTestDocumentFile,
            deleteTestDocument
          )}
        />
        {!STATUSES.includes(test.status) && (
          <>
            <h6 className="document-block-title">Additional</h6>
            <DocumentTable
              index="id"
              data={additionalDocuments}
              loading={!isAdditionalDocumentsLoaded}
              columns={additionalDocumentTableColumns(
                test,
                downloadTestAdditionalDocumentFile,
                deleteAdditionalTestDocument
              )}
            />
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  isDocumentsLoaded: documentsSelector.getIsLoaded(state),
  documents: documentsSelector.getItems(state),
  isAdditionalDocumentsLoaded: additionalDocumentsSelector.getIsLoaded(state),
  additionalDocuments: additionalDocumentsSelector.getItems(state)
});

const mapDispatchToProps = {
  downloadTestDocumentFile,
  downloadTestAdditionalDocumentFile,
  getAllDocuments: documentSearchAction,
  getAllAdditionalDocuments: additionalDocumentSearchAction,
  deleteTestDocument: archiveTestDocument,
  deleteAdditionalTestDocument: archiveAdditionalTestDocument
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(DocumentsBlock);
