import * as React from 'react';
import Button from "@material-ui/core/Button/Button";
import {
  Link,
  Route,
  RouteComponentProps,
  Switch
} from "react-router-dom";
import ReportTemplatesList from "./ReportTemplatesList";
import ReportTemplatesCreate from "./ReportTemplatesCreate";
import ReportTemplatesUpdate from "./ReportTemplatesUpdate";
import { withRouter } from 'react-router-dom';
import { getCreateLink } from 'helpers/router-helper';
import {
  getSpecialismsWithoutReportTemplatesAction,
} from "actions";
import { connect } from "react-redux";
import { ConnectedProps } from "types";
import { RootState } from "reducers";


class ReportTemplates extends React.Component<PropsFromRedux & RouteComponentProps> {
  baseUrl = this.props.match.path;

  componentDidMount() {
    this.props.getSpecialismsWithoutReportTemplatesAction();
  }

  createComponent = () => <ReportTemplatesCreate backRoute={this.baseUrl} />;
  updateComponent = () => <ReportTemplatesUpdate backRoute={this.baseUrl} />;

  render() {
    const {specialismsWithoutReportTemplates} = this.props;

    return (
      <div>
        <div className="crud-sections-header">
          <h4>Report Templates</h4>
          {!!specialismsWithoutReportTemplates.length &&
            <Link to={getCreateLink(this.baseUrl)}>
              <Button variant="contained" color="default">
                New template
              </Button>
            </Link>}
        </div>
        <hr />
        <Switch>
          <Route
            path={getCreateLink(this.baseUrl)}
            render={this.createComponent}
          />
          <Route path={this.baseUrl + "/:id"} render={this.updateComponent} />
        </Switch>
        <ReportTemplatesList
          baseUrl={this.baseUrl}
          specialismsWithoutReportTemplates={specialismsWithoutReportTemplates}
        />
      </div>
    );
  }
}

const connector = connect((state: RootState) => ({
  specialismsWithoutReportTemplates: state.specialismsWithoutReportTemplates,
}), {
  getSpecialismsWithoutReportTemplatesAction
});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(ReportTemplates));
