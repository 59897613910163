import * as React from "react";
import { ITest } from "../../../models/test.interface";
import { InputAdornment, MenuItem, Zoom } from "@material-ui/core";
import ReduxTextField from "../../../components/forms/ReduxTextField";
import {
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm
} from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { FieldGeneric } from "../../../components/forms/FieldGeneric";
import { createProposal, updateProposal } from "../../../actions/proposal";
import { withRouter } from "react-router-dom";
import {
  composeValidators,
  fieldIsNumber,
  fieldMaxLength,
  fieldMaxValueForNumber,
  fieldMinValueForNumber,
  fieldsNotEmpty,
  fieldIsNullableNumber
} from "../../../helpers/validations/validation";
import ReduxDatePicker from "../../../components/forms/ReduxDatePicker";
import ReduxWYSIWYGField from "../../../components/forms/ReduxWYSIWYGField";
import moment from "moment";
import Icon from "@material-ui/core/Icon/Icon";
import TooltipIcon from "@material-ui/core/Tooltip/Tooltip";
import BookingProposal from "./BookingProposal";
import { ProposalEditAccessStatusesForTester } from "../../../constants/test-status";
import ReduxSelectField from "../../../components/forms/ReduxSelectField";
import { Expenses, EXPENSES_LIST } from "../../../constants/expenses";
export const FORM_NAME = "BookingProposalEdit";
const selectorForm = formValueSelector(FORM_NAME);

export const testDateStart = testFinish =>
  (testFinish && new Date(testFinish).getTime()) || "";

interface IFormValues {
  dateStart: string;
  dateEnd: string;
  countDays: number;
  dailyExpenses: number;
  expensesMode: number;
  expensesTotal: number;
  amountExpensesNotes: string;
  dailyExpensesNotes: string;
  dailyExpensesDays: number;
  info: string;
  firstPhase: string;
  firstPhaseInformation: string;
  secondPhase?: string;
  secondPhaseInformation?: string;
  otherPhases: string;
  otherPhasesInformation: string;
  preRequisites: string;
}
class BookingProposalEdit extends React.Component<
  InjectedFormProps<{}, {}> & {
    test: ITest;
    createProposal: (
      id: number,
      body: IFormValues,
      formName: string
    ) => (dispatch: Dispatch) => Promise<void>;
    updateProposal: (
      id: number,
      body: IFormValues,
      formName: string,
      backRoute?: string
    ) => (dispatch: Dispatch) => Promise<void>;
    valuesData: IFormValues;
    baseUrl: string;
    isEditByTester?: boolean;
  },
  any
> {
  isRequired: boolean = false;

  state = {
    showViewMode: false,
    expensesMode: !!this.props.initialValues ? this.props.initialValues.expensesMode : Expenses.NO_EXPENSES,
  };

  isSubmitButtonClicked = () => {
    this.isRequired = true;
  };

  renderOption = (value: any) => (
    <MenuItem key={value.key} value={value.key}>
      {value.value}
    </MenuItem>
  );

  handleExpensesModeUpdate = (event, id) => {
    this.setState({ expensesMode: id });

    this.props.change('dailyExpenses', null);
    this.props.change('dailyExpensesNotes', null);
    this.props.change('dailyExpensesDays', null);
    this.props.change('expensesTotal', null);
    this.props.change('amountExpensesNotes', null);

    return;
  };

  render() {
    const { test, handleSubmit } = this.props;
    const currentDay = Date.now();

    if (
      testDateStart(test.dateTo) < Date.now() &&
      !ProposalEditAccessStatusesForTester.includes(test.status)
    ) {
      return (
        <div className="styled-block">
          <h4 className="block-header mb-4">Booking Proposal</h4>
          <div className="table-no-items">
            You can not submit your proposal, the Test is expired.
          </div>
        </div>
      );
    }

    if (this.state.showViewMode) {
      return <BookingProposal {...this.props} />;
    }

    return (
      <form
        onSubmit={handleSubmit((values: IFormValues) => {
          const convertPrefillDate = (date: string): string =>
            !!this.props.initialValues ? moment(date).format() : date;

          const body = {
            dateStart: convertPrefillDate(values.dateStart),
            dateEnd: convertPrefillDate(values.dateEnd),
            firstPhase: values.firstPhase,
            firstPhaseInformation: values.firstPhaseInformation,
            expensesMode: values.expensesMode,
            expensesTotal: values.expensesTotal
              ? Number(values.expensesTotal)
              : 0,
            amountExpensesNotes: values.amountExpensesNotes,
            dailyExpensesNotes: values.dailyExpensesNotes,
            dailyExpensesDays: values.dailyExpensesDays ? Number(values.dailyExpensesDays) : null,
            info: values.info,
            otherPhases: values.otherPhases,
            otherPhasesInformation: values.otherPhasesInformation,
            preRequisites: values.preRequisites,
            secondPhase: values.secondPhase,
            secondPhaseInformation: values.secondPhaseInformation,
            test: "/api/tests/" + test.id,
            countDays: values.countDays ? Number(values.countDays) : 0,
            dailyExpenses: values.dailyExpenses
              ? Number(values.dailyExpenses)
              : 0,
            isEditedByTester: this.props.isEditByTester || false
          };

          this.props.isEditByTester
            ? this.props.updateProposal(
                test?.proposal.id,
                body,
                FORM_NAME,
                location.pathname
              )
            : this.props.createProposal(test.id as number, body, FORM_NAME);
        })}
      >
        <div className="styled-block">
          {this.props.isEditByTester && (
            <div className="d-flex justify-content-end">
              <button
                className="mr-0 btn-cancel"
                type="submit"
                onClick={() => this.setState({ showViewMode: true })}
              >
                Cancel
              </button>
            </div>
          )}
          <div className="pt-4">
            <h4 className="block-header mb-4">Propose work overview</h4>
            <div className="row">
              <div className="col-md-12">
                <FieldGeneric
                  name="info"
                  label="Thank you for the opportunity to provide the following Statement of Work and proposal.
                        Please see the following proposed scope of works and timescales related to your request.
                        Please let me know if you have any comments or changes."
                  component={ReduxWYSIWYGField}
                />
              </div>
            </div>
          </div>
          <div className="pt-4">
            <h4 className="block-header mb-4" style={{ marginTop: "20px" }}>
              Booking Proposal
            </h4>
            <div className="row">
              <div className="col-md-3">
                <FieldGeneric
                  name="dateStart"
                  margin="normal"
                  label="From *"
                  InputLabelProps={{
                    shrink: true
                  }}
                  disablePast={false}
                  component={ReduxDatePicker}
                />
              </div>
              <div className="col-md-3">
                <FieldGeneric
                  name="dateEnd"
                  margin="normal"
                  label="To *"
                  InputLabelProps={{
                    shrink: true
                  }}
                  disablePast={true}
                  minDate={currentDay}
                  component={ReduxDatePicker}
                />
              </div>
              {!test.isOwnedByCompany && (
                <div className="col-md-3">
                  <Field
                    name="countDays"
                    label="Number of Days Effort *"
                    margin="normal"
                    fullWidth
                    component={ReduxTextField}
                  />
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-md-3">
                <FieldGeneric
                  name="expensesMode"
                  label="Expenses *"
                  component={ReduxSelectField}
                  margin="normal"
                  onChange={this.handleExpensesModeUpdate}
                >
                  {EXPENSES_LIST.map(this.renderOption)}
                </FieldGeneric>
              </div>
              { this.state.expensesMode === Expenses.EXPECTED_EXPENSES_AMOUNT && (
                <>
                <div className="col-md-3">
                  <FieldGeneric
                    inputProps={{ maxLength: 255 }}
                    name="expensesTotal"
                    label="Expenses *"
                    component={ReduxTextField}
                    margin="normal"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          &pound;
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
                <div className="col-md">
                  <FieldGeneric
                    inputProps={{ maxLength: 255 }}
                    name="amountExpensesNotes"
                    label="Expenses Note *"
                    component={ReduxTextField}
                    margin="normal"
                    fullWidth
                  />
                </div>
                </>)
              }
              { this.state.expensesMode === Expenses.DAILY_EXPENSES && (
                <>
                  <div className="col-md-3">
                    <FieldGeneric
                      name="dailyExpensesDays"
                      label="Number of Days *"
                      component={ReduxTextField}
                      margin="normal"
                      fullWidth
                    />
                  </div>
                  {!test.isOwnedByCompany && (
                    <div className="col-md-3">
                      <Field
                        name="dailyExpenses"
                        label="Daily Expenses *"
                        margin="normal"
                        fullWidth
                        component={ReduxTextField}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              &pound;
                            </InputAdornment>
                          )
                        }}
                      />
                    </div>
                  )}
                  <div className="col-md">
                    <FieldGeneric
                      inputProps={{ maxLength: 255 }}
                      name="dailyExpensesNotes"
                      label="Expenses Note *"
                      component={ReduxTextField}
                      margin="normal"
                      fullWidth
                    />
                  </div>
                </>)
              }
            </div>
            <div className="pt-4">
              <div className="d-flex align-items-center mb-3">
                <h4 className="block-header">Phase 1</h4>
                <div className="col-md-3">
                  <FieldGeneric
                    name="firstPhase"
                    placeholder="Enter phase title here"
                    component={ReduxTextField}
                  />
                </div>
                <TooltipIcon
                  placement={"top-end"}
                  TransitionComponent={Zoom}
                  disableFocusListener
                  disableTouchListener
                  title="Example: Phase 1, Incremental Web Application Pentest"
                >
                  <Icon>info</Icon>
                </TooltipIcon>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <FieldGeneric
                    name="firstPhaseInformation"
                    label="Enter supporting text for Phase 1 including additional information"
                    component={ReduxWYSIWYGField}
                  />
                </div>
              </div>
            </div>
            <div className="pt-4">
              <div className="d-flex align-items-center pt-4 mb-3">
                <h4 className="block-header">Phase 2</h4>
                <div className="col-md-3">
                  <FieldGeneric
                    name="secondPhase"
                    placeholder="Enter phase title here"
                    component={ReduxTextField}
                  />
                </div>
                <TooltipIcon
                  placement={"top-end"}
                  TransitionComponent={Zoom}
                  disableFocusListener
                  disableTouchListener
                  title="Enter name of proposed security test or Report Writing and Final Analysis"
                >
                  <Icon>info</Icon>
                </TooltipIcon>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <FieldGeneric
                    name="secondPhaseInformation"
                    label="Enter supporting text for Phase 2 including additional information"
                    component={ReduxWYSIWYGField}
                  />
                </div>
              </div>
            </div>
            <div className="pt-4">
              <div className="d-flex align-items-center pt-4 mb-3">
                <h4 className="block-header">Other phases</h4>
                <div className="col-md-3">
                  <FieldGeneric
                    name="otherPhases"
                    placeholder="Enter phase title or report writing phase"
                    component={ReduxTextField}
                  />
                </div>
                <TooltipIcon
                  placement={"top-end"}
                  TransitionComponent={Zoom}
                  disableFocusListener
                  disableTouchListener
                  title="Additional phases or report writing"
                >
                  <Icon>info</Icon>
                </TooltipIcon>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <FieldGeneric
                    name="otherPhasesInformation"
                    label="Enter supporting text for this Phase"
                    component={ReduxWYSIWYGField}
                  />
                </div>
              </div>
            </div>
            <div className="pt-4">
              <div className="d-flex align-items-center pt-4 mb-3">
                <h4 className="block-header">Pre-requisites</h4>
                <TooltipIcon
                  placement={"top-end"}
                  TransitionComponent={Zoom}
                  disableFocusListener
                  disableTouchListener
                  title={
                    <span>
                      Note: Testing requirements which must be in place before
                      testing can commence, failure to provide these
                      requirements could result in the scope being incomplete
                      and testing delayed.
                      <br />
                      Example of pre-requisites for information purposes:
                      <li>
                        Network access to the application, for example IP
                        Whitelist the provided testing source address for both
                        the firewall and the WAF (if applicable)
                      </li>
                      <li>
                        URLs for both APIs, schema for the internal API as well
                        as example requests or swagger/postman files to show the
                        API is used.
                      </li>
                      <li>API authentication details for the internal API</li>
                    </span>
                  }
                >
                  <Icon style={{ marginLeft: "10px" }}>info</Icon>
                </TooltipIcon>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <FieldGeneric
                    name="preRequisites"
                    label="Enter Pre-requisites that the Client will need to provide prior to the testing activities"
                    component={ReduxWYSIWYGField}
                  />
                </div>
              </div>
            </div>
            <div className="pt-4">
              <b>
                NOTE:
                <li>Must include the range of IP addresses and description.</li>
                <li>
                  On Client approval of this SoW remember to issue your
                  Authorisation form to test.
                </li>
              </b>
            </div>
            <div className="col-md-12 mt-4 text-right">
              <button
                className="btn-accept"
                onClick={this.isSubmitButtonClicked}
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

interface IState {
  test: {
    items: {
      items: IProposal[];
    };
  };
}

interface IProposal {
  [key: string]: string;
}

function mapStateToProps(state: IState, props) {
  const index = state.test.items.items.findIndex(
    test => test.id === props.test.id
  );
  const proposal = state.test.items.items[index].proposal;

  if (proposal) {
    return {
      valuesData: {
        ...selectorForm(
          state,
          "dateStart",
          "dateEnd",
          "countDays",
          "expensesMode",
          "expensesTotal",
          "amountExpensesNotes",
          "dailyExpensesNotes",
          "dailyExpensesDays",
          "dailyExpenses",
          "info",
          "firstPhase",
          "firstPhaseInformation",
          "secondPhase",
          "secondPhaseInformation",
          "otherPhases",
          "otherPhasesInformation",
          "preRequisites"
        )
      },
      initialValues: proposal
    };
  }

  return null;
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      createProposal,
      updateProposal
    },
    dispatch
  );
};

const connectForm = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  validate: composeValidators(
    fieldsNotEmpty([
      "dateStart",
      "dateEnd",
      "countDays",
      "info",
      "firstPhase",
      "firstPhaseInformation",
      "otherPhases",
      "otherPhasesInformation",
      "preRequisites",
      "expensesTotal",
      "dailyExpensesDays",
      "dailyExpenses",
      "expensesMode",
      "dailyExpensesNotes",
      "amountExpensesNotes",
    ]),
    fieldIsNumber(["countDays"]),
    fieldIsNullableNumber(["dailyExpenses"]),
    fieldIsNullableNumber(["expensesTotal"]),
    fieldMaxValueForNumber(["dailyExpenses"], 500),
    fieldMinValueForNumber(["dailyExpenses"], 0),
    fieldMinValueForNumber(["countDays"], 1),
    fieldMaxLength(
      [
        "info",
        "firstPhaseInformation",
        "secondPhaseInformation",
        "otherPhasesInformation",
        "preRequisites"
      ],
      10000
    ),
    fieldMaxLength(["firstPhase", "secondPhase", "otherPhases"], 100),
    values => {
      const diffDateFrom = moment(values.dateEnd).diff(
        moment(values.dateStart),
        "days"
      );
      const diffDateTo = moment(values.dateEnd).diff(moment(), "days");
      const fromDateErrorMessage =
        diffDateFrom < 0 ? "Date from should be less or equal to Date to" : "";
      const toDateErrorMessage =
        diffDateTo >= 0 ? "" : "Date should not be before minimal date";
      return {
        dateStart: fromDateErrorMessage,
        dateEnd: toDateErrorMessage
      };
    }
  ),
  asyncBlurFields: []
})(BookingProposalEdit as any);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(connectForm as any) as any);
