import * as Types from "../constants/action-type";
import {
  createLoaderAction,
  createCrudAction,
  createRequestAction,
} from "../helpers/action-helper";
import { setRequestInProcess } from "./request";
import { openSnackbar } from "./snackbar";
import { reset } from "redux-form";
import { push } from "react-router-redux";
import { handleHttpErrors } from "../helpers/errors/http-error-handler";
import { getRequestName } from "../helpers/action-request-helper";
import { getRequestFromType } from "../api/requests/request-factory";
import { postDocument } from "../api/requests/document";
import { API_DOCUMENTS_ENDPOINT_URL } from "../constants/urls";
import { createAction } from "redux-actions";

export const insuranceLoader = createLoaderAction(Types.INSURANCES);
export const insuranceRequest = createRequestAction(Types.INSURANCES);

export const getTesterInsurances = () => {
  return {
    type: Types.INSURANCES_GET,
    payload: {},
  };
};

export const postItemInsurance = (insurance, backRoute, formName) => async dispatch => {
  dispatch(setRequestInProcess(true, getRequestName(Types.INSURANCES, 'postItem')));

  let data, createdFile;

  try {
    if (insurance.evidence instanceof File) {
      createdFile = await postDocument(insurance.evidence);

      if (createdFile) {
        insurance.evidence = API_DOCUMENTS_ENDPOINT_URL + createdFile.uuid;
      }
    }

    data = await (getRequestFromType(Types.INSURANCES)).postItem(insurance);

    dispatch(openSnackbar('Created', 4000));
    dispatch(createCrudAction(Types.INSURANCES).addItem(data));
    dispatch(reset(formName));
    dispatch(push(backRoute));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, getRequestName(Types.INSURANCES, 'postItem')));
};

export const  putItemInsurance = (insurance, backRoute, formName) => async dispatch => {
  dispatch(setRequestInProcess(true, getRequestName(Types.INSURANCES, 'putItem')));

  let data, createdFile;

  try {
    if (insurance.evidence instanceof File) {
      createdFile = await postDocument(insurance.evidence);

      if (createdFile) {
        insurance.evidence = API_DOCUMENTS_ENDPOINT_URL + createdFile.id;
      }
    }

    data = await (getRequestFromType(Types.INSURANCES)).putItem(insurance);

    dispatch(openSnackbar('Updated', 4000));
    dispatch(createCrudAction(Types.INSURANCES).replaceItem(data));
    dispatch(reset(formName));
    dispatch(push(backRoute));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, getRequestName(Types.INSURANCES, 'putItem')));
};

export const  archiveInsurance = (insurance, backRoute, formName) => async dispatch => {
  await (getRequestFromType(Types.INSURANCES)).deleteItem(insurance);
  dispatch(createAction(Types.INSURANCES_GET)());
};
