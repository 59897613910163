import { Dispatch } from "redux";
import { getTimeExpensesActions } from "reducers/time-expenses";
import { setRequestInProcess } from "./request";
import { handleHttpErrors } from "helpers/errors/http-error-handler";
import {
  createDailyTimeExpensesLogRequest,
  createWeeklyTimeLogRequest,
  deleteDailyTimeExpensesLogRequest,
  downloadPdfDocument,
  editDailyTimeExpensesLogRequest,
  getExpensesTypesRequest,
  getTimeCategoriesRequest,
  getTimeExpensesWeekInvoiceRequest,
  getTimeExpensesWeeksRequest,
  getTimesheetDayDataByIdRequest,
  getTimesheetManagersRequest,
  getTimesheetWeekByIdRequest,
  postExpensesDocument,
  updateSelectedWeeksStatusRequest,
  updateTimesheetPoNumberRequest
} from "api/requests/time-and-expenses";
import { reduceArrayByWeekNumber } from "helpers/timesheets-expenses";
import {
  CreateDailyTimeLogFormData,
  CreateWeeklyTimeLogFormData,
  DayByIdActionType,
  DeleteDailyTimeExpensesLogActionType,
  EditDailyTimeLogFormData,
  ReducedWeekType,
  TimeExpensesWeekType,
  TimesheetDocumentConvertedType,
  TimesheetDocumentType,
  TimesheetManagerType,
  TimesheetPoNumberType,
  TimesheetPoNumberUpdateParams,
  TimeSheetsFilterParamsType,
  TimeSheetWeeksInvoiceAction,
  UpdateSelectedWeeksStatusAction
} from "types/timesheets-and-expenses";
import { MAX_FILE_SIZE } from "constants/document";
import { openSnackbar } from "./snackbar";
import { deleteDayDocument } from "api/requests/document";
import { IDocument } from "models/document.interface";

const actions = getTimeExpensesActions();

const convertDocuments = async (
  documents: TimesheetDocumentType[] | undefined,
  dispatch: Dispatch
): Promise<TimesheetDocumentConvertedType[] | undefined> => {
  if (!Array.isArray(documents)) {
    return [];
  }

  const documentsIds: TimesheetDocumentConvertedType[] = [];
  if (Array.isArray(documents) && documents.length) {
    for (let item of documents) {
      if ("id" in item.document) {
        documentsIds.push({
          id: item.id,
          document: `/api/documents/${(item.document as IDocument).id}`,
          type: item.type
        });
      } else {
        if (item.document.size > MAX_FILE_SIZE) {
          dispatch(openSnackbar("File is too big", 4000));
          return;
        }
        const documentsId = await postExpensesDocument(item.document);
        documentsIds.push({
          ...documentsId,
          type: item.type
        });
      }
    }
  }

  return documentsIds;
};

export const getTimeExpensesWeeksAction = (
  params: TimeSheetsFilterParamsType
) => async (dispatch: Dispatch) => {
  dispatch(setRequestInProcess(true, "getTimeExpensesWeeks"));
  try {
    const { items } = await getTimeExpensesWeeksRequest(params);

    const weeksList: ReducedWeekType[] = reduceArrayByWeekNumber(items);

    dispatch(actions.setTimeExpensesWeeks(weeksList, weeksList.length));
    dispatch(actions.setIsLoaded(true));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  } finally {
    dispatch(setRequestInProcess(false, "getTimeExpensesWeeks"));
  }
};
const updateConsultantTimeExpensesWeeks = async (
  dispatch: Dispatch,
  successMessage: string,
  queryParams = {}
) => {
  const { items } = await getTimeExpensesWeeksRequest(queryParams);
  const weeksList: ReducedWeekType[] = reduceArrayByWeekNumber(items);
  dispatch(actions.setTimeExpensesWeeks(weeksList, weeksList.length));
  dispatch(openSnackbar(successMessage, 3000));
};

export const createDayTimeLogAction = (
  data: CreateDailyTimeLogFormData
) => async (dispatch: Dispatch) => {
  dispatch(setRequestInProcess(true, "createDayTimeLogAction"));
  try {
    const { documents, goBackHandler, queryParams = {}, ...rest } = data;
    const updatedDocuments = await convertDocuments(documents, dispatch);

    await createDailyTimeExpensesLogRequest({
      ...rest,
      documents: updatedDocuments || []
    });
    await updateConsultantTimeExpensesWeeks(
      dispatch,
      "Time Expenses log has been created",
      queryParams
    );
    goBackHandler();
  } catch (error) {
    handleHttpErrors(error, dispatch);
  } finally {
    dispatch(setRequestInProcess(false, "createDayTimeLogAction"));
  }
};

export const createWeeklyTimeLogAction = (
  data: CreateWeeklyTimeLogFormData
) => async (dispatch: Dispatch) => {
  dispatch(setRequestInProcess(true, "createWeeklyTimeLogAction"));
  try {
    const { goBackHandler, queryParams = {}, ...rest } = data;

    await createWeeklyTimeLogRequest({
      ...rest
    });
    await updateConsultantTimeExpensesWeeks(
      dispatch,
      "Weekly Time log has been created",
      queryParams
    );
    goBackHandler();
  } catch (error) {
    handleHttpErrors(error, dispatch);
  } finally {
    dispatch(setRequestInProcess(false, "createWeeklyTimeLogAction"));
  }
};

export const editDayTimeLogAction = (data: EditDailyTimeLogFormData) => async (
  dispatch: Dispatch
) => {
  dispatch(setRequestInProcess(true, "editDayTimeLogAction"));
  try {
    const {
      documents = [],
      goBackHandler,
      archivedDocuments = [],
      queryParams = {},
      ...rest
    } = data;

    const documentsIds = await convertDocuments(documents, dispatch);

    if (archivedDocuments) {
      await Promise.all(
        archivedDocuments.map(id => deleteDayDocument(rest.id, id))
      );
      dispatch(actions.clearArchivedDocumentsList());
    }

    await editDailyTimeExpensesLogRequest({
      ...rest,
      documents: documentsIds || []
    });

    await updateConsultantTimeExpensesWeeks(
      dispatch,
      "Time Expenses log has been updated",
      queryParams
    );
    goBackHandler();
  } catch (error) {
    handleHttpErrors(error, dispatch);
  } finally {
    dispatch(setRequestInProcess(false, "editDayTimeLogAction"));
  }
};

export const getTimesheetDayDataByIdAction = (id: string) => async (
  dispatch: Dispatch
) => {
  dispatch(setRequestInProcess(true, "getTimesheetDayDataById"));
  try {
    const dayData: DayByIdActionType = await getTimesheetDayDataByIdRequest(id);

    dispatch(actions.setSelectedDayData(dayData));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  } finally {
    dispatch(setRequestInProcess(false, "getTimesheetDayDataById"));
  }
};

export const deleteDailyTimeExpensesLogAction = (
  props: DeleteDailyTimeExpensesLogActionType
) => async (dispatch: Dispatch) => {
  const { id, cb, queryParams = {} } = props;
  dispatch(setRequestInProcess(true, "deleteDailyTimeExpensesLogAction"));
  try {
    await deleteDailyTimeExpensesLogRequest(id);
    await updateConsultantTimeExpensesWeeks(
      dispatch,
      "Time Expenses log has been deleted",
      queryParams
    );
    dispatch(actions.clearArchivedDocumentsList());
    cb();
  } catch (error) {
    handleHttpErrors(error, dispatch);
  } finally {
    dispatch(setRequestInProcess(false, "getTimesheetDayDataById"));
  }
};

export const clearSelectedDayDataAction = () => (dispatch: Dispatch) => {
  dispatch(actions.setSelectedDayData(null));
};

export const addSelectedProjectWeeksAction = (ids: number[]) => (
  dispatch: Dispatch
) => {
  dispatch(actions.addSelectedProjectWeeksItems(ids));
};
export const removeSelectedProjectWeeksAction = (ids: number[]) => (
  dispatch: Dispatch
) => {
  dispatch(actions.removeSelectedProjectWeeksItems(ids));
};
export const clearSelectedProjectWeeksAction = () => (dispatch: Dispatch) => {
  dispatch(actions.clearSelectedProjectWeeksItems());
};

export const getTimeCategoriesAction = () => async (dispatch: Dispatch) => {
  dispatch(setRequestInProcess(true, "getTimeCategoriesAction"));
  try {
    const { items } = await getTimeCategoriesRequest();
    dispatch(actions.setTimeCategoriesList(items));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  } finally {
    dispatch(setRequestInProcess(false, "getTimeCategoriesAction"));
  }
};
export const getExpensesTypesAction = () => async (dispatch: Dispatch) => {
  dispatch(setRequestInProcess(true, "getExpensesTypesAction"));
  try {
    const { items } = await getExpensesTypesRequest();
    dispatch(actions.setExpensesTypesList(items));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  } finally {
    dispatch(setRequestInProcess(false, "getExpensesTypesAction"));
  }
};

export const updateSelectedWeeksStatusAction = ({
  selectedIds,
  comment,
  status,
  cb,
  queryParams = {}
}: UpdateSelectedWeeksStatusAction) => async (dispatch: Dispatch) => {
  dispatch(setRequestInProcess(true, "updateSelectedWeeksStatusAction"));

  try {
    await updateSelectedWeeksStatusRequest({
      selectedIds,
      comment,
      status
    });
    await updateConsultantTimeExpensesWeeks(
      dispatch,
      "Status updated successfully",
      queryParams
    );
    dispatch(actions.clearSelectedProjectWeeksItems());
    cb();
  } catch (error) {
    if (error.response && error.response.status === 422) {
      dispatch(openSnackbar(error.response.body.error, 3000));
    } else {
      handleHttpErrors(error, dispatch);
    }
  } finally {
    dispatch(setRequestInProcess(false, "updateSelectedWeeksStatusAction"));
  }
};

export const getTimeExpensesWeekInvoiceAction = (
  params: TimeSheetWeeksInvoiceAction
) => async (dispatch: Dispatch) => {
  dispatch(setRequestInProcess(true, "getTimeExpensesWeekInvoiceAction"));
  try {
    const { weekId, invoiceLinkType, fileName } = params;

    const data: string = await getTimeExpensesWeekInvoiceRequest(
      weekId,
      invoiceLinkType
    );
    if (data) {
      downloadPdfDocument(data, fileName);
    } else {
      dispatch(openSnackbar("Error: Invoice not found", 3000));
    }
  } catch (error) {
    handleHttpErrors(error, dispatch);
  } finally {
    dispatch(setRequestInProcess(false, "getTimeExpensesWeekInvoiceAction"));
  }
};

export const archiveTimesheetExpensesDocumentAction = (id: number) => (
  dispatch: Dispatch
) => {
  dispatch(actions.addArchivedDocument(id));
  dispatch(openSnackbar(`Document has been archived`, 3000));
};
export const clearArchivedDocumentsListAction = () => (dispatch: Dispatch) => {
  dispatch(actions.clearArchivedDocumentsList());
};

export const getTimesheetManagersAction = () => async (dispatch: Dispatch) => {
  dispatch(setRequestInProcess(true, "getTimesheetManagersAction"));
  try {
    const data: TimesheetManagerType[] = await getTimesheetManagersRequest();
    dispatch(actions.setTimesheetManagersList(data));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  } finally {
    dispatch(setRequestInProcess(false, "getTimesheetManagersAction"));
  }
};

export const updateTimesheetPoNumberAction = (
  params: TimesheetPoNumberUpdateParams
) => async (dispatch: Dispatch) => {
  dispatch(setRequestInProcess(true, "updateTimesheetPoNumberAction"));
  try {
    const { id, goBackHandler, value, poNumberType } = params;
    await updateTimesheetPoNumberRequest({ id, value, poNumberType });
    const {
      purchaseOrderNumber,
      expensesPurchaseOrderNumber,
      notesThread
    } = await getTimesheetWeekByIdRequest(id);
    dispatch(
      actions.setTimesheetWeekPoNumber({
        id,
        data: { purchaseOrderNumber, expensesPurchaseOrderNumber, notesThread }
      })
    );
    dispatch(
      openSnackbar(
        `${
          poNumberType === TimesheetPoNumberType.EXPENSES_PO_NUMBER
            ? "Expenses "
            : ""
        } PO Number has been updated`,
        3000
      )
    );
    if (typeof goBackHandler === "function") {
      goBackHandler();
    }
  } catch (error) {
    handleHttpErrors(error, dispatch);
  } finally {
    dispatch(setRequestInProcess(false, "updateTimesheetPoNumberAction"));
  }
};
