import * as React from "react";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router-dom";
import CompletedReportsTable from "./CompletedReportsTable";
import { IAnyProps } from "../../../../interfaces/any-props.interface";
import AmendmentsTable from "../../../client-tester/amendments/AmendmentsTable";
import AdminBackToTest from "../../../client-tester/test-details/button/AdminBackToTest";
import AdditionalDocuments from "./AdditionalDocuments/AdditionalDocuments";
import { Zoom } from "@material-ui/core";
import Icon from "@material-ui/core/Icon/Icon";
import TooltipIcon from "@material-ui/core/Tooltip/Tooltip";
import StatusBlock from "../../../common/StatusBlock";
import { connect } from "react-redux";
import { ConnectedProps } from "types";
import selector from "selectors/test";
import { result } from "lodash";
import { testRequest } from "actions/test";

type IProps = RouteComponentProps<any> & IAnyProps & {};

class CompletedReports extends React.Component<IProps & PropsFromRedux, {}> {
  baseUrl = this.props.match.url;

  componentDidMount() {
    const testId = this.props.match.params.test;
    this.props.loadTestById(testId);
  }

  render() {
    const { test } = this.props;

    if (!test) {
      return <div>Loading...</div>;
    }

    return (
      <>
        <AdminBackToTest />
        <h4 className="main-content-header">
          {test.name}
          <span className="status-label ml-3">
            {test.status && <StatusBlock status={test.status} />}
          </span>
        </h4>
        <div className="styled-block">
          <div className="block-header-wrapper mb-4">
            <h4 className="block-header">Completed Report(s)</h4>
          </div>
          <CompletedReportsTable testId={this.props.match.params.test} />
        </div>

        <div className="styled-block">
          <div className="block-header-wrapper mb-4">
            <h4 className="block-header">Clarifications</h4>
          </div>
          <AmendmentsTable />
        </div>
        <div className="styled-block">
          <div className="block-header-wrapper mb-4">
            <h4 className="block-header">
              Additional documents
              <TooltipIcon
                placement={"top"}
                TransitionComponent={Zoom}
                disableFocusListener
                disableTouchListener
                title="Additional documents that can be created in addition to the final report"
              >
                <Icon
                  style={{
                    marginLeft: "5px",
                    marginBottom: "2px",
                    verticalAlign: "middle"
                  }}
                >
                  info
                </Icon>
              </TooltipIcon>
            </h4>
          </div>
          <AdditionalDocuments testId={this.props.match.params.test} />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  test: selector.getItemById(result(props, "match.params.test", false))(state),
});

const mapDispatchToProps = {
  loadTestById: testRequest.getItem,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(CompletedReports));
