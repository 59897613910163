import * as Types from "constants/action-type";
import {
  createFilterAction,
  createLoaderAction,
  createCrudAction,
  createRequestAction
} from "helpers/action-helper";
import { getRequestName } from "helpers/action-request-helper";
import { handleHttpErrors } from "helpers/errors/http-error-handler";
import { USERS } from "constants/action-type";
import { getRequestFromType } from "api/requests/request-factory";
import {
  setRequestInProcess,
  setResponseWithPermissionsToStore
} from "./request";
import {
  getItems,
  postSubuserRequest,
  reassignTesterRequest,
  putSubuserRequest,
  putItem,
  updateClientByAdminRequest,
  getUsersConsultants,
  putAssignConsultantAction
} from "api/requests/users";
import { openSnackbar } from "./snackbar";
import { reset, stopSubmit } from "redux-form";
import { push } from "react-router-redux";
import { result } from "lodash";
import {
  reassignModalClose,
  reassignModalGroup,
  reassignModalOpen,
  reassignModalTitle
} from "./modals";
import { IAnyProps } from "interfaces/any-props.interface";
import { ACTIVE } from "constants/filter";
import { getUsersFilteredForReassign } from "./reassign-users";
import { Group } from "constants/group";
import { testingCompaniesRequest } from "./testing-companies";
import { MILLISECONDS_FOR_SHOWING_MESSAGE } from "./const";

export const usersFilter = createFilterAction(Types.USERS);
export const usersLoader = createLoaderAction(Types.USERS);
export const usersRequest = createRequestAction(Types.USERS);

export const GET_USER = "GET_USER";

const crudType = Types.USERS;

export const getUser = id => async dispatch => {
  dispatch(
    setRequestInProcess(true, getRequestName(crudType, "getItem", "" + id))
  );
  let data;

  try {
    data = await (getRequestFromType(crudType) as any).getItem(id);
    dispatch(createCrudAction(crudType).addItem(data));
    dispatch({ type: GET_USER, payload: data });
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(
    setRequestInProcess(false, getRequestName(crudType, "getItem", "" + id))
  );
};

export const loadDynamicPermissions = () => async dispatch => {
  dispatch(setRequestInProcess(true, "getDynamicPermissions"));

  let permissions: any = {};
  try {
    permissions = await (getRequestFromType(
      crudType
    ) as any).getDynamicPermissions();
    dispatch(setResponseWithPermissionsToStore(permissions.body));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, "getDynamicPermissions"));
};

export const getUsersFiltered = (
  page,
  itemsPerPage,
  showStatus,
  name,
  email,
  phone,
  userFilter,
  createdAt,
  deletedAt,
  userDbsFilter = false,
  userTestingCompanyFilter = "",
  userClientCompanyFilter = "",
  groupName = []
) => async dispatch => {
  dispatch(setRequestInProcess(true, "getUsersFiltered"));
  /** part responsible for a loader state */
  dispatch(createLoaderAction(crudType).setIsNotLoaded());

  let data = [],
    totalItems;

  try {
    ({ items: data, totalItems } = await getItems(
      page,
      itemsPerPage,
      showStatus,
      name,
      email,
      phone,
      userFilter,
      createdAt,
      deletedAt,
      userDbsFilter,
      userTestingCompanyFilter,
      userClientCompanyFilter,
      groupName
    ));

    data.map((user: any) => {
      if (user.dynamicPermissions.length) {
        user.dynamicPermissions = user.dynamicPermissions.reduce(
          (obj, item: any) => ((obj[item.name] = true), obj),
          {}
        );
      } else {
        user.dynamicPermissions = {};
      }
    });
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(
    createLoaderAction(crudType).setPaginatioData(
      data,
      itemsPerPage,
      totalItems,
      page
    )
  );
  dispatch(setRequestInProcess(false, "getUsersFiltered"));
};

export const reassignItem = (
  id,
  groupName,
  page,
  itemsPerPage,
  showStatus,
  filterText
) => async dispatch => {
  dispatch(
    setRequestInProcess(true, getRequestName(crudType, "deleteItem", "" + id))
  );

  try {
    await (getRequestFromType(crudType) as any).deleteItem(id);
  } catch (error) {
    /** Show error popup */
    if (result(error, "response.status") === 422) {
      await dispatch(getUsersFilteredForReassign(groupName));
      await dispatch(
        reassignModalTitle(result(error, "response.body.hydra:description"))
      );
      await dispatch(reassignModalOpen(id));
      await dispatch(reassignModalGroup(groupName));
    }

    handleHttpErrors(error, dispatch);
    dispatch(
      setRequestInProcess(
        false,
        getRequestName(crudType, "deleteItem", "" + id)
      )
    );
    return;
  }
  dispatch(
    getUsersFiltered(
      page,
      itemsPerPage,
      showStatus,
      filterText,
      "",
      "",
      "",
      "",
      ""
    )
  );
  dispatch(
    setRequestInProcess(false, getRequestName(crudType, "deleteItem", "" + id))
  );
};

export const archiveItem = userFilter => (
  id,
  page,
  itemsPerPage,
  showStatus,
  name,
  email,
  phone,
  customUserFilter,
  createdAt,
  deletedAt,
  userDbsFilter = false,
  testingCompanyFilter,
  userClientCompanyFilter,
  company,
) => async dispatch => {
  dispatch(
    setRequestInProcess(true, getRequestName(crudType, "archiveItem", "" + id))
  );

  const filter = userFilter === "Planner" ? "" : customUserFilter;

  try {
    await (getRequestFromType(crudType) as any).archiveItem(id);
  } catch (error) {
    /** Show error popup */
    if (result(error, "response.status") === 422) {
      if (userFilter !== "TesterAndManager") {
        await dispatch(getUsersFilteredForReassign(userFilter, company));
        await dispatch(
          reassignModalTitle(result(error, "response.body.hydra:description"))
        );
        await dispatch(reassignModalOpen(id));
        await dispatch(reassignModalGroup(userFilter));
      }
    } else {
      handleHttpErrors(error, dispatch);
    }
    dispatch(
      setRequestInProcess(
        false,
        getRequestName(crudType, "archiveItem", "" + id)
      )
    );
    return;
  }

  dispatch(
    setRequestInProcess(false, getRequestName(crudType, "archiveItem", "" + id))
  );

  dispatch(
    getUsersFiltered(
      page,
      itemsPerPage,
      showStatus,
      name,
      email,
      phone,
      filter,
      createdAt,
      deletedAt,
      userDbsFilter,
      testingCompanyFilter,
      userClientCompanyFilter
    )
  );
  dispatch(openSnackbar("Successfully deactivated", 4000));
};

export const unarchiveItem = userFilter => (
  id,
  page,
  itemsPerPage,
  showStatus,
  name,
  email,
  phone,
  customUserFilter,
  createdAt,
  deletedAt,
  userDbsFilter = false,
  testingCompanyFilter,
  userClientCompanyFilter
) => async dispatch => {
  dispatch(
    setRequestInProcess(
      true,
      getRequestName(crudType, "unarchiveItem", "" + id)
    )
  );

  let dataUnarchived;
  const filter = userFilter === "Planner" ? "" : customUserFilter;

  try {
    dataUnarchived = await (getRequestFromType(crudType) as any).unarchiveItem(
      id
    );
  } catch (error) {
    handleHttpErrors(error, dispatch);
    dispatch(
      setRequestInProcess(
        false,
        getRequestName(crudType, "unarchiveItem", "" + id)
      )
    );
    return;
  }

  dispatch(
    setRequestInProcess(
      false,
      getRequestName(crudType, "unarchiveItem", "" + id)
    )
  );
  dispatch(
    getUsersFiltered(
      page,
      itemsPerPage,
      showStatus,
      name,
      email,
      phone,
      filter,
      createdAt,
      deletedAt,
      userDbsFilter,
      testingCompanyFilter,
      userClientCompanyFilter
    )
  );
  dispatch(openSnackbar("Successfully reactivated", 4000));
};

export const deleteItem = userFilter => (
  id,
  page,
  itemsPerPage,
  showStatus,
  name,
  email,
  phone,
  customUserFilter,
  createdAt,
  deletedAt,
  userDbsFilter = false,
  testingCompanyFilter,
  userClientCompanyFilter
) => async dispatch => {
  dispatch(
    setRequestInProcess(true, getRequestName(crudType, "deleteItem", "" + id))
  );

  const filter = userFilter === "Planner" ? "" : userFilter;

  try {
    await (getRequestFromType(crudType) as any).deleteItem(id);
  } catch (error) {
    /** Show error popup */
    if (
      result(error, "response.status") === 422 &&
      userFilter === Group.PLANNER
    ) {
      await dispatch(getUsersFilteredForReassign(userFilter));
      await dispatch(
        reassignModalTitle(result(error, "response.body.hydra:description"))
      );
      await dispatch(reassignModalOpen(id));
      await dispatch(reassignModalGroup(userFilter));
    }
    if (userFilter !== Group.PLANNER) {
      handleHttpErrors(error, dispatch);
    }
    dispatch(
      setRequestInProcess(
        false,
        getRequestName(crudType, "deleteItem", "" + id)
      )
    );
    return;
  }
  dispatch(
    getUsersFiltered(
      page,
      itemsPerPage,
      showStatus,
      name,
      email,
      phone,
      filter,
      createdAt,
      deletedAt,
      userDbsFilter,
      testingCompanyFilter,
      userClientCompanyFilter
    )
  );
  dispatch(
    setRequestInProcess(false, getRequestName(crudType, "deleteItem", "" + id))
  );
  dispatch(openSnackbar("Successfully deleted", 4000));
};

export const postSubuserAction = (
  model,
  formName,
  backRoute,
  validationHandler: (error: any) => IAnyProps,
  pageNumber,
  itemsPerPage,
  showStatus,
  name
) => async dispatch => {
  dispatch(setRequestInProcess(true, getRequestName(crudType, "postItem")));

  let data;

  const newDynamicPermissions: string[] = [];

  if (model.dynamicPermissions) {
    Object.keys(model.dynamicPermissions).forEach(key => {
      if (model.dynamicPermissions[key]) {
        newDynamicPermissions.push(key);
      }
    });
  }
  model.dynamicPermissions = newDynamicPermissions;

  try {
    await postSubuserRequest(model);
    dispatch(reset(formName));
    dispatch(push(backRoute));

    await dispatch(
      getUsersFiltered(
        pageNumber,
        itemsPerPage,
        showStatus,
        name,
        "",
        "",
        "",
        "",
        ""
      )
    );
    dispatch(openSnackbar("User was saved", 4000));
  } catch (error) {
    if (error.response.status === 400) {
      handleHttpErrors(error, dispatch);
      dispatch(
        stopSubmit(
          formName,
          validationHandler(result(error, "response.body.violations", {}))
        )
      );
    }
  } finally {
    dispatch(setRequestInProcess(false, getRequestName(USERS, "postItem")));
  }
};

export const putSubuserAction = (
  model,
  formName,
  backRoute,
  validationHandler: (error: any) => IAnyProps,
  page,
  itemsPerPage,
  showStatus,
  userFilter = "",
  name,
  email,
  phone,
  createdAt,
  deletedAt
) => async dispatch => {
  dispatch(setRequestInProcess(true, getRequestName(crudType, "putItem")));

  let data;

  const newDynamicPermissions: string[] = [];

  if (model.userGroup !== "Viewer") {
    Object.keys(model.dynamicPermissions).forEach(key => {
      if (model.dynamicPermissions[key]) {
        newDynamicPermissions.push(key);
      }
    });
  }

  const fieldsForChangeSlugs = ["firstName", "lastName", "phone"];
  const newModel = Object.keys(model).reduce((object, key) => {
    if (!fieldsForChangeSlugs.includes(key)) {
      object[key] = model[key];
    }
    return object;
  }, {});
  try {
    if (model.groupName === Group.OWNER) {
      data = await updateClientByAdminRequest(model.id, {
        ...newModel,
        first_name: model.firstName,
        last_name: model.lastName,
        telephone: model.phone
      });
    } else {
      data = await putSubuserRequest(model.id, {
        ...model,
        dynamicPermissions: newDynamicPermissions
      });
    }

    dispatch(openSnackbar("User was saved", 4000));
    dispatch(reset(formName));
    dispatch(push(backRoute));
  } catch (error) {
    if (result(error, "response.status") === 422) {
      dispatch(
        stopSubmit(
          formName,
          validationHandler(result(error, "response.body.violations", {}))
        )
      );

      if (
        (model.groupName === Group.PLANNER ||
          model.groupName === Group.VIEWER) &&
        !!result(error, "response.body.hydra:description")
      ) {
        await dispatch(getUsersFilteredForReassign(model.groupName));
        await dispatch(reassignModalOpen(model.id));
        await dispatch(reassignModalGroup(model.groupName));
        await dispatch(
          reassignModalTitle(result(error, "response.body.hydra:description"))
        );
      }
    } else {
      handleHttpErrors(error, dispatch);
    }
    dispatch(setRequestInProcess(false, getRequestName(USERS, "putItem")));
    return;
  }
  dispatch(
    getUsersFiltered(
      page,
      itemsPerPage,
      showStatus,
      name,
      email,
      phone,
      userFilter,
      createdAt,
      deletedAt
    )
  );
  dispatch(setRequestInProcess(false, getRequestName(USERS, "putItem")));
};

export const putUserAction = (
  model,
  page,
  itemsPerPage,
  showStatus,
  name,
  email,
  phone,
  userFilter,
  createdAt,
  deletedAt,
  userClientCompanyFilter // Added parameter
) => async dispatch => {
  dispatch(setRequestInProcess(true, getRequestName(crudType, "putItem")));
  let data;
  try {
    data = await putItem(model);
    dispatch(
      openSnackbar("User was updated", 4000)
    );
  } catch (error) {
    if (result(error, "response.status") === 422) {
      // await dispatch(getUsersFilteredForReassign(model.groupName));
      // await dispatch(reassignModalOpen(model.id));
      // await dispatch(reassignModalGroup(model.groupName));
      // await dispatch(
      //   reassignModalTitle(result(error, "response.body.hydra:description"))
      // );
    }

    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, getRequestName(USERS, "putItem")));
  dispatch(
    getUsersFiltered(
      page,
      itemsPerPage,
      showStatus,
      name,
      email,
      phone,
      userFilter,
      createdAt,
      deletedAt,
      false, // userDbsFilter
      "", // userTestingCompanyFilter
      userClientCompanyFilter // Pass userClientCompanyFilter
    )
  );
};

export const reassignUserActions = (
  idFrom,
  idTo,
  userGroup,
  action
) => async dispatch => {
  if (!idTo) {
    dispatch(openSnackbar("Please select user", 4000));
    return;
  }

  dispatch(
    setRequestInProcess(true, getRequestName(crudType, "reassignUserActions"))
  );

  try {
    await reassignTesterRequest(idFrom, idTo);

    dispatch(reassignModalClose());
  } catch (error) {
    handleHttpErrors(error, dispatch);
    return;
  }

  if (action) {
    action({ id: idFrom })();
  }

  dispatch(
    setRequestInProcess(false, getRequestName(USERS, "reassignUserActions"))
  );
};

export const resetAction2FA = userId => async dispatch => {
  dispatch(setRequestInProcess(true, getRequestName(crudType, "reset2FA")));
  let data;

  try {
    data = await (getRequestFromType(crudType) as any).reset2FA(userId);
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }
  dispatch(openSnackbar("Reset 2FA completed", 4000));
  dispatch(setRequestInProcess(false, getRequestName(crudType, "reset2FA")));
};

export const changeTesterRoleAction = (
  testerProfileId: number,
  userRole: string,
  pageNumber,
  itemsPerPage,
  showStatus,
  name,
  email,
  phone,
  role,
  createdAt,
  deletedAt,
  userDbsFilter,
  userTestingCompanyFilter,
  userClientCompanyFilter,
  company?: any,
  newCompanyName?: string
) => async dispatch => {
  if (!company && !newCompanyName && userRole === "AVORD Tester") {
    dispatch(openSnackbar("Please select Testing Company", 4000));
    return;
  }
  dispatch(
    setRequestInProcess(true, getRequestName(crudType, "changeTesterRole"))
  );

  let data;

  try {
    data = await (getRequestFromType(crudType) as any).changeTesterRoleRequest(
      testerProfileId,
      userRole,
      company,
      newCompanyName
    );
    await dispatch(testingCompaniesRequest.getItems(1, 100, ACTIVE));
  } catch (error) {
    handleHttpErrors(error, dispatch);
    dispatch(
      setRequestInProcess(false, getRequestName(crudType, "changeTesterRole"))
    );
    return;
  }

  dispatch(
    setRequestInProcess(false, getRequestName(crudType, "changeTesterRole"))
  );

  await dispatch(
    getUsersFiltered(
      pageNumber,
      itemsPerPage,
      showStatus,
      name,
      email,
      phone,
      role,
      createdAt,
      deletedAt,
      userDbsFilter,
      userTestingCompanyFilter,
      userClientCompanyFilter
    )
  );
  dispatch(openSnackbar("Role changed", 4000));
};

export const changeReceiveBookingRequestsAction = (
  testerProfileId,
  value
) => async dispatch => {
  dispatch(setRequestInProcess(true, "changeReceiveBookingRequests"));
  let data;
  try {
    data = await (getRequestFromType(
      crudType
    ) as any).changeReceiveBookingRequestsRequest(testerProfileId, value);
  } catch (error) {
    handleHttpErrors(error, dispatch);
    dispatch(
      setRequestInProcess(
        false,
        getRequestName(crudType, "changeReceiveBookingRequests")
      )
    );
    return;
  }
  dispatch(openSnackbar("Successfully changed", 4000));
  dispatch(
    setRequestInProcess(
      false,
      getRequestName(crudType, "changeReceiveBookingRequests")
    )
  );
};

export const sendInviteToTester = email => async dispatch => {
  dispatch(setRequestInProcess(true, "sendInvateToTester"));
  let data;
  try {
    data = await (getRequestFromType(
      crudType
    ) as any).sendInviteToTesterRequest(email);
  } catch (error) {
    handleHttpErrors(error, dispatch);
    dispatch(
      setRequestInProcess(false, getRequestName(crudType, "sendInvateToTester"))
    );
    return;
  }
  dispatch(openSnackbar(data.body.message, 4000));
  dispatch(
    setRequestInProcess(false, getRequestName(crudType, "sendInvateToTester"))
  );
};

export const registerTesterByManager = (
  model,
  formName,
  validationHandler,
  closeModal,
  resetForm,
  page,
  itemsPerPage,
  showStatus,
  filterText,
  userFilter
) => async dispatch => {
  dispatch(
    setRequestInProcess(
      true,
      getRequestName(crudType, "registerTesterByManager")
    )
  );
  let data;
  try {
    data = await (getRequestFromType(
      crudType
    ) as any).registerTesterByManagerRequest(model);
  } catch (error) {
    if (error.response.status === 400) {
      dispatch(
        stopSubmit(
          formName,
          validationHandler(result(error, "response.body.violations", {}))
        )
      );
    }
    dispatch(
      setRequestInProcess(
        false,
        getRequestName(USERS, "registerTesterByManager")
      )
    );
    return;
  }
  closeModal();
  resetForm();

  dispatch(
    getUsersFiltered(
      page,
      itemsPerPage,
      showStatus,
      filterText,
      "",
      "",
      userFilter,
      "",
      ""
    )
  );
  dispatch(openSnackbar(data.body.message || "Success", 4000));
  dispatch(
    setRequestInProcess(false, getRequestName(USERS, "registerTesterByManager"))
  );
};

export const unlinkTesterAction = userFilter => (
  testerProfileId,
  testerId,
  page,
  itemsPerPage,
  showStatus,
  filterText
) => async dispatch => {
  dispatch(
    setRequestInProcess(
      true,
      getRequestName(crudType, "unlinkTesterAction", "" + testerProfileId)
    )
  );

  try {
    await (getRequestFromType(crudType) as any).unlinkTesterRequest(
      testerProfileId
    );
  } catch (error) {
    if (result(error, "response.status") === 422) {
      await dispatch(getUsersFilteredForReassign(userFilter));
      await dispatch(
        reassignModalTitle(result(error, "response.body.hydra:description"))
      );
      await dispatch(reassignModalOpen(testerId));
      await dispatch(reassignModalGroup(userFilter));
    }

    dispatch(
      setRequestInProcess(
        false,
        getRequestName(crudType, "unlinkTesterAction", "" + testerProfileId)
      )
    );
    return;
  }

  dispatch(
    setRequestInProcess(
      false,
      getRequestName(crudType, "unlinkTesterAction", "" + testerProfileId)
    )
  );

  dispatch(
    getUsersFiltered(
      page,
      itemsPerPage,
      showStatus,
      filterText,
      "",
      "",
      userFilter,
      "",
      ""
    )
  );
  dispatch(openSnackbar("Successfully removed", 4000));
};

export const reassignCompanyTesterAction = (
  testerFromUserId,
  testerToId,
  groupName,
  testerFromProfileId,
  page,
  itemsPerPage,
  showStatus,
  filterText
) => async dispatch => {
  dispatch(
    setRequestInProcess(
      true,
      getRequestName(crudType, "reassignTester", "" + testerFromUserId)
    )
  );

  try {
    await (getRequestFromType(crudType) as any).reassignCompanyTester(
      testerFromUserId,
      testerToId
    );
  } catch (error) {
    /** Show error popup */
    if (result(error, "response.status") === 422) {
      await dispatch(getUsersFilteredForReassign(groupName));
      await dispatch(
        reassignModalTitle(result(error, "response.body.hydra:description"))
      );
      await dispatch(reassignModalOpen(testerFromUserId));
      await dispatch(reassignModalGroup(groupName));
    }
    dispatch(
      setRequestInProcess(
        false,
        getRequestName(crudType, "reassignTester", "" + testerFromUserId)
      )
    );
    return;
  }
  dispatch(
    unlinkTesterAction(groupName)(
      testerFromProfileId,
      testerFromUserId,
      page,
      itemsPerPage,
      showStatus,
      filterText
    )
  );
  dispatch(
    setRequestInProcess(
      false,
      getRequestName(crudType, "deleteItem", "" + testerFromProfileId)
    )
  );
};

export const getInputConsultants = (filter = {}) => async dispatch => {
  dispatch(setRequestInProcess(true, "getInputUsersConsultants"));

  const data = await getUsersConsultants(filter);

  dispatch(createCrudAction(crudType).addItem(data.body["hydra:member"]));
  dispatch(setRequestInProcess(false, "getInputUsersConsultants"));
};

export const assignConsultants = data => async dispatch => {
  dispatch(setRequestInProcess(true, "assignConsultants"));

  try {
    await putAssignConsultantAction(data);
  } catch (e) {
    dispatch(setRequestInProcess(false, "assignConsultants"));
    dispatch(openSnackbar(e.message, MILLISECONDS_FOR_SHOWING_MESSAGE));
    return;
  }

  dispatch(setRequestInProcess(false, "assignConsultants"));
};
