import { ROLE_EMPLOYEE, ROLE_EXTERNAL } from "./roles";

export const plannerMenuItems = baseUrl => [
  { title: "Dashboard", url: baseUrl + "/dashboard", icon: "home" },
  { title: "Schedule", url: baseUrl + "/schedule", icon: "event_note" },
  {
    title: "Booking\xa0Requests",
    url: baseUrl + "/booking-requests",
    icon: "comment"
  },
  { title: "Reports", url: baseUrl + "/reports", icon: "description" },
  {
    title: "Projects",
    url: baseUrl + "/tests-and-projects",
    icon: "view_list"
  },
  { title: "Virtual\xa0Team", url: baseUrl + "/virtual-team", icon: "people" },
  { title: "FAQ", url: baseUrl + "/faq", icon: "help" },
  { title: "Admin", url: baseUrl + "/admin", icon: "settings" }
];

export const testerMenuItems = baseUrl => [
  { title: "My\xa0Profile", url: baseUrl + "/account", icon: "home" },
  {
    title: "Booking\xa0Requests",
    url: baseUrl + "/booking-requests",
    icon: "comment"
  },
  { title: "Bookings", url: baseUrl + "/bookings", icon: "description" },
  {
    title: "Rules\xa0of\xa0Engagement",
    url: baseUrl + "/rules-and-engagement",
    icon: "import_contacts",
    excludedRoles: [ROLE_EMPLOYEE, ROLE_EXTERNAL]
  }
];

export const clientsMenuItems = baseUrl => [
  { title: "FAQ", url: baseUrl + "/faq", icon: "help" },
  {
    title: "Projects",
    url: baseUrl + "/tests-and-projects",
    icon: "view_list"
  }
];

export const ownerMenuItems = (baseUrl, isAvordFirst) => [
  { title: "Dashboard", url: baseUrl + "/dashboard", icon: "home" },
  { title: "Schedule", url: baseUrl + "/schedule", icon: "event_note" },
  {
    title: "Booking\xa0Requests",
    url: baseUrl + "/booking-requests",
    icon: "comment"
  },
  { title: "Reports", url: baseUrl + "/reports", icon: "description" },
  {
    title: "Projects",
    url: baseUrl + "/tests-and-projects",
    icon: "view_list"
  },
  !isAvordFirst && { title: "Virtual\xa0Team", url: baseUrl + "/virtual-team", icon: "people" },
  { title: "FAQ", url: baseUrl + "/faq", icon: "help" },
  { title: "Admin", url: baseUrl + "/admin", icon: "settings" }
].filter(Boolean);

export const adminMenuItems = baseUrl => [
  { title: "Carousel", url: baseUrl + "/carousel", icon: "" },
  { title: "Content\xa0Management", url: baseUrl + "/content", icon: "" },
  { title: "Email\xa0Templates", url: baseUrl + "/email-templates", icon: "" },
  { title: "Countries", url: baseUrl + "/countries", icon: "" },
  { title: "Sector", url: baseUrl + "/sector", icon: "" },
  { title: "Certificate\xa0types", url: baseUrl + "/certificate", icon: "" },
  {
    title: "Timesheet\xa0Categories",
    url: baseUrl + "/timesheet-categories",
    icon: ""
  },
  {
    title: "Expenses\xa0Types",
    url: baseUrl + "/expenses-types",
    icon: ""
  },
  { title: "Finance", url: baseUrl + "/finance", icon: "" },
  { title: "FAQ", url: baseUrl + "/faq", icon: "help" },
  {
    title: "Report\xa0Templates",
    url: baseUrl + "/report-templates",
    icon: ""
  },
  {
    title: "Specialisms\xa0(Test\xa0types)",
    url: baseUrl + "/specialisms",
    icon: ""
  },
  {
    title: "Application\xa0Settings",
    url: baseUrl + "/application-settings",
    icon: ""
  }
];

export const superAdminMenuItems = baseUrl => [
  { title: "Admin Users", url: baseUrl + "/admin-users", icon: "group" },
  { title: "Carousel", url: baseUrl + "/carousel", icon: "" },
  { title: "Content Management", url: baseUrl + "/content", icon: "" },
  { title: "Email Templates", url: baseUrl + "/email-templates", icon: "" },
  { title: "Countries", url: baseUrl + "/countries", icon: "" },
  { title: "Sector", url: baseUrl + "/sector", icon: "" },
  { title: "Finance", url: baseUrl + "/finance", icon: "" },
  { title: "FAQ", url: baseUrl + "/faq", icon: "" },
  { title: "Report Templates", url: baseUrl + "/report-templates", icon: "" },
  {
    title: "Specialisms (Test types)",
    url: baseUrl + "/specialisms",
    icon: ""
  },
  {
    title: "Application Settings",
    url: baseUrl + "/application-settings",
    icon: ""
  }
];

export const companyManagerMenuItems = baseUrl => [
  { title: "My\xa0Profile", url: baseUrl + "/account", icon: "home" },
  {
    title: "Booking\xa0Requests",
    url: baseUrl + "/booking-requests",
    icon: "comment"
  },
  { title: "Bookings", url: baseUrl + "/bookings", icon: "description" },
  {
    title: "Rules\xa0of\xa0Engagement",
    url: baseUrl + "/rules-and-engagement",
    icon: "import_contacts",
    excludedRoles: [ROLE_EMPLOYEE, ROLE_EXTERNAL]
  },
  { title: "Admin", url: baseUrl + "/admin", icon: "settings" }
];
