import * as React from "react";
import { WithStyles, withStyles, Zoom } from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import Typography from "@material-ui/core/Typography/Typography";
import { Link } from "react-router-dom";
import { styles } from "styles/material/tesrer-card";
import {
  ITesterIncoming,
  ITesterProfile,
  ITesterProfileGet
} from "models/tester-profile.interface";
import {
  getProfile,
  getSpecialismsEditOpen,
  getTesterSpecialisms
} from "selectors/tester-profile";
import { getProfileFromJWT } from "helpers/jwt-checker";
import { logOut } from "actions";
import { createLoaderActionItem } from "interfaces/reducer-item.interface";
import * as actionType from "constants/action-type";
import { dataFetcher } from "components/dataFetcher";
import { IAnyProps } from "interfaces/any-props.interface";
import { ISpecialism } from "models/specialism.interface";
import { IBackRoute } from "components/routerModal";
import ReduxTextField from "components/forms/ReduxTextField";
import { FieldGeneric } from "components/forms/FieldGeneric";
import Grid from "@material-ui/core/Grid/Grid";
import {
  composeValidators,
  fieldIsNumber,
  fieldMaxLength,
  fieldMaxValueForNumber,
  fieldMinValueForNumber,
  fieldsIsLinkedInLink,
  fieldsNotEmpty,
  fieldsNotEmptyDependsCheckbox
} from "helpers/validations/validation";
import {
  formValueSelector,
  InjectedFormProps,
  reduxForm,
  change,
  Field
} from "redux-form";
import { connect } from "react-redux";
import {
  putTesterProfile,
  specialismsEditOpen,
  specialismsEditClose
} from "actions/testers";
import Icon from "@material-ui/core/Icon/Icon";
import IconButton from "@material-ui/core/IconButton/IconButton";
import SpecialismsView from "../specialisms/SpecialismsView";
import { getSpecialismsFlat } from "helpers/specialisms";
import SpecialismsEditList from "../specialisms/SpecialismsEditList";
import * as _ from "lodash";
import ReduxImageFileField from "components/forms/ReduxImageFileField";
import { getLinkedInCompanies } from "selectors/linkedin";
import ReduxSelectField from "components/forms/ReduxSelectField";
import { currenciesList } from "constants/lists/currencies-list";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import {
  handleSpaceKeyPress
} from "helpers/validations/emptyTestFieldValidation";
import {
  handleKeyPressOnNumberField
} from "helpers/validations/numberFieldValidator";
import ReduxSwitch from "components/forms/ReduxSwitch";
import { putTesterProfileAsync } from "actions/tester-profile";
import ReduxSingleCountryField from "components/forms/ReduxSingleCountryField";
import { BASE_URL } from "constants/urls";
import { StyleRules } from "@material-ui/core/styles";
import { STATUS_VALIDATION_PASSED } from "constants/dbs-certificate-statuses";
import AreYouSureModal from "components/table/AreYouSureModal";

type ITester = ITesterProfile & ITesterIncoming;

interface IOwnProps {
  profile: ITesterProfile & ITesterIncoming & ITesterProfileGet;
  specialisms: ISpecialism[];
  specialismsSelected: string[];
  linkedinCompanies: string[];
  isCrestRegisteredCompany: boolean;
  isCheckRegisteredCompany: boolean;
  isHasVatNumber: boolean;
  vatNumber: string;
}

interface IMapProps {
  isSpecialismsEditOpen: boolean;
}

interface IDispatchProps {
  putProfile: (profile: IAnyProps, backRoute: string, formName: string) => void;
  putTesterProfileAsync: (
    profile: IAnyProps,
    backRoute: string,
    formName: string
  ) => void;
  specialismsEditOpen: () => void;
  specialismsEditClose: () => void;
}

type IProps = IOwnProps &
  WithStyles &
  IBackRoute &
  InjectedFormProps<ITester> &
  IMapProps &
  IDispatchProps;

export const FORM_NAME = "ProfileEdit";
const selector = formValueSelector(FORM_NAME);

const CONFIRM_MESSAGE = "Your DBS validation has passed. Changing your profile data will require your DBS status to be re-validated. Are you sure you want to proceed?";

class ProfileEdit extends React.Component<IProps, { isConfirmModalOpen: boolean }> {
  constructor(props) {
    super(props);

    this.state = {
      isConfirmModalOpen: false,
    }
  }

  componentWillReceiveProps(
    nextProps: Readonly<IProps>,
    nextContext: any
  ): void {
    /** Added */
    const added = _.difference(
      nextProps.specialismsSelected,
      this.props.specialismsSelected
    );
    if (added.length) {
      const children: ISpecialism[] = _.result(
        _.find(this.props.specialisms, {id: +added[0]} as any),
        "subTypes",
        []
      );

      children.forEach(child => {
        this.props.change(`specialisms.${child.id}`, true);
      });
    }

    /** Removed */
    const removed = _.difference(
      this.props.specialismsSelected,
      nextProps.specialismsSelected
    );

    if (removed.length) {
      const children: ISpecialism[] = _.result(
        _.find(this.props.specialisms, {id: +removed[0]} as any),
        "subTypes",
        []
      );

      children.forEach(child => {
        this.props.change(`specialisms.${child.id}`, false);
      });
    }

    /** Check General */
    const allSpecialismsWithoutGeneral = this.props.specialisms.filter(
      item => item && item.id !== 1
    );

    if (_.first(nextProps.specialismsSelected) === "1") {
      allSpecialismsWithoutGeneral.forEach(parent => {
        this.props.change(`specialisms.${parent.id}`, false);

        if (parent.subTypes) {
          parent.subTypes.forEach(child => {
            this.props.change(`specialisms.${child.id}`, false);
          });
        }
      });
    }
  }

  renderLabel = () => {
    const {profile} = this.props;
    if (profile && profile.user && profile.user.groupName === "AVORD Tester") {
      if (!!profile.testingCompanyName) {
        return "Testing Company name";
      }
      return "Input company name"
    }
    return "Input Testing Company name";
  }

  disableCompanyNameField = () => {
    const {profile} = this.props;
    if (profile && profile.user && profile.user.groupName === "AVORD Tester") {
      return !!profile.testingCompanyName;
    }
    return false;
  }

  onSubmitHandler = (event) => {
    event.preventDefault();

    const {profile} = this.props;

    if (profile.dBSCheckMinStatus === STATUS_VALIDATION_PASSED) {
      this.setState((state) => ({
        ...state,
        isConfirmModalOpen: true,
      }));

      return;
    }

    this.sendToBackEnd();
  }

  private sendToBackEnd() {
    this.props.handleSubmit(values => {
      this.props.putTesterProfileAsync(
        values,
        this.props.backRoute,
        FORM_NAME
      );
    })();
  }

  hideConfirmModal = () => {
    this.setState((state) => ({
      ...state,
      isConfirmModalOpen: false,
    }));
  }

  onConfirmHandler = () => {
    this.sendToBackEnd();
  }

  render() {
    if (!this.props.profile) {
      return (
        <div>
          Loading...
        </div>
      );
    }
    return (
      <Grid>
        {this.props.profile.dBSCheckMinStatus === STATUS_VALIDATION_PASSED && <AreYouSureModal
          open={this.state.isConfirmModalOpen}
          onClose={this.hideConfirmModal}
          onConfirm={this.onConfirmHandler}
          message={CONFIRM_MESSAGE}
          confirmButtonText="Confirm"
          confirmHint="Yes, proceed and require re-validation"
          cancelButtonText="Cancel"
          cancelHint=" No, keep my current profile data."
        />}
        <form
          noValidate
          autoComplete="off"
          onSubmit={this.onSubmitHandler}
        >
          <div className={this.props.classes.details}>
            <Typography
              variant="headline"
              className={this.props.classes.header}
            >
              <span>
                <Typography variant="headline">My Profile</Typography>
              </span>
            </Typography>
            <hr />
            <Grid container>
              <Grid container spacing={24}>
                <Grid item xs={12} sm={6}>
                  <FieldGeneric
                    inputProps={{minLength: 1, maxLength: 50}}
                    name="testerFirstName"
                    label="First name *"
                    component={ReduxTextField}
                  />
                  <FieldGeneric
                    inputProps={{minLength: 1, maxLength: 50}}
                    name="testerLastName"
                    label="Last name *"
                    component={ReduxTextField}
                  />
                  {this.props.isSpecialismsEditOpen ? (
                    <IconButton onClick={this.props.specialismsEditClose}>
                      <Icon>navigate_before</Icon>
                    </IconButton>
                  ) : (
                    <Button onClick={this.props.specialismsEditOpen}>
                      <Icon>edit</Icon>
                      <span className="ml-3">
                        To update your specialisms click here
                      </span>
                    </Button>
                  )}

                  {this.props.isSpecialismsEditOpen ? (
                    <SpecialismsEditList />
                  ) : (
                    <SpecialismsView />
                  )}

                  <Grid container alignItems={"flex-end"}>
                    <Grid item md={6} xs={12}>
                      <FieldGeneric
                        name="linkedinLink"
                        label="LinkedIn profile *"
                        component={ReduxTextField}
                        onKeyPress={handleSpaceKeyPress}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item lg={6} xs={12}>
                      {/*<LinkedInButton />*/}
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item lg={6} xs={12}>
                      {this.props.profile && !!this.props.profile.testingCompanyName ?
                        (
                          <FieldGeneric
                            fullWidth={true}
                            name="testingCompanyName"
                            label={this.renderLabel()}
                            component={ReduxTextField}
                            allowDisable
                            disabled={this.disableCompanyNameField()}
                          />
                        ) : (
                          <FieldGeneric
                            fullWidth={true}
                            name="companyName"
                            label={this.renderLabel()}
                            component={ReduxTextField}
                            allowDisable
                            disabled={this.disableCompanyNameField()}
                          />
                        )}

                    </Grid>
                  </Grid>

                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <FieldGeneric
                        inputProps={{maxLength: 15}}
                        name="companyNumber"
                        label="Enter company registration number *"
                        component={ReduxTextField}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={16}>
                    <Grid item xs={4}>
                      <FieldGeneric
                        name="currencyCode"
                        label="Currency *"
                        component={ReduxSelectField}
                        fullWidth={true}
                      >
                        <MenuItem value={currenciesList[1].value}>
                          {currenciesList[1].label}
                        </MenuItem>
                      </FieldGeneric>
                    </Grid>
                    <Grid item xs={7}>
                      <FieldGeneric
                        name="rate"
                        label="Rate *"
                        component={ReduxTextField}
                        onKeyPress={handleKeyPressOnNumberField}
                        fullWidth={true}
                        inputProps={{min: 450, max: 10000}}
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={1} className="h-100 pt-5">
                      <Tooltip
                        placement={"bottom-end"}
                        TransitionComponent={Zoom}
                        disableFocusListener
                        disableTouchListener
                        title="Be competitive with your pricing so you can appear more often in client searches"
                      >
                        <Icon>info</Icon>
                      </Tooltip>
                    </Grid>
                  </Grid>

                  <Grid container spacing={0}>
                    <Grid item lg={7} xs={12} className="pt-4">
                      <Field
                        name="isHasVatNumber"
                        label="Do you have a VAT number?"
                        component={ReduxSwitch}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item lg={5} xs={12}>
                      <FieldGeneric
                        disabled={!this.props.isHasVatNumber}
                        inputProps={{maxLength: 20}}
                        name="vatNumber"
                        label="Enter VAT number"
                        component={ReduxTextField}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={0}>
                    <Grid item xs={12} className="pt-4">
                      <Field
                        name="isGovernmentCleared"
                        label="Government Cleared?"
                        component={ReduxSwitch}
                        margin="normal"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={0}>
                    <Grid item lg={7} xs={12} className="pt-4">
                      <Field
                        name="isCheckRegisteredCompany"
                        label="Are you a CHECK registered company?"
                        component={ReduxSwitch}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item lg={5} xs={12}>
                      <FieldGeneric
                        disabled={!this.props.isCheckRegisteredCompany}
                        inputProps={{maxLength: 15}}
                        name="checkRegisteredCompany"
                        label="Enter CHECK Scheme ID"
                        component={ReduxTextField}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={0}>
                    <Grid item lg={7} xs={12} className="pt-4">
                      <Field
                        name="isCrestRegisteredCompany"
                        label="Are you a CREST registered company?"
                        component={ReduxSwitch}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item lg={5} xs={12}>
                      {this.props.isCrestRegisteredCompany ? (
                        <FieldGeneric
                          name="companyCrestCertificate"
                          label="Upload Membership Certificate"
                          component={ReduxImageFileField}
                        />
                      ) : (
                        <div />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div style={{maxHeight: "400px"}}>
                    <span className="rounded-image">
                      <img
                        src={this.props.profile.profileImagePath ? BASE_URL + this.props.profile.profileImagePath : "/images/user.svg"}
                        id="uploadImagePreview"
                        alt="Profile image"
                      />
                    </span>
                  </div>
                  <FieldGeneric
                    name="picture"
                    label="Profile picture"
                    component={ReduxImageFileField}
                    needPreview
                  />
                  <div>Location</div>
                  <FieldGeneric
                    name="country"
                    label="Country of origin *"
                    type="number"
                    component={ReduxSingleCountryField}
                  />
                  <FieldGeneric
                    name="testerAddressLine"
                    label="Address line 1 *"
                    component={ReduxTextField}
                  />
                  <FieldGeneric
                    name="testerAddressLine2"
                    label="Address line 2"
                    component={ReduxTextField}
                  />
                  <FieldGeneric
                    name="testerAddressLine3"
                    label="Address line 3"
                    component={ReduxTextField}
                  />
                  <FieldGeneric
                    name="testerTown"
                    label="Town/City *"
                    component={ReduxTextField}
                  />
                  <FieldGeneric
                    name="testerState"
                    label="State/Province"
                    component={ReduxTextField}
                  />
                  <FieldGeneric
                    name="postCode"
                    label="Post/zip code *"
                    component={ReduxTextField}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <FieldGeneric
                  rows={4}
                  multiline={true}
                  name="bio"
                  label="Bio *"
                  component={ReduxTextField}
                />
              </Grid>
              <br />
              <Grid container spacing={8}>
                <Grid item lg={1} xs={6}>
                  <Link to={this.props.backRoute}>
                    <Button fullWidth={true} variant="contained"
                            color="default">
                      Cancel
                    </Button>
                  </Link>
                </Grid>
                <Grid lg={10} />

                <Grid item lg={1} xs={6}>
                  <Button
                    fullWidth={true}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </form>
      </Grid>
    );
  }
}

const styled = withStyles(styles as StyleRules)(ProfileEdit);

const componentWithForm = reduxForm({
  form: FORM_NAME,
  validate: composeValidators(
    fieldsNotEmpty([
      "postCode",
      "companyNumber",
      "testerAddressLine",
      "testerFirstName",
      "testerLastName",
      "testerTown",
      "currencyCode",
      "rate",
      "country",
      "bio",
      "linkedinLink"
    ]),
    fieldsNotEmptyDependsCheckbox(
      ["vatNumber", "checkRegisteredCompany", "companyCrestCertificate"],
      ["isHasVatNumber", "isCheckRegisteredCompany", "isCrestRegisteredCompany"]
    ),
    fieldIsNumber(["rate"]),
    fieldMaxLength(
      ["testerFirstName", "testerLastName", "testerState", "testerTown"],
      50
    ),
    fieldMaxLength(
      ["testerAddressLine", "testerAddressLine2", "testerAddressLine3"],
      255
    ),
    fieldMaxLength(["postCode"], 10),
    fieldMaxLength(["bio"], 2000),
    fieldMaxValueForNumber(["rate"], 10000),
    fieldMinValueForNumber(["rate"], 450),
    fieldsIsLinkedInLink(["linkedinLink"])
  )
})(styled);

const connected = connect(
  state => {
    const profile = getProfile(state);
    const specialisms = getTesterSpecialisms(state);

    if (
      (!profile.loaded || !profile.item) ||
      (!specialisms.loaded || !specialisms.item)
    ) {
      return {};
    }

    const specialismsSelected = selector(state, "specialisms");

    return {
      initialValues: {
        ...profile.item,
        currencyCode:
          profile.item && profile.item.currencyInfo
            ? profile.item.currencyInfo.code
            : "GBP",
        specialisms: !specialisms.item
          ? {}
          : getSpecialismsFlat(specialisms.item)
            .map(a => "" + a.id)
            .reduce((acc, val) => ({...acc, [val]: true}), {}),
        isHasVatNumber: !!(profile.item && profile.item.vatNumber),
        isCheckRegisteredCompany: !!(
          profile.item && profile.item.checkRegisteredCompany
        ),
        isCrestRegisteredCompany: !!(
          profile.item && profile.item.companyCrestCertificate
        )
      },
      specialismsSelected: Object.keys(specialismsSelected || {}).filter(
        specId => {
          return specialismsSelected[specId];
        }
      ),
      isSpecialismsEditOpen: getSpecialismsEditOpen(state),
      linkedinCompanies: getLinkedInCompanies(state),
      isCrestRegisteredCompany: selector(state, "isCrestRegisteredCompany"),
      isHasVatNumber: selector(state, "isHasVatNumber"),
      isCheckRegisteredCompany: selector(state, "isCheckRegisteredCompany"),
    };
  },
  {
    putProfile: putTesterProfile,
    putCRESTDocument: putTesterProfile,
    putTesterProfileAsync,
    specialismsEditOpen,
    specialismsEditClose,
    change
  }
)(componentWithForm);

export default dataFetcher(connected, [
  {
    key: "profile",
    action: () => {
      const profile = getProfileFromJWT();

      if (!profile) {
        return logOut();
      }

      return createLoaderActionItem(actionType.TESTER_PROFILE).get();
    },
    selector: state => getProfile(state),
    alwaysReceiveFreshData: true
  },
  {
    key: "specialisms",
    action: () => {
      return createLoaderActionItem(actionType.TESTER_SPECIALISMS).get();
    },
    selector: state => getTesterSpecialisms(state)
  }
]) as React.ComponentClass<IBackRoute>;
