import * as React from "react";
import { connect } from "react-redux";
import { WithStyles, withStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Button from "@material-ui/core/Button/Button";
import Typography from "@material-ui/core/Typography/Typography";
import { styles } from "styles/material/tesrer-card";
import { IInsurance } from "models/insurance.interface";
import { Link } from "react-router-dom";
import InsurancesTable from "./InsurancesTable";
import { RouteComponentProps, withRouter } from "react-router-dom";
import selector from "selectors/insurance";
import moment from "moment";
import { TableNoItems } from "components/table/TableNoItems";
import { RootState } from "reducers";
import { StyleRules } from "@material-ui/core/styles";
import { ConnectedProps } from "types";

type ComponentProps = PropsFromRedux & WithStyles & RouteComponentProps<{}>;

class InsurancesCard extends React.Component<ComponentProps> {

  baseUrl = this.props.match.path;

  highlight = () => {
    const { insurances } = this.props;

    const expiredInsurance = insurances.find(
      (insurance: IInsurance) =>
        moment(insurance.validTo).format("YYYY-MM-DD") <
        moment().format("YYYY-MM-DD")
    );

    return expiredInsurance || insurances.length < 2 ? "box-shadow-danger" : "";
  };

  renderInsurancesComponent() {
    const { insurances, isLoaded } = this.props;

    if (!isLoaded) {
      return <TableNoItems asLoading />;
    }

    if (isLoaded && insurances.length === 0) {
      return <TableNoItems />;
    }

    return <InsurancesTable baseUrl={this.baseUrl} insurances={insurances} />;
  }

  render() {
    return (
      <div className={this.highlight()}>
        <Card className={this.props.classes.card}>
          <div className={this.props.classes.details}>
            <CardContent className={this.props.classes.content}>
              <Typography
                variant="headline"
                className={this.props.classes.header}
              >
                <span>Insurances</span>
                <span className={this.props.classes.rightAlign}>
                  <Link to={`${this.baseUrl}/create-insurance`}>
                    <Button size="small" color="primary">
                      New Insurance
                    </Button>
                  </Link>
                </span>
              </Typography>
              <hr />
              {this.renderInsurancesComponent()}
            </CardContent>
          </div>
        </Card>
      </div>
    );
  }
}

const styled = withStyles(styles as StyleRules)(InsurancesCard);

const connector = connect((state: RootState) => {
  return {
    insurances: selector.getItems(state) as IInsurance[],
    isLoaded: selector.getIsLoaded(state) as boolean,
  };
});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(styled));
