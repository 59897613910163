import * as React from "react";
import { formatDateWithTime } from "helpers/date-formatter";
import { ITest } from "models/test.interface";
import {
  Grid,
  Icon, InputAdornment,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Zoom
} from "@material-ui/core";
import { ILocation } from "models/location.interface";
import { connect } from "react-redux";
import { getRoles, getUserGroup, getUserPermissions } from "selectors/auth";
import { Group } from "constants/group";
import { downloadSoWDocument } from "api/requests/document-download";
import {
  editProposalByTesterModalOpen,
  getAdditionalServicesList
} from "actions";
import {
  ROLE,
  ROLE_ACCOUNT_MANAGER,
  ROLE_ADMIN,
  ROLE_CONSULTANT,
  ROLE_PLANNER,
  ROLE_TESTER
} from "constants/roles";
import { TestStatus } from "constants/test-status";
import BookingProposalEdit from "./BookingProposalEdit";
import EditProposalModal from "./modal/EditProposalModal";
import { Permission } from "constants/permission";
import PermissionBlock from "components/auth/PermissionBlock";
import { Expenses } from "../../../constants/expenses";
import { FieldGeneric } from "../../../components/forms/FieldGeneric";
import ReduxTextField from "../../../components/forms/ReduxTextField";

export interface ICreated {
  test: ITest;
  locationTest: ILocation;
  baseUrl: string;
}

interface IAdditionalServicesProps {
  id: number;
  name: string;
  description: string;
  price: number;
  daysPercent: number;
  total: number;
}

export const isInputConsultant = roles =>
  roles.some(role => role === ROLE_CONSULTANT);

class BookingProposal extends React.Component<ICreated & any, {}> {
  state = {
    isEditProposal: false
  };
  isOwnerOrPlanner = () => {
    const {userGroup} = this.props;
    return userGroup === Group.OWNER || userGroup === Group.PLANNER;
  };

  isOwnerOrPlannerOrAdmin = () => {
    const {userGroup, roles = [], permissions = []} = this.props;

    if (roles.includes(ROLE_ACCOUNT_MANAGER) || permissions.includes(Permission.CAN_VIEW_PLATFORM_INVOICES_AND_COSTS)) {
      return true;
    }

    return (
      this.isOwnerOrPlanner() ||
      userGroup === Group.ADMIN ||
      userGroup === Group.SUPER_ADMIN
    );
  };

  haveAdditionalServices = () => {
    const {test} = this.props;
    return test.proposal && !!test.proposal.managedServiceDescription;
  };

  haveServiceCharge = () => {
    const {test} = this.props;
    return (
      test.proposal &&
      (!!test.proposal.testerMargin || !!test.proposal.clientMargin)
    );
  };

  handleDownloadPDF = () => {
    const {test, downloadSoWDocument} = this.props;
    downloadSoWDocument(test.id);
  };

  componentDidMount() {
    const {getAdditionalServices, test} = this.props;

    if (!!test && this.isOwnerOrPlannerOrAdmin()) {
      getAdditionalServices(test.id);
    }
  }
  canViewProposal = (roles: ROLE[], permissions: Permission[]) => {
    const allowed = [ROLE_PLANNER, ROLE_ADMIN, ROLE_TESTER];
    const {test} = this.props;

    for (const key in allowed) {
      if (roles.includes(allowed[key])) {
        return true;
      }
    }

    if (
      roles.includes(ROLE_ACCOUNT_MANAGER)
      && permissions.includes(Permission.CAN_MANAGE_TESTS)
      && test.tester?.isAvordConsultancyCompany
    ) {
      return true;
    }

    if (
      roles.includes(ROLE_ACCOUNT_MANAGER)
      && permissions.includes(Permission.CAN_MANAGE_AVORD_CONSULTANCY_COMPANY_MANAGER)
    ) {
      return true;
    }


    return permissions.includes(Permission.CAN_VIEW_PLATFORM_INVOICES_AND_COSTS);
  }

  canViewPrices = (roles: ROLE[], permissions: Permission[]) => {
    const allowed = [ROLE_PLANNER, ROLE_ADMIN, ROLE_TESTER];
    const {test} = this.props;

    for (const key in allowed) {
      if (roles.includes(allowed[key])) {
        return true;
      }
    }

    if (
      roles.includes(ROLE_ACCOUNT_MANAGER)
      && permissions.includes(Permission.CAN_MANAGE_TESTS)
      && test.tester?.isAvordConsultancyCompany
    ) {
      return true;
    }

    return permissions.includes(Permission.CAN_VIEW_PLATFORM_INVOICES_AND_COSTS);
  }

  render() {
    const {
      test,
      downloadSoWDocument,
      additionalServices = [],
      auth: {roles, permissions},
      editProposalByTesterModalOpen
    } = this.props;
    if (!test) {
      return <div className="styled-block">Loading...</div>;
    }

    const isTester = roles.some(role => role === ROLE_TESTER);
    const isAccountManager = roles.some(role => role === ROLE_ACCOUNT_MANAGER);
    const isTestStatusProposalSubmittedOrBooked =
      test.status === TestStatus.STATUS_PROPOSAL_SUBMITTED ||
      test.status === TestStatus.STATUS_BOOKED;
    const isShowingEditProposal =
      isTestStatusProposalSubmittedOrBooked
      && (
        isTester
        || (
          isAccountManager
          && permissions.includes(Permission.CAN_MANAGE_AVORD_CONSULTANCY_COMPANY_MANAGER)
          && test.tester?.isAvordConsultancyCompany
        )
      );

    if (this.state.isEditProposal) {
      return <BookingProposalEdit isEditByTester {...this.props} />;
    }

    if (test.proposal) {
      return (
        <div className="styled-block">
          {isShowingEditProposal && (
            <div className="d-flex justify-content-end">
              <button
                className="btn-accept mr-0"
                onClick={() => editProposalByTesterModalOpen()}
                type="submit"
              >
                Edit
              </button>
            </div>
          )}
          <div className="styled-table table-responsive">
            <div className="block-header col-4">
              {downloadSoWDocument &&
                this.isOwnerOrPlannerOrAdmin() &&
                !!test.proposal.clientMargin && (
                  <>
                    <Grid item className="pt-4 d-flex align-items-center">
                      <button
                        className="btn-download"
                        onClick={this.handleDownloadPDF}
                      >
                        {"Please download Statement of Work"}
                      </button>
                      <Tooltip
                        placement={"bottom-end"}
                        TransitionComponent={Zoom}
                        disableFocusListener
                        disableTouchListener
                        title="Once you have downloaded the SOW/Quotation, please add the relevant Purchase Order Number together with the signature of an authorised person and then email to support@avord.com"
                      >
                        <Icon>info</Icon>
                      </Tooltip>
                    </Grid>
                  </>
                )}
            </div>
            <div className="booking-proposal-view">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <h4 className="block-header mb-2">
                        Proposed work overview
                      </h4>
                      {test.proposal &&
                      !test.proposal.info.includes("<script>") ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: test.proposal.info
                          }}
                        />
                      ) : (
                        <div>{test.proposal.info}</div>
                      )}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <h4 className="block-header  mb-2">Booking proposal</h4>
                      <div className="d-flex flex-wrap">
                        <div className="booking-proposal-item">
                          <b>Dates: </b>
                          {formatDateWithTime(test.proposal.dateStart)} to{" "}
                          {formatDateWithTime(test.proposal.dateEnd)}
                        </div>
                        {!test.isOwnedByCompany && (
                          <div className="booking-proposal-item ml-5">
                            <b>Number of Days Effort: </b>
                            {test.proposal.countDays}
                          </div>
                        )}
                        <PermissionBlock has={this.canViewPrices}>
                          {!test.isOwnedByCompany &&
                            test.proposal.currency &&
                            !isInputConsultant(roles) && (
                              <div className="booking-proposal-item ml-5">
                                <b>Day Rate: </b>
                                {test.proposal.currency.symbol}
                                {test.proposal.rate}
                              </div>
                            )}
                        </PermissionBlock>
                      </div>
                      <div className="d-flex flex-wrap">
                        <PermissionBlock has={this.canViewProposal}>
                          {!test.isOwnedByCompany && test.proposal.currency && (
                            <>
                            <div className="booking-proposal-item">
                              {test.proposal.expensesMode === Expenses.DAILY_EXPENSES  ? (<b>Daily Expenses: </b>) : (<b>Expenses: </b>)}
                              {test.proposal.currency.symbol}
                              {test.proposal.expensesMode === Expenses.NO_EXPENSES && (0)}
                              {test.proposal.expensesMode === Expenses.EXPECTED_EXPENSES_AMOUNT && (test.proposal.expensesTotal)}
                              {test.proposal.expensesMode === Expenses.DAILY_EXPENSES && (test.proposal.dailyExpenses)}
                            </div>
                            {test.proposal.expensesMode === Expenses.DAILY_EXPENSES && (
                              <div className="booking-proposal-item ml-5">
                                <b>Number of days: </b>
                                {test.proposal.dailyExpensesDays}
                               </div>
                                )}
                            {test.proposal.expensesMode === Expenses.DAILY_EXPENSES && (
                              <div className="booking-proposal-item ml-5">
                                <b>Expenses Note: </b>
                                {test.proposal.dailyExpensesNotes}
                              </div>
                            )}
                            {test.proposal.expensesMode === Expenses.EXPECTED_EXPENSES_AMOUNT && (
                              <div className="booking-proposal-item ml-5">
                                <b>Expenses Note: </b>
                                {test.proposal.amountExpensesNotes}
                              </div>
                            )}
                            </>
                          )}
                        </PermissionBlock>
                      </div>
                      <PermissionBlock has={this.canViewPrices}>
                        {this.haveServiceCharge() && !isInputConsultant(roles) && (
                          <div className="booking-proposal-item">
                            <b>
                              AVORD service charge (
                              {this.isOwnerOrPlannerOrAdmin()
                                ? test.proposal.clientMarginPercent
                                : test.proposal.testerMarginPercent}
                              %):{" "}
                            </b>
                            {test.proposal.currency.symbol}
                            {this.isOwnerOrPlannerOrAdmin()
                              ? test.proposal.clientMargin
                              : test.proposal.testerMargin}
                          </div>
                        )}
                        {this.isOwnerOrPlannerOrAdmin() &&
                          test.proposal.currency &&
                          this.haveAdditionalServices() && (
                            <div className="booking-proposal-item">
                              <b>AVORD managed services: </b>
                              {test.proposal.managedServiceDescription} -{" "}
                              {test.proposal.currency.symbol}
                              {test.proposal.costManagedServices}
                              <Tooltip
                                placement={"top-end"}
                                disableFocusListener
                                disableTouchListener
                                title={
                                  <span>
                                  Service days: {test.proposal.serviceDays} days
                                  <div>
                                    AVORD management service day rate:{" "}
                                    {test.proposal.currency.symbol}
                                    {test.proposal.managedServicePrice}
                                  </div>
                                </span>
                                }
                              >
                                <Icon className="ml-2">info</Icon>
                              </Tooltip>
                            </div>
                          )}
                        {this.isOwnerOrPlannerOrAdmin() &&
                          !!Array.from(additionalServices).length && (
                            <div className="additional-service">
                              <b>Additional Services:</b>
                              {Array.from<IAdditionalServicesProps>(
                                additionalServices
                              ).map((field: IAdditionalServicesProps) => {
                                return (
                                  <div
                                    className="d-flex justify-content-between flex-wrap"
                                    key={field.id}
                                  >
                                    <div className="additional-service-item">
                                      {field.name}
                                    </div>
                                    <div className="additional-service-item">
                                      {field.description}
                                    </div>
                                    <div className="additional-service-item">
                                      <p>
                                        <span className="font-weight-bold">Days:</span> {field.daysPercent}
                                      </p>
                                    </div>
                                    <div className="additional-service-item">
                                      <p>
                                        <span className="font-weight-bold">Day rate:</span> {field.price}{" "}
                                      </p>
                                    </div>
                                    <div className="additional-service-item">
                                      <p>
                                        <span className="font-weight-bold">Total:</span> £{field.total}{" "}
                                      </p>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                        )}
                        {!test.isOwnedByCompany &&
                          test.proposal.currency &&
                          (!!test.proposal.fullTotalCostForTester ||
                            !!test.proposal.fullTotalCostForClient) &&
                          !isInputConsultant(roles) && (
                            <div className="booking-proposal-item">
                              <b>Total (excl VAT): </b>
                              {test.proposal.currency.symbol}
                              {this.isOwnerOrPlannerOrAdmin()
                                ? test.proposal.fullTotalCostForClient
                                : test.proposal.fullTotalCostForTester}
                            </div>
                        )}
                      </PermissionBlock>
                    </TableCell>
                  </TableRow>
                  {test.proposal.firstPhase &&
                    test.proposal.firstPhaseInformation && (
                      <TableRow>
                        <TableCell>
                          <h4 className="block-header mb-2">
                            Phase 1. {test.proposal.firstPhase}
                          </h4>
                          {!test.proposal.firstPhaseInformation.includes(
                            "<script>"
                          ) ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: test.proposal.firstPhaseInformation
                              }}
                            />
                          ) : (
                            <div>{test.proposal.firstPhaseInformation}</div>
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                  {test.proposal.secondPhase &&
                    test.proposal.secondPhaseInformation && (
                      <TableRow>
                        <TableCell>
                          <h4 className="block-header mb-2">
                            Phase 2. {test.proposal.secondPhase}
                          </h4>
                          {!test.proposal.secondPhaseInformation.includes(
                            "<script>"
                          ) ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: test.proposal.secondPhaseInformation
                              }}
                            />
                          ) : (
                            <div>{test.proposal.secondPhaseInformation}</div>
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                  {test.proposal.otherPhases &&
                    test.proposal.otherPhasesInformation && (
                      <TableRow>
                        <TableCell>
                          <h4 className="block-header mb-2">
                            Other phases. {test.proposal.otherPhases}
                          </h4>
                          {test.proposal.otherPhasesInformation &&
                          !test.proposal.otherPhasesInformation.includes(
                            "<script>"
                          ) ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: test.proposal.otherPhasesInformation
                              }}
                            />
                          ) : (
                            <div>{test.proposal.otherPhases}</div>
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                  {test.proposal.preRequisites && (
                    <TableRow>
                      <TableCell>
                        <h4 className="block-header mb-2">Pre-requisites</h4>
                        {!test.proposal.preRequisites.includes("<script>") ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: test.proposal.preRequisites
                            }}
                          />
                        ) : (
                          <div>{test.proposal.preRequisites}</div>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </TableHead>
              </Table>
            </div>
          </div>
          {!this.isOwnerOrPlannerOrAdmin() && (
            <div className="pt-4">
              <b>
                NOTE:
                <li>Must include the range of IP addresses and description.</li>
                <li>
                  On Client approval of this SoW remember to issue your
                  Authorisation form to test.
                </li>
              </b>
            </div>
          )}
          {/*// @ts-ignore*/}
          <EditProposalModal
            openEditMode={() => this.setState({isEditProposal: true})}
          />
        </div>
      );
    }

    return null;
  }
}

export default connect(
  (state: { currentUser; additionalServices; auth }) => {
    return {
      auth: state.auth,
      userGroup: getUserGroup(state),
      permissions: getUserPermissions(state),
      roles: getRoles(state),
      additionalServices: state.additionalServices.additionalServices || []
    };
  },
  {
    downloadSoWDocument,
    getAdditionalServices: getAdditionalServicesList,
    editProposalByTesterModalOpen
  }
)(BookingProposal) as any;
