import {IConverterFromSwagger, IConverterToSwagger} from "./converter.interface";
import {extractId} from "../../helpers/converter-helper";
import {API_DOCUMENTS_ENDPOINT_URL} from "../../constants/urls";

export const convertorFromSwagger: IConverterFromSwagger<any, any> = model => {
  return {
    ...model,
    documentUploadedAt: model.documentUploadedAt,
    report: extractId(model.report),
    document: model.document,
    specialism: extractId(model.specialism),
  };
};

export const convertorToSwagger: IConverterToSwagger<any, any> = model => {
  return {
    ...model,
    report: `/api/reports/${model.report}`,
    document: model.document ? API_DOCUMENTS_ENDPOINT_URL + model.document : null,
    specialism: `/api/specialisms/${model.specialism}`,
  };
};

export const convertorReportDocumentToSwagger: IConverterToSwagger<any, any> = model => {
  return {
    ...model,
    report: `/api/reports/${model.report}`,
    document: model.document ? API_DOCUMENTS_ENDPOINT_URL + model.document : null,
  };
};
