import * as React from "react";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table/Table";
import TableBody from "@material-ui/core/TableBody/TableBody";
import { connect } from "react-redux";
import { specialismsRequest } from "actions";
import EnhancedTableHead from "components/table/EnhancedTableHead";
import {  RootState } from "reducers";
import { TableCell, TableRow } from "@material-ui/core";
import { getEditLink } from "helpers/router-helper";
import { Link } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import Icon from "@material-ui/core/Icon/Icon";
import Button from "@material-ui/core/es/Button";
import { downloadReportTemplate } from "actions/specialisms-without-report-templates";
import { downloadDocumentFile } from "api/requests/document-download";
import { formatDateWithTime } from "helpers/date-formatter";
import { ConnectedProps } from "types";
import { ISpecialism } from "models/specialism.interface";

type Props = {baseUrl: string, specialismsWithoutReportTemplates: ISpecialism[]}

class ReportTemplatesList extends React.Component<PropsFromRedux & Props> {
  componentDidMount() {
    this.props.loadItems(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.data === nextProps.data) {
      this.props.loadItems(this.props);
    }
  }

  downloadFile = ({ document_uuid }: ISpecialism) => {
    if (document_uuid) {
      this.props.downloadDocumentFile(document_uuid);
    }
  };

  render() {
    const { data, isLoaded } = this.props;

    if (!isLoaded) {
      return <div>Loading...</div>;
    }

    if (!data.length) {
      return <div>There are no items!</div>;
    }

    return (
      <Paper style={{ overflowX: "auto", width: "100%" }} className="mb-4">
        <Table>
          <EnhancedTableHead columns={["Type", "Date", "Download", "Edit"]} />
          <TableBody>
            {data.map(item => {
              if (!item.reportTemplateDocument) {
                return;
              }

              return (
                <TableRow key={item.id}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{formatDateWithTime(item.date.date)}</TableCell>
                  <TableCell>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => this.downloadFile(item)}
                    >
                      Download
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Link to={getEditLink(this.props.baseUrl, item.id)}>
                      <IconButton>
                        <Icon>edit</Icon>
                      </IconButton>
                    </Link>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

const connector = connect(
  (state: RootState) => ({
    data: state.specialisms.items.items,
    isLoaded: state.auth.isLoaded
  }),

  {
    loadItems: specialismsRequest.getItems,
    downloadReportTemplate,
    downloadDocumentFile
  }
);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ReportTemplatesList);
