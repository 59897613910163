import {
  createLoaderAction,
  createCrudAction,
} from '../helpers/action-helper';
import {handleHttpErrors} from "../helpers/errors/http-error-handler";
import {REASSIGN_USERS} from "../constants/action-type";
import {setRequestInProcess} from "./request";
import {getItems} from "../api/requests/reassign-users";
import {openSnackbar} from "./snackbar";

export const getUsersFilteredForReassign = (userFilter, company) => async dispatch => {
  dispatch(setRequestInProcess(true, 'getUsersFilteredForReassign'));
  /** part responsible for a loader state */
  dispatch(createLoaderAction(REASSIGN_USERS).setIsNotLoaded());

  let data = [], totalItems;

  try {
    ({
      items: data,
      totalItems
    } = await getItems(userFilter, company));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(createCrudAction(REASSIGN_USERS).addItems(data));
  dispatch(setRequestInProcess(false, 'getUsersFilteredForReassign'));
};
