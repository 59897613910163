import * as React from "react";
import {withStyles, Modal, Grid, WithStyles} from "@material-ui/core";
import {defaultStyleWidth} from "../../../styles/material/popup-style";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {bookTesterAction} from "../../../actions/testers";
import {ITesterProfileGet} from "../../../models/tester-profile.interface";
import TestersView from "../schedule/testers/TestersView";
import ExternalTestersView from "../schedule/testers/ExternalTestersView";
import {countriesRequest} from "../../../actions/countries";
import {MAX_PAGINATION_RESULTS} from "../../../constants/loader";
import {ACTIVE} from "../../../constants/filter";
import {fetchMyData} from "../../../api/requests/auth";

interface IProps {
  open: boolean;
  onClose: () => void;
  tester: ITesterProfileGet;
  bookTesterAction: (testId: number, testerId: number) => void;
  loadCountries: (page, itemsPerPage, showStatus) => void;
}

interface IState {
  isAvordFirst: boolean;
}

class TesterProfileModal extends React.Component<IProps & RouteComponentProps<any> & WithStyles, IState> {
  state = {
    isAvordFirst: false,
    hasLoaded: false,
  };

  async componentDidUpdate(prevProps) {
    if (this.props.open && !prevProps.open && !this.state.hasLoaded) {
      const userProfile = await fetchMyData();
      this.props.loadCountries(1, MAX_PAGINATION_RESULTS, ACTIVE);
      this.setState({
        isAvordFirst: userProfile.isAvordFirst,
        hasLoaded: true,
      });
    }
  }

  render() {
    const {tester} = this.props;
    const {isAvordFirst} = this.state;

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.open}
        onClose={this.props.onClose}
        className="d-flex overflow-auto"
      >
        <div className={`styled-modal ${(tester && !tester.isOwnedByCompany) ? 'lg' : 'md'} tester-modal`}>
          {
            (tester && !tester.isOwnedByCompany) && (
              <TestersView
                key={tester.id}
                item={tester}
                favoriteLink={`schedule/test/pre-fill/${tester.id}`}
                hasBookingButton={!isAvordFirst}
              />
            )
          }

          {
            (tester && tester.isOwnedByCompany) && (
              <ExternalTestersView
                key={tester.id}
                item={tester}
                hasBookingButton={!isAvordFirst}
              />
            )
          }
          <Grid container spacing={16}>
            <Grid item xs={12} className="text-right">
              <button className="btn-cancel" onClick={this.props.onClose}>Cancel</button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    );
  }
}

const connectedComponent = connect<IProps>(
  undefined,
  {
    bookTesterAction,
    loadCountries: countriesRequest.getItems
  }
)(withRouter(TesterProfileModal));

export default withStyles(defaultStyleWidth(640))(connectedComponent) as any;
