import * as Types from "../constants/action-type";
import {
  createFilterAction,
  createLoaderAction,
  createCrudAction,
  createRequestAction
} from "../helpers/action-helper";
import { createAction } from "redux-actions";
import { openSnackbar } from "./snackbar";
import { setRequestInProcess } from "./request";
import { push } from "react-router-redux";
import { getRequestFromType } from "../api/requests/request-factory";
import { reset } from "redux-form";
import { handleHttpErrors } from "../helpers/errors/http-error-handler";
import { getRequestName } from "../helpers/action-request-helper";
import {
  changeTestStatus,
  getBookingRequestsRequest,
  updateServiceDatesRequest
} from "../api/requests/test";
import { TestStatus } from "../constants/test-status";
import {
  getTestDocuments,
  putItem,
  savePONumberRequest,
  getItem
} from "../api/requests/test";
import {
  ADDITIONAL_DOCUMENT_SEARCH_APPLY,
  DOCUMENT_SEARCH_APPLY,
  TEST_ADDITIONAL_DOCUMENT
} from "../constants/action-type";
import { messageModalClose } from "./modals";
import { IAnyProps } from "../interfaces/any-props.interface";
import {
  uploadNewAdditionalTestDocument,
  uploadNewTestDocument
} from "./document";
import { getApi } from "../api/swagger/api-factory";
import { fetchRequestBody } from "../helpers/request-helper";
import { convertorFromSwagger } from "../api/convertors/project";
import {
  UpdateServiceDateActionType,
  UpdateServiceDateType
} from "../types/test-info";
import { ITest } from "models/test.interface";

export const testFilter = createFilterAction(Types.TEST);
export const testLoader = createLoaderAction(Types.TEST);
export const testRequest = createRequestAction(Types.TEST);

export const setTest = data => createAction(Types.TEST, () => data)();
export const setProject = data => createAction(Types.TEST, () => data)();

export const getCompletedTests = async () => {
  const data = await getApi()
    .then(api => api.complete_testsTestCollection())
    .then(fetchRequestBody)
    .then(convertorFromSwagger);

  return data;
};

export const getBookingRequestsAction = (
  page: number,
  itemsPerPage: number,
  project: number[],
  status: string[],
  dateFrom: string,
  dateTo: string,
  questionAsked: boolean
) => async dispatch => {
  dispatch(
    setRequestInProcess(true, getRequestName(Types.TEST, "getBookingRequests"))
  );
  /** part responsible for a loader state */
  dispatch(createLoaderAction(Types.TEST).setIsNotLoaded());

  let data = [],
    totalItems;

  try {
    ({ items: data, totalItems } = await getBookingRequestsRequest(
      page,
      itemsPerPage,
      project,
      status,
      dateFrom,
      dateTo,
      questionAsked
    ));
    dispatch(
      createLoaderAction(Types.TEST).setPaginatioData(
        data,
        itemsPerPage,
        totalItems,
        page
      )
    );
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(
    setRequestInProcess(false, getRequestName(Types.TEST, "getBookingRequests"))
  );
};

export const getTestForBookingAction = (
  page: number,
  itemsPerPage: number,
  project: number[],
  status: string[]
) => async dispatch => {
  dispatch(
    setRequestInProcess(true, getRequestName(Types.TEST, "getBookingRequests"))
  );
  /** part responsible for a loader state */
  dispatch(createLoaderAction(Types.TEST).setIsNotLoaded());

  let data = [],
    totalItems;

  try {
    ({ items: data, totalItems } = await getBookingRequestsRequest(
      page,
      itemsPerPage,
      project,
      status
    ));
    dispatch(
      createLoaderAction(Types.TEST).setPaginatioData(
        data,
        itemsPerPage,
        totalItems,
        page
      )
    );
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(
    setRequestInProcess(false, getRequestName(Types.TEST, "getBookingRequests"))
  );
};

const saveTest = (
  model,
  formName,
  validationHandler: (error: any) => IAnyProps,
  callback?: (dispatch) => void
) => async dispatch => {
  dispatch(setRequestInProcess(true, "saveTestAction saveTest"));

  let data;

  try {
    data = await (getRequestFromType(Types.TEST) as any).postItem(model);

    dispatch(openSnackbar("Created", 4000));
    dispatch(createCrudAction(Types.TEST).addItem(data));
    dispatch(reset(formName));

    if (callback) {
      callback(data);
    }
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, "saveTestAction saveTest"));
};

export const updateTest = (
  id,
  model,
  formName,
  callback?: (dispatch) => void
) => async dispatch => {
  dispatch(setRequestInProcess(true, "saveTestAction updateTest"));

  let data;

  if (!model.dateFrom && !model.dateTo) {
    model.dateFrom = null;
    model.dateTo = null;
  }

  try {
    data = await (getRequestFromType(Types.TEST) as any).putItem(id, model);

    dispatch(openSnackbar("Updated", 4000));
    dispatch(createCrudAction(Types.TEST).addItem(data));
    dispatch(reset(formName));

    if (callback) {
      callback(data);
    }
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, "saveTestAction updateTest"));
};

export const saveTestAction = (
  model,
  formName,
  backRoute,
  validationHandler: (error: any) => IAnyProps
) => async dispatch => {
  dispatch(
    saveTest(model, formName, validationHandler, data => {
      if (!!model.dateFrom && !!model.dateTo) {
        dispatch(setTestStatusAction(data.id, TestStatus.STATUS_SCHEDULED));
      }
      dispatch(push(backRoute));
    })
  );
};

export const saveTestActionAndBookTester = (
  model,
  formName,
  validationHandler: (error: any) => IAnyProps
) => async dispatch => {
  dispatch(
    saveTest(model, formName, validationHandler, data => {
      if (!!model.dateFrom && !!model.dateTo) {
        dispatch(
          setTestStatusAction(data.id, TestStatus.STATUS_SCHEDULED, data => {
            dispatch(
              push(`/client/schedule/booking/${model.project}/${data.id}`)
            );
          })
        );
      }
    })
  );
};

export const updateTestAction = (
  id,
  model,
  formName,
  backRoute
) => async dispatch => {
  if (!model.dateFrom && !model.dateTo) {
    model.dateFrom = null;
    model.dateTo = null;
  }
  dispatch(
    updateTest(id, model, formName, data => {
      if (!model.dateFrom && !model.dateTo) {
        if (model.status && model.status === TestStatus.STATUS_SCHEDULED) {
          dispatch(setTestStatusAction(data.id, TestStatus.STATUS_DRAFT));
        }
      } else {
        if (model.status && model.status === TestStatus.STATUS_DRAFT) {
          dispatch(setTestStatusAction(data.id, TestStatus.STATUS_SCHEDULED));
        }
      }
      dispatch(push(backRoute));
    })
  );
};

const TEST_DOCUMENTS_STATUSES = [
  TestStatus.STATUS_DRAFT,
  TestStatus.STATUS_SCHEDULED,
  TestStatus.STATUS_BOOKING_REQUESTED,
  TestStatus.STATUS_BOOKING_REQUESTED_EDITED
];

const ADDITIONAL_DOCUMENT_STATUSES = [
  TestStatus.STATUS_PROPOSAL_SUBMITTED,
  TestStatus.STATUS_PROPOSAL_SUBMITTED_CANCELLED_BY_CUSTOMER,
  TestStatus.STATUS_PROPOSAL_SUBMITTED_CANCELLED_BY_TESTER,
  TestStatus.STATUS_BOOKED,
  TestStatus.STATUS_REPORT_SUBMITTED,
  TestStatus.STATUS_REPORT_ACCEPTED
];

export const postTestDocumentAction = (
  model,
  formName,
  backRoute,
  test: ITest
) => async dispatch => {
  dispatch(setRequestInProcess(true, "postTestDocumentAction"));

  let createdFile;

  const payload = {
    document_id: 0,
    test_id: 0
  };

  try {
    if (model.testDocuments instanceof File) {
      if (TEST_DOCUMENTS_STATUSES.includes(test.status)) {
        createdFile = await dispatch(
          uploadNewTestDocument(test, model.testDocuments)
        );

        if (createdFile) {
          dispatch(createCrudAction(Types.TEST_DOCUMENT).addItem(createdFile));
          dispatch(createAction(DOCUMENT_SEARCH_APPLY));
        }
      }

      if (ADDITIONAL_DOCUMENT_STATUSES.includes(test.status)) {
        createdFile = await dispatch(
          uploadNewAdditionalTestDocument(test, model.testDocuments)
        );

        if (createdFile) {
          dispatch(
            createCrudAction(Types.TEST_ADDITIONAL_DOCUMENT).addItem(
              createdFile
            )
          );
          dispatch(createAction(ADDITIONAL_DOCUMENT_SEARCH_APPLY));
        }
      }

      if (!createdFile) {
        dispatch(setRequestInProcess(false, "postTestDocumentAction"));
        return;
      }

      payload.document_id = createdFile.id;
      payload.test_id = test.id;
    }

    const documents = await getTestDocuments(payload.test_id);

    const documentsIris: string[] = documents.items
      .map(item => {
        if (item["@id"]) {
          return item["@id"];
        }
        else if (item.uuid) {
          return "/api/documents/" + item.uuid;
        }

        return null;
      })
      .filter(id => id !== null && id !== undefined);

    if (createdFile && createdFile.uuid) {
      const newIri = "/api/documents/" + createdFile.uuid;

      if (!documentsIris.includes(newIri)) {
        documentsIris.push(newIri);
      }
    }

    await putItem(test.id, {
      testDocuments: documentsIris
    } as any);

    dispatch(openSnackbar("Created", 4000));
    dispatch(messageModalClose());
    dispatch(reset(formName));
    dispatch(push(backRoute));

    dispatch(setRequestInProcess(false, "postTestDocumentAction"));
  } catch (error) {
    dispatch(setRequestInProcess(false, "postTestDocumentAction"));
    handleHttpErrors(error, dispatch);
  }
};

export const updateTestActionAndBookTester = (
  id,
  model,
  formName
) => async dispatch => {
  dispatch(
    updateTest(id, model, formName, data => {
      if (
        !!model.dateFrom &&
        !!model.dateTo &&
        data.status === TestStatus.STATUS_DRAFT
      ) {
        dispatch(
          setTestStatusAction(id, TestStatus.STATUS_SCHEDULED, data => {
            dispatch(
              push(`/client/schedule/booking/${model.project}/${data.id}`)
            );
          })
        );
      } else {
        dispatch(push(`/client/schedule/booking/${model.project}/${data.id}`));
      }
    })
  );
};

export type ISetTestStatusAction = (
  testId,
  status: TestStatus,
  callback?: (data) => any
) => any;

export const setTestStatusAction: ISetTestStatusAction = (
  testId,
  status,
  callback
) => async dispatch => {
  dispatch(setRequestInProcess(true, "setTestStatusAction"));

  let data;

  try {
    data = await (getRequestFromType(Types.TEST) as any).putItem(testId, {
      status
    });

    dispatch(createCrudAction(Types.TEST).addItem(data));

    if (callback) {
      callback(data);
    }
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, "setTestStatusAction"));
};

export type IChangeTestStatusAction = (
  testId,
  status: TestStatus,
  callback?: (data) => any
) => any;

export const changeTestStatusAction: IChangeTestStatusAction = (
  testId,
  status,
  callback
) => async dispatch => {
  dispatch(setRequestInProcess(true, "changeTestStatusAction"));

  let data;

  try {
    data = await changeTestStatus(testId, status);
    dispatch(createCrudAction(Types.TEST).addItem(data));

    if (callback) {
      callback(data);
    }
    dispatch(setRequestInProcess(false, "changeTestStatusAction"));
    return data;
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, "changeTestStatusAction"));
};

export const savePONumberAction = (PONumber, testId) => async dispatch => {
  dispatch(setRequestInProcess(true, "savePONumber"));

  let data;

  try {
    data = await savePONumberRequest(PONumber, testId);
    await dispatch(loadTest(testId));
    dispatch(setRequestInProcess(false, "savePONumber"));
    dispatch(openSnackbar("Purchase number updated"));
    return data;
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, "savePONumber"));
};

export const loadTest = id => async dispatch => {
  let data;

  try {
    data = await getItem(id);
    dispatch(createCrudAction(Types.TEST).addItem(data));
  } catch (error) {
    handleHttpErrors(error, dispatch);
    return;
  }
};

export const updateServiceDatesAction = ({
  dateFrom,
  dateTo,
  testId,
  callBack
}: UpdateServiceDateActionType) => async dispatch => {
  dispatch(setRequestInProcess(true, "updateServiceDatesAction"));

  try {
    await updateServiceDatesRequest({ dateFrom, dateTo, testId });
    await dispatch(loadTest(testId));
    callBack();
    dispatch(openSnackbar("Service dates updated"));
  } catch (error) {
    handleHttpErrors(error, dispatch);
  }

  dispatch(setRequestInProcess(false, "updateServiceDatesAction"));
};
