import * as React from 'react';
import {connect} from 'react-redux';
import {withStyles, Modal, Button, WithStyles} from '@material-ui/core';
import {defaultStyleWidth} from '../../../../styles/material/popup-style';
import {amendmentsModalClose} from "../../../../actions/modals";
import {getIsOpenModalAmendments, getModalAmendmentsItem} from "../../../../selectors/modal";
import {FieldGeneric} from '../../../../components/forms/FieldGeneric';
import {RouteComponentProps, withRouter} from "react-router-dom";
import {reduxForm, InjectedFormProps} from "redux-form";
import ReduxTextField from "../../../../components/forms/ReduxTextField";
import {amendmentsModalSubmit} from "../../../../actions/question";
import {setReportDocumentStatus} from "../../../../actions/report-documents";
import {AMENDMENTS_FORM_NAME} from "../../../../constants/forms";
import {IReportDocument} from "../../../../models/report-documents.interface";
import {IChangeTestStatusAction, changeTestStatusAction} from "../../../../actions/test";
import {TestStatus} from "../../../../constants/test-status";
import {ReportDocumentStatus} from "../../../../constants/report-document-status";
import Grid from "@material-ui/core/Grid";
import {composeValidators, fieldMaxLength, fieldsNotEmpty} from "../../../../helpers/validations/validation";

interface IStateToProps {
  isOpenModal: any;
  item: IReportDocument | false;
}

interface IDispatchToProps {
  modalClose: () => any;
  modalSubmit: (idTest, question) => any;
  requestAmendmentsForReport: (idReportDocument, reportDocument, status, formName) => any;
  setTestStatus: IChangeTestStatusAction;
}

class AmendmentsRequestModal extends React.Component<IStateToProps
  & IDispatchToProps
  & WithStyles<any>
  & RouteComponentProps<any>
  & InjectedFormProps<any>> {
  render() {
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.isOpenModal}
        onClose={this.handleClose}
      >
        <div className={this.props.classes.paper}>
          <div className="crud-sections-header">
            <h4>Clarification</h4>
          </div>
          <hr/>
          <form noValidate autoComplete="off" onSubmit={this.props.handleSubmit((values) => {
            this.props.modalSubmit(this.props.match.params.test, values.amendments);
            this.props.setTestStatus(this.props.match.params.test, TestStatus.STATUS_AMENDMENT_SENT);
            if (this.props.item) {
              const updatedItem = { ...this.props.item };

              if (updatedItem.document && typeof updatedItem.document === 'string') {
                updatedItem.document = updatedItem.document.replace('/api/documents/', '');
              }

              this.props.requestAmendmentsForReport(
                updatedItem.id,
                updatedItem,
                ReportDocumentStatus.STATUS_AMENDMENTS_REQUESTED,
                AMENDMENTS_FORM_NAME
              );
            }
          })}>
            <FieldGeneric
              name="amendments"
              rows={4}
              multiline={true}
              label="Details *"
              margin="normal"
              component={ReduxTextField}
            />
            <Grid container spacing={16}>
              <Grid item xs={6}>
                <Button fullWidth={true} type="submit" variant="contained" onClick={this.handleClose}>
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button fullWidth={true} type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Modal>
    );
  }

  private readonly handleClose = () => {
    this.props.modalClose();
    this.props.untouch('amendments');
    this.props.change('amendments', '');
  };
}

function mapStateToProps(state) {
  return {
    isOpenModal: getIsOpenModalAmendments(state),
    item: getModalAmendmentsItem(state),
  };
}

const connectedComponent = connect<IStateToProps, IDispatchToProps>(mapStateToProps as any, {
  modalClose: amendmentsModalClose,
  modalSubmit: amendmentsModalSubmit,
  requestAmendmentsForReport: setReportDocumentStatus,
  setTestStatus: changeTestStatusAction,
})(AmendmentsRequestModal);

const formed = reduxForm({
  form: AMENDMENTS_FORM_NAME,
  destroyOnUnmount: false,
  validate: composeValidators(
    fieldsNotEmpty(['amendments']),
    fieldMaxLength(['amendments'], 2000),
  ),
})(connectedComponent);

const routered = withRouter(formed as any);

export default withRouter(withStyles(defaultStyleWidth(320))(routered as any) as any) as any;
