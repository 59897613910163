import * as React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import Header from "containers/guest/Header";
import SideNav from "../common/SideNav";
import PrivateRoute from "components/auth/PrivateRoute";
import Dashboard from "./dashboard/Dashboard";
import {
  ROLE_ACCOUNT_MANAGER,
  ROLE_ADMIN,
  ROLE_OWNER,
  ROLE_PLANNER,
  ROLE_VIEWER
} from "constants/roles";
import Schedule from "./schedule/Schedule";
import BookingRequestConfirmation from "./booking-requests/BookingRequestСonfirmation";
import TestUpdate from "../client-tester/test-details/TestUpdate";
import TestDetails from "../client-tester/test-details/TestDetails";
import BookingRequests from "./booking-requests/BookingRequests";
import Test from "../client-tester/test-details/Test";
import Reports from "./reports/Reports";
import ClientAdmin from "./admin/ClientAdmin";
import ClientFaq from "./faq/ClientFaq";
import TestsAndProjects from "./test-and-projects/TestsAndProjects";
import Footer from "containers/guest/Footer";
import VirtualTeam from "./virtual-team/VirtualTeam";
import {fetchMyData} from "../../api/requests/auth";

class Client extends React.Component<RouteComponentProps<{}>, { isAvordFirst: boolean }> {
  state = {
    isAvordFirst: false
  };

  async componentDidMount() {
    const userProfile = await fetchMyData();
    this.setState({ isAvordFirst: userProfile.isAvordFirst });
  }

  render() {
    const baseUrl = this.props.match.path;
    const { isAvordFirst } = this.state;

    return (
      <div>
        <Header />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 col-xl-2 bg-white">
                <div className="">
                  <SideNav baseUrl={baseUrl} isAvordFirst={isAvordFirst} />
                </div>
              </div>
              <div className="col-lg-9 col-xl-10 content">
                <Switch>
                  <PrivateRoute
                    path="/client/virtual-team"
                    component={VirtualTeam}
                    roles={[ROLE_PLANNER, ROLE_OWNER]}
                  />
                  <PrivateRoute
                    path="/client/dashboard"
                    component={Dashboard}
                    role={ROLE_PLANNER}
                  />
                  <PrivateRoute
                    path="/client/schedule"
                    component={Schedule}
                    roles={[ROLE_PLANNER, ROLE_ADMIN]}
                  />
                  <PrivateRoute
                    path="/client/booking-requests/:test/booking-confirmation"
                    component={BookingRequestConfirmation}
                    role={ROLE_PLANNER}
                  />
                  <PrivateRoute
                    path="/client/booking-requests/:test/edit"
                    component={TestUpdate}
                    role={ROLE_PLANNER}
                  />
                  <PrivateRoute
                    path="/client/booking-requests/:test"
                    component={TestDetails}
                    role={ROLE_PLANNER}
                  />
                  <PrivateRoute
                    path="/client/booking-requests"
                    component={BookingRequests}
                    role={ROLE_PLANNER}
                  />
                  <PrivateRoute
                    path="/client/reports/:test"
                    component={Test}
                    roles={[ROLE_PLANNER, ROLE_VIEWER, ROLE_ADMIN, ROLE_ACCOUNT_MANAGER]}
                  />
                  <PrivateRoute
                    path="/client/reports/:test/executive-summary"
                    component={Test}
                    roles={[ROLE_PLANNER, ROLE_VIEWER]}
                  />
                  <PrivateRoute
                    path="/client/reports"
                    component={Reports}
                    role={ROLE_PLANNER}
                  />
                  <PrivateRoute
                    path="/client/admin"
                    component={ClientAdmin}
                    roles={[ROLE_OWNER, ROLE_PLANNER]}
                  />
                  <Route path="/client/faq" component={ClientFaq} />
                  <Route
                    path="/client/tests-and-projects"
                    component={TestsAndProjects}
                  />
                </Switch>
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Client;
