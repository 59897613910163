import {
  convertorCategoryFromSwagger,
  convertorCreateDailyTimeLogFromSwagger,
  convertorCreateDailyTimeLogToSwagger,
  convertorCreateWeeklyTimeLogToSwagger,
  convertorDayDataByIdFromSwagger,
  convertorEditDailyTimeLogToSwagger,
  convertorFromSwagger,
  convertorSearchFiltersToSwagger,
  convertSubmitSelectedWeeksToSwagger
} from "../convertors/time-and-expenses";
import { extractJSONLD, fetchRequestBody } from "../../helpers/request-helper";
import { getApi, getApiJSONLD } from "../swagger/api-factory";
import {
  DailyTimeExpensesLogRequest,
  EditDailyTimeExpensesLogRequestType,
  TimeSheetInvoiceLinkType,
  TimesheetPoNumberUpdateParams,
  TimeSheetsFilterParamsType,
  WeeklyTimeExpensesLogRequestType
} from "../../types/timesheets-and-expenses";

export const getTimeExpensesWeeksRequest = (
  params: TimeSheetsFilterParamsType
) => {
  return getApiJSONLD()
    .then(api =>
      api.getTimeExpensesWeekCollection(convertorSearchFiltersToSwagger(params))
    )
    .then(extractJSONLD(convertorFromSwagger));
};
export const getTimeExpensesWeekInvoiceRequest = async (
  id: number,
  type: TimeSheetInvoiceLinkType
) => {
  return getApiJSONLD()
    .then(api => api.get_time_expenses_weeks_invoice({ id, type }))
    .then(fetchRequestBody);
};

export const getTimesheetDayDataByIdRequest = async (id: string) => {
  return getApiJSONLD()
    .then(api => api.getTimesheetDayItem({ id }))
    .then(fetchRequestBody)
    .then(convertorDayDataByIdFromSwagger);
};

export const createDailyTimeExpensesLogRequest = async (
  params: DailyTimeExpensesLogRequest
) => {
  return getApiJSONLD()
    .then(api =>
      api.postTimesheetDayCollection(
        convertorCreateDailyTimeLogToSwagger(params)
      )
    )
    .then(extractJSONLD(convertorCreateDailyTimeLogFromSwagger));
};

export const createWeeklyTimeLogRequest = async (
  params: WeeklyTimeExpensesLogRequestType
) => {
  return getApiJSONLD()
    .then(api =>
      api.postTimesheetDayCollection(
        convertorCreateWeeklyTimeLogToSwagger(params)
      )
    )
    .then(fetchRequestBody);
};

export const editDailyTimeExpensesLogRequest = async (
  params: EditDailyTimeExpensesLogRequestType
) => {
  const a = convertorEditDailyTimeLogToSwagger(params);
  return getApiJSONLD()
    .then(api => api.putTimesheetDayItem(a))
    .then(fetchRequestBody)
    .then(convertorCreateDailyTimeLogFromSwagger);
};

export const deleteDailyTimeExpensesLogRequest = async (id: string) => {
  return getApiJSONLD()
    .then(api => api.deleteTimesheetDay({ id }))
    .then(fetchRequestBody);
};

export const postExpensesDocument = async (file: File) => {
  return getApi()
    .then(api => {
      return api.postDocumentCollection({ file });
    })
    .then(fetchRequestBody)
    .then(data => ({ document: `/api/documents/${data.uuid}` }));
};

export const updateSelectedWeeksStatusRequest = async params => {
  return getApi().then(api => {
    const p = convertSubmitSelectedWeeksToSwagger(params);
    return api.updateTimesheetExpensesWeeksStatus(
      convertSubmitSelectedWeeksToSwagger(params)
    );
  });
};

export const getTimeCategoriesRequest = async () => {
  return getApi()
    .then(api => {
      return api.getTimesheetTimeCategories();
    })
    .then(extractJSONLD(convertorCategoryFromSwagger));
};

export const getExpensesTypesRequest = async () => {
  return getApi()
    .then(api => {
      return api.getTimesheetExpensesTypes();
    })
    .then(extractJSONLD(convertorCategoryFromSwagger));
};

export const downloadPdfDocument = (data: string, name = "file") => {
  const downloadLink = document.createElement("a");
  const fileName = `${name}.pdf`;
  downloadLink.href = data;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const getTimesheetManagersRequest = async () => {
  return getApi()
    .then(api => {
      return api.getTimesheetManagersCollection();
    })
    .then(fetchRequestBody);
};

export const updateTimesheetPoNumberRequest = async (
  params: TimesheetPoNumberUpdateParams
) => {
  const { id, poNumberType, value } = params;
  return getApi()
    .then(api => {
      return api.updateTimesheetPoNumber({
        id,
        poNumber: { poNumberType, value }
      });
    })
    .then(fetchRequestBody);
};

export const getTimesheetWeekByIdRequest = async (id: string) => {
  return getApi()
    .then(api => {
      return api.getTimeExpensesWeekItem({ id });
    })
    .then(fetchRequestBody);
};
