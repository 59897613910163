import * as React from 'react';
import {connect} from 'react-redux';
import {withStyles, Modal, Typography, Button, WithStyles, MenuItem, Select} from '@material-ui/core';
import {defaultStyleWidth} from '../../../styles/material/popup-style';
import {getModalReasignId, getModalReassignGroup, getModalReassignTitle} from "../../../selectors/modal";
import {getIsOpenModalReasign} from "../../../selectors/modal";
import {reassignModalClose} from "../../../actions/modals";

import {IUsers} from "../../../models/users.interface";
import {reassignUserActions} from "../../../actions/users";
import {getUsersFilteredForReassign} from "../../../actions/reassign-users";
import reassignUsersSelector from "../../../selectors/reassign-users";
import {RouteComponentProps, withRouter} from "react-router";

interface IStateToProps {
  isOpenModal: any;
  modalTitle: string;
  data: IUsers[];
  id: number;
  modalGroup: string;
  onArchive: any;
  isArchive: boolean;
  setOnArchive: any;
}

interface IDispatchToProps {
  modalClose: () => any;
  getUsersFilteredForReassign: (...any) => any;
  reassignUserActions: (...any) => any;
}

class UsersReassignModal extends React.Component<
  IStateToProps
  & IDispatchToProps
  & WithStyles<any>
  & RouteComponentProps<any>
> {
  state = {
    value: 0,
  };

  handleChange = (event) => {
    this.setState({value: event.target.value});
  };

  componentWillMount(): void {
    if (!this.props.data.length) {
      this.props.getUsersFilteredForReassign(this.props.modalGroup);
    }
  }

  handleSubmit = () => {
    this.props.reassignUserActions(this.props.id, this.state.value, this.props.modalGroup);

    if(this.props.isArchive) {
      this.props.onArchive({ id: this.props.id, groupName: this.props.modalGroup });
      this.props.setOnArchive(false);
    }
  };

  render() {
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.isOpenModal}
        onClose={this.handleClose}
        className="d-flex overflow-auto"

      >
        <div className={`styled-modal ${this.props.isArchive ? "sm" : "md"}`}>
          <p>
            {this.props.modalTitle}
          </p>
          <hr/>
          <Select
            fullWidth
            className="mb-4"
            onChange={this.handleChange}
            value={this.state.value}
          >
            {this.props.data.map((item: IUsers)=> {
              return (
                item.id !== this.props.id &&
                  <MenuItem
                    key={item.id}
                    value={item.id}>
                    {item.email}
                  </MenuItem>
              )
            })}
          </Select>

          <Button variant="contained" onClick={this.handleClose}>
            CANCEL
          </Button>
          {this.props.isArchive ? (<Button variant="contained" color="primary" onClick={this.handleSubmit} style={{float: 'right'}}>
            REASSIGN AND CONTINUE
          </Button>):(
            <>
              <Button variant="contained" color="primary" onClick={this.handleSubmit} style={{float: 'right'}}>
                REASSIGN AND DELETE FOREVER
              </Button>
              <span className="or-text">OR</span>
              <Button variant="contained" color="primary" style={{float: 'right'}}>
                DELETE ALL DATA FOREVER
              </Button>
            </>
          )}
        </div>
      </Modal>
    );
  }

  private readonly handleClose = () => {
    this.props.setOnArchive(false);
    this.props.modalClose();
  };
}

function mapStateToProps(state, props: any) {
  return {
    isOpenModal: getIsOpenModalReasign(state),
    modalTitle: getModalReassignTitle(state),
    id: getModalReasignId(state),
    modalGroup: getModalReassignGroup(state),
    data: reassignUsersSelector.getItems(state),
    onArchive: props.onArchive,
    isArchive: props.isArchive,
    setOnArchive: props.setOnArchive,
  };
}

const connectedComponent = connect<IStateToProps, IDispatchToProps>(mapStateToProps, {
  modalClose: reassignModalClose,
  getUsersFilteredForReassign,
  reassignUserActions,
})(UsersReassignModal);

export default withRouter(withStyles(defaultStyleWidth(320))(connectedComponent as any) as any) as any;
