import React, { useState, useEffect } from "react";
import SelectAllApproved from "./SelectAllApproved";
import SearchForm from "./SearchForm";
import selector from "selectors/time-expenses";
import { connect } from "react-redux";
import UpdateSelectedWeeksStatusForm from "./forms/UpdateSelectedWeeksStatusForm";
import { TE_STATUS } from "constants/timesheet-expenses";
import { bindActionCreators, Dispatch } from "redux";
import { getAllProjects } from "actions/project";
import { getConsultantsRequestsAction } from "actions/consultants";
import { FilterParamsType } from "types/consultant";
import { ACTIVE } from "constants/filter";
import TimeSheetWeeksList from "./TimeSheetWeeksList";
import PermissionBlock from "components/auth/PermissionBlock";
import { Permission } from "constants/permission";
import Tooltip from "@material-ui/core/Tooltip";
import {
  ReducedWeekType,
  TimeExpensesWeekType
} from "../../../types/timesheets-and-expenses";

type AdminTimeSheetsComponentProps = {
  selectedProjectWeeks: number[];
  weeksItems: ReducedWeekType[];
  getAllProjects: () => void;
  getAllConsultants: (params: FilterParamsType) => void;
};

const AdminTimeSheetsComponent = (props: AdminTimeSheetsComponentProps) => {
  const tooltipText = 'Select only Submitted or only Approved items simultaneously';
  const { selectedProjectWeeks, weeksItems, getAllProjects, getAllConsultants } = props;
  const [isOpenedModal, setIsOpenedModal] = useState<boolean>(false);

  useEffect(() => {
    getAllProjects();
    getAllConsultants({ itemsPerPage: 999, show: ACTIVE });
  }, []);

  let allProjectsWeeks: TimeExpensesWeekType[] = [];
  weeksItems.map(({ projectsWeeks }) => {
    allProjectsWeeks = [...allProjectsWeeks, ...projectsWeeks];
  });

  const allApprovedWeeksIds: number[] = allProjectsWeeks
    .filter(week => week.status === TE_STATUS.STATUS_APPROVED)
    .map(({ id }) => id as number);

  const allSubmittedWeeksIds: number[] = allProjectsWeeks
    .filter(week => week.status === TE_STATUS.STATUS_SUBMITTED)
    .map(({ id }) => id as number);

  const isCheckedApprovedWeeks =
    !!selectedProjectWeeks.length &&
    selectedProjectWeeks.every(id => allApprovedWeeksIds.includes(id));

  const isCheckedSubmittedWeeks =
    !!selectedProjectWeeks.length &&
    selectedProjectWeeks.every(id => allSubmittedWeeksIds.includes(id));

  const isMixedProjectWeeks =
    !!selectedProjectWeeks.length &&
    !selectedProjectWeeks.every(id => allSubmittedWeeksIds.includes(id)) &&
    !selectedProjectWeeks.every(id => allApprovedWeeksIds.includes(id));

  const [isOpenedRejectAllModal, setIsOpenedRejectAllModal] = useState<boolean>(
    false
  );
  const [isOpenedApproveAllModal, setIsOpenedApproveAllModal] = useState<
    boolean
  >(false);

  return (
    <>
      <div className="d-flex justify-content-end pb-4">
        <PermissionBlock
          permissions={[Permission.CAN_MANAGE_TIME_EXPENSES]}
        >
          <Tooltip title={tooltipText} disableHoverListener={!isMixedProjectWeeks}>
            <button
              className="btn-reject mr-2 mb-3 mb-sm-0"
              onClick={() => setIsOpenedRejectAllModal(true)}
              disabled={!isCheckedSubmittedWeeks}
            >
              Reject Selected
            </button>
          </Tooltip>

          <Tooltip title={tooltipText} disableHoverListener={!isMixedProjectWeeks}>
            <button
              className="btn-accept mr-2"
              onClick={() => setIsOpenedApproveAllModal(true)}
              disabled={!isCheckedSubmittedWeeks}
            >
              Approve selected
            </button>
          </Tooltip>

          <Tooltip title={tooltipText} disableHoverListener={!isMixedProjectWeeks}>
            <button
              className="btn-accept"
              disabled={!isCheckedApprovedWeeks}
              onClick={() => setIsOpenedModal(true)}
            >
              Generate invoice
            </button>
          </Tooltip>
        </PermissionBlock>
      </div>
      <SearchForm />
      <PermissionBlock
        permissions={[Permission.CAN_MANAGE_TIME_EXPENSES]}
      >
        <SelectAllApproved />
      </PermissionBlock>
      <TimeSheetWeeksList asAdmin />
      {isOpenedModal && (
        <UpdateSelectedWeeksStatusForm
          closeModal={() => setIsOpenedModal(false)}
          confirmLabel="Yes"
          description="The invoices will be created for all selected weeks and projects:"
          status={TE_STATUS.STATUS_INVOICED}
        />
      )}
      {isOpenedRejectAllModal && (
        <UpdateSelectedWeeksStatusForm
          closeModal={() => setIsOpenedRejectAllModal(false)}
          confirmLabel="Reject"
          description="You are going to reject selected weeks. Please leave a comment if necessary."
          status={TE_STATUS.STATUS_REJECTED}
        />
      )}
      {isOpenedApproveAllModal && (
        <UpdateSelectedWeeksStatusForm
          closeModal={() => setIsOpenedApproveAllModal(false)}
          confirmLabel="Approve"
          description="You are going to approve selected weeks. Please leave a comment if necessary."
          status={TE_STATUS.STATUS_APPROVED}
        />
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    selectedProjectWeeks: selector.getSelectedProjectWeeks(state),
    weeksItems: selector.getTimeExpensesWeeks(state)
  };
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getAllProjects: getAllProjects,
      getAllConsultants: getConsultantsRequestsAction
    },
    dispatch
  );
};

const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminTimeSheetsComponent);

export default connected;
