import * as React from "react";
import StarRatingComponent from "react-star-rating-component";
import {Link, withRouter} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import {Zoom} from "@material-ui/core";
import {ITesterProfileGet} from "../../../models/tester-profile.interface";
import {getTesterLocationString} from "../../../helpers/location-helper";
import {BASE_URL} from "../../../constants/urls";
import {downloadSecureDocument, getUrlSecureDocument} from "../../../api/requests/document-download";
import {INSURANCE_VALUES_COUNT} from "../../../constants/insurance";
import {STATUS_VALIDATION_PASSED} from "../../../constants/dbs-certificate-statuses";
import {extractId} from "../../../helpers/converter-helper";
import CertificateModal from "./modal/CertificateModal";
import {openSnackbar} from "../../../actions/snackbar";
import {ITest} from "../../../models/test.interface";
import ExternalTestersView from "../../client/schedule/testers/ExternalTestersView";
import { ROLE_ACCOUNT_MANAGER } from "../../../constants/roles";
import { Permission } from "../../../constants/permission";
import { Group } from "../../../constants/group";

interface IOwnProps {
  test: ITest;
  item: ITesterProfileGet;
  countryUk: number;
  showSeeReviewsLink?: boolean;
  downloadSecureDocument: any;
}

interface IProps extends IOwnProps, RouteComponentProps<any> {
  getUrlSecureDocument: (id: number) => any;
  openSnackbar: (message: string, time?: number) => ({
    type: string,
    payload: any
  });
}

interface IState {
  isModalOpen: boolean;
  isCertificateModalOpen: boolean;
  modalImg: null | any;
  modalTitle: string;
}

class TestDetailsTesterCard extends React.Component<IProps, IState> {
  static defaultProps: Partial<IProps> = {
    showSeeReviewsLink: true
  };

  state = {
    isCertificateModalOpen: false,
    isModalOpen: false,
    modalImg: null,
    modalTitle: ""
  };

  needRenderUKBlock = false;

  downloadFile = event => {
    const evidenceDocumentId = event.currentTarget.dataset.evidenceid;

    if (evidenceDocumentId) {
      this.props.downloadSecureDocument(evidenceDocumentId);
    }
  };

  renderInsurancesValue = value => {
    return value === INSURANCE_VALUES_COUNT
      ? `more then £${INSURANCE_VALUES_COUNT - 1}m`
      : `£${value}m`;
  };

  canSeeDayRate = () => {
    const {auth} = this.props;

    if (auth.permissions.includes(Permission.CAN_MANAGE_TESTS) || auth.permissions.includes(Permission.CAN_VIEW_PLATFORM_INVOICES_AND_COSTS)) {
      return true;
    }

    return (
      auth.group === Group.OWNER ||
      auth.group === Group.PLANNER ||
      auth.group === Group.ADMIN ||
      auth.group === Group.SUPER_ADMIN
    );
  };

  renderCertificates = (certificateNames) => {
    return certificateNames.map((item, index) => {
      return (
        <span key={item.evidence}
              title={item.certificateType.fullName}
              onClick={this.openCertificateModal(
                item.evidence,
                item.certificateType.shortName + " - " + item.certificateType.fullName
              )}
        >
          <span className="tester-certificate">
            {item.certificateType.shortName}
          </span>
          {certificateNames.length - 1 === index ? "" : " | "}
        </span>
      );
    });
  };

  openCertificateModal = (evidenceIri, certificateName) => () => {
    if (!this.state.isCertificateModalOpen) {
      this.props.getUrlSecureDocument(extractId(evidenceIri)).then(response => {
        if (response) {
          this.setState({isCertificateModalOpen: true, modalImg: response, modalTitle: certificateName});
        } else {
          this.props.openSnackbar("You can not see certificates");
        }
      });
    }
  };

  closeCertificateModal = () => {
    this.setState({isCertificateModalOpen: false, modalImg: null});
  };

  getIdFromIri(iri?: string) {
    if (iri) {
      const arrIri = iri.split("/");

      return Number(arrIri[arrIri.length - 1]);
    }

    return null;
  }

  setNeedRenderUKBlock(): void {
    const {item, countryUk} = this.props;
    if (item) {
      if (this.getIdFromIri(item.country) === countryUk) {
        this.needRenderUKBlock = true;
      } else if (item.rightsToWorkCountries) {
        item.rightsToWorkCountries.map((country) => {
          if (this.getIdFromIri(country) === countryUk) {
            this.needRenderUKBlock = true;
          }
        });
      }
    }
  }

  render() {
    const {item} = this.props;
    this.setNeedRenderUKBlock();
    if (item && item.isOwnedByCompany) {
      return (
        <div className="styled-block">
          <ExternalTestersView
            key={item.id}
            item={item}
            hasBookingButton={false}
          />
        </div>
      )
    }

    return (
      <div className="styled-block">
        <CertificateModal
          open={this.state.isCertificateModalOpen}
          onClose={this.closeCertificateModal}
          src={this.state.modalImg}
          title={this.state.modalTitle}
        />
        <div className="tester-view pt-3">
          <Grid container spacing={16}>
            <Grid item xs={2} className="test-details-profile-picture">
              <div className="tester-view-picture">
                {!item.profileImagePath ||
                item.profileImagePath === null ||
                item.profileImagePath === undefined
                  ? (
                    <img
                      src="/images/user-large.svg"
                      alt={item.testerFirstName + " " + item.testerLastName}
                      className="rounded-circle img-fluid"
                    />
                  )
                  : (
                    <img
                      src={BASE_URL + item.profileImagePath}
                      alt={item.testerFirstName + " " + item.testerLastName}
                      className="rounded-profile-search img-fluid"
                    />
                  )
                }
              </div>
            </Grid>
            <Grid item xs={9} className="pl-4">
              <div className="d-flex justify-content-between word-break-all">
                <h2>{item.testerFirstName} {item.testerLastName}</h2>
                {item.user && !item.user.deletedAt && (
                <span className="day-rate">
                  {(item.rate > 0 && item.currencyInfo) && this.canSeeDayRate() &&
                    (
                      <span>
                          <strong>
                            {item.currencyInfo.symbol} {item.rate}
                          </strong>
                          <span>/day</span>
                      </span>
                    )
                  }
                  {(item.isFavorite && item.user && !item.user.deletedAt)
                    ? (
                      <Tooltip
                        placement="bottom-end"
                        TransitionComponent={Zoom}
                        disableFocusListener
                        disableTouchListener
                        title="Tester from your favorites"
                      >
                        <span className="btn-manage"/>
                      </Tooltip>
                    )
                    : null
                  }
                </span>
                ) }
              </div>

              {item.user && !item.user.deletedAt && (
              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <h5>Average rating</h5>
                  <div className="d-flex">
                    <StarRatingComponent
                      className="rating-stars"
                      name="rang"
                      starCount={5}
                      editing={false}
                      value={!!item.rank ? item.rank : 0}
                    />
                    <span className="star-rating-rank">
                    {!!item.rank ? Number((item.rank).toFixed(1)) : 0}
                  </span>
                  </div>
                  {!!this.props.showSeeReviewsLink && (
                    <Link
                      className="underlined-link"
                      to={`/client/schedule/booking/${this.props.match.params.project}/${this.props.match.params.test}/${item.id}/reviews`}>
                      See all reviews
                    </Link>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <h5>Location</h5>
                  <div>{getTesterLocationString(item)}</div>
                </Grid>
              </Grid>
              )}
            </Grid>

            {item.user && !item.user.deletedAt && (
            <>
              <Grid item xs={12}>
                <h5>Specialisms</h5>
                {(item.specialismsNames || []).map((item: string) => (
                  <span key={item} className="specialism-item">
                  {item}
                </span>
                ))}
              </Grid>
              <Grid item xs={6}>
                {item.insurancesData &&
                item.insurancesData.publicLiability &&
                item.insurancesData.indemnityInsurance &&
                this.needRenderUKBlock &&
                <React.Fragment>
                  <h5>
                    {"Insurance Value "}
                    <span title="Public liability covers physical damage caused by your work, e.g. injuries or property damage.
  Professional indemnity covers clients' financial losses.">What&nbsp;is&nbsp;it?</span>
                  </h5>
                  <p className="insurance">
                    <span>PL</span>{" "}
                    {this.renderInsurancesValue(item.insurancesData.publicLiability)}
                    {" "}/{" "}
                    <span>PI</span>{" "}
                    {this.renderInsurancesValue(item.insurancesData.indemnityInsurance)}
                  </p>
                </React.Fragment>
                }
              </Grid>
              <Grid item xs={6}>
                <h5>Qualified Experience</h5>
                <p>
                  {!!item.workExperience ? (
                    <span> {item.workExperience} year{+item.workExperience !== 1 && "s"}</span>) : (
                    <span>No experience</span>
                  )}
                </p>
              </Grid>
              <Grid item xs={12}>
                {(!!this.props.test.testerCertificates && !!this.props.test.testerCertificates.length) &&
                <React.Fragment>
                  <h5>Certifications</h5>
                  <p>{this.renderCertificates(this.props.test.testerCertificates)}</p>
                </React.Fragment>
                }
              </Grid>
              <Grid item xs={12}>
                <div className="dbs">
                  <ul className="dbs-list">
                    {item.checkRegisteredCompany && (
                      <li>CHECK registered company</li>
                    )}
                    {item.companyCrestCertificate && (
                      <li>CREST registered company</li>
                    )}
                    {item.isGovernmentCleared && <li>Government Cleared</li>}
                  </ul>
                  {item.dBSCheckMinStatus === STATUS_VALIDATION_PASSED && this.needRenderUKBlock && (
                    <img src="/images/DBS.svg" alt="DBS"/>
                  )}
                </div>
              </Grid>
            </>
            )}
          </Grid>
        </div>
      </div>
    );
  }
}

export default connect(
  (state: { auth }) => {
    return {
      auth: state.auth
    };
  },
  {
    downloadSecureDocument,
    openSnackbar,
    getUrlSecureDocument
  }
)(withRouter(TestDetailsTesterCard));
