import * as React from "react";
import Table from "@material-ui/core/Table/Table";
import TableBody from "@material-ui/core/TableBody/TableBody";
import {connect} from "react-redux";
import {
  clientBookingRequestRequest,
  clientBookingRequestLoader,
  getBookingRequestsAction
} from "../../../actions/client-booking-requests";
import selector from "../../../selectors/client-booking-requests";
import {TablePagination, Zoom} from "@material-ui/core";
import {isChangesExist} from "../../../helpers/props-checker";
import {IAnyProps} from "../../../interfaces/any-props.interface";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import debounce from "debounce";
import {ITest} from "../../../models/test.interface";
import {TestStatus} from "../../../constants/test-status";
import {formValueSelector} from "redux-form";
import {
  ALL_STATUSES_BOOKING_REQUESTS,
  BOOKING_REQUESTS_FORM_NAME,
} from "../../common/BookingRequestsSearchForm";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {Link} from "react-router-dom";
import * as _ from "lodash";
import {formatDateWithTime} from "../../../helpers/date-formatter";
import TableHead from "@material-ui/core/TableHead";
import StatusBlock from "../../common/StatusBlock";
import {HashLink} from "react-router-hash-link";
import TesterTableCell from "../../common/TesterTableCell";
import {putItem, deleteFavorite} from "../../../api/requests/favorite-testers";
import Tooltip from "@material-ui/core/Tooltip";
import { openSnackbar } from "../../../actions/snackbar";
import {bindActionCreators} from "redux";
import Icon from "@material-ui/core/Icon/Icon";
import TableAction from "../reports/TableAction";
import { fetchMyData } from "../../../api/requests/auth"; // Import the service to get user profile

const searchFormSelector = formValueSelector(BOOKING_REQUESTS_FORM_NAME);

interface IOwnProps {
  baseUrl: string;
  data: ITest[];
  isAvordFirst: boolean;

  [key: string]: any;

  searchValues: {
    project: number[];
    status: string[];
    dateFrom: string;
    dateTo: string;
    questionAsked: boolean;
  };
}

type IProps = IOwnProps & RouteComponentProps<any>;

class BookingRequestsList extends React.Component<IProps, { isAvordFirst: boolean }> {
  state = {
    isAvordFirst: false
  };

  debouncedLoad = debounce(nextProps => {
    this.loadItems(nextProps);
  }, 500);

  loadItems = props => {
    props.loadItems(
      props.pageNumber,
      props.itemsPerPage,
      props.searchValues.project,
      !!props.searchValues.status && props.searchValues.status.length
        ? props.searchValues.status
        : ALL_STATUSES_BOOKING_REQUESTS,
      props.searchValues.dateFrom,
      props.searchValues.dateTo,
      props.searchValues.questionAsked
    );
  };

  onArchive = item => {
    this.props.archiveItem(
      item.id,
      this.props.pageNumber,
      this.props.itemsPerPage,
      this.props.showStatus,
      this.props.filterText
    );
  };

  async componentDidMount() {
    const userProfile = await fetchMyData();
    this.setState({ isAvordFirst: userProfile.isAvordFirst });
    this.loadItems(this.props);
  }

  componentWillReceiveProps(nextProps: IProps) {
    if (_.isEmpty(this.props.searchValues)) {
      return;
    }

    if (
      isChangesExist(
        ["pageNumber", "itemsPerPage", "showStatus", "filterText"],
        nextProps,
        this.props
      ) ||
      isChangesExist(
        ["project", "status", "dateFrom", "dateTo", "questionAsked"],
        nextProps.searchValues,
        this.props.searchValues
      )
    ) {
      this.loadItems(nextProps);
    }
  }

  handleOnClick = (tester) => (event) => {
    if (tester && !!tester.id) {
      if (tester.isFavorite) {
        deleteFavorite(tester.id)
        .then(()=>{
          this.debouncedLoad(this.props);
          this.props.showSuccessMessage("Tester successfully removed from favorites");
        });
      } else {
        putItem(tester.id)
        .then(()=>{
          this.debouncedLoad(this.props);
          this.props.showSuccessMessage("Tester successfully added to favorites");
        });
      }
    }
  };

  handleChangePage = (event, page) => {
    this.props.setPageNumber(page + 1);
  };

  handleChangeRowsPerPage = event => {
    this.props.setItemsPerPage(event.target.value);
  };

  getStatusAction = (item: ITest) => {
    if (item.status === TestStatus.STATUS_PROPOSAL_SUBMITTED) {
      return (
        <Link to={`/client/booking-requests/${item.id}`}>
          <button className="btn-view" type="submit">
            View&nbsp;Proposal
          </button>
        </Link>
      );
    }

    if (
      item.status === TestStatus.STATUS_BOOKING_REJECTED_BY_TESTER ||
      item.status === TestStatus.STATUS_BOOKING_REJECTED_BY_CUSTOMER ||
      item.status === TestStatus.STATUS_PROPOSAL_SUBMITTED_CANCELLED_BY_TESTER
    ) {
      return (
        <Link to={`/client/schedule/${item.id}/copy`}>
          {
            (item.tester && !item.tester.isFavorite) ? (
              <button type="submit" className="btn-book-a-tester-small">
                Book&nbsp;a&nbsp;Tester
              </button>
            ) : (
              <button type="submit" className="btn-book-a-tester-small">
                Book&nbsp;a&nbsp;Tester
              </button>
            )
          }
        </Link>
      );
    }

    if (item.status === TestStatus.STATUS_BOOKED) {
      return (
        <Link to={`/client/reports/${item.id}/details`}>
          <button className="btn-view" type="submit">
            View&nbsp;Details
          </button>
        </Link>
      );
    }

    return (
      <Link to={`/client/booking-requests/${item.id}`}>
        <button className="btn-view" type="submit">
          View&nbsp;Details
        </button>
      </Link>
    );
  };

  getStatusActionForComponent = (item: ITest, component) => {
    if (item.status === TestStatus.STATUS_PROPOSAL_SUBMITTED) {
      return (
        <HashLink to={`/client/booking-requests/${item.id}#questions`}>
          {component}
        </HashLink>
      );
    }

    if (
      item.status === TestStatus.STATUS_BOOKING_REJECTED_BY_TESTER ||
      item.status === TestStatus.STATUS_BOOKING_REJECTED_BY_CUSTOMER ||
      item.status === TestStatus.STATUS_PROPOSAL_SUBMITTED_CANCELLED_BY_TESTER
    ) {
      return <Link to={`/client/schedule/test/${item.id}`}>{component}</Link>;
    }

    return (
      <HashLink to={`/client/booking-requests/${item.id}#questions`}>
        {component}
      </HashLink>
    );
  };

  refreshItems = () => {
    this.loadItems(this.props);
  };

  getWrapperStyle = (item: ITest) => this.getStatusAction(item) === null ? 'justify-content-end' : 'justify-content-between'

  render() {
    if (!this.props.isLoaded) {
      return (
        <div className="styled-block">
          <h4 className="block-header mb-4">Last Booking Requests</h4>
          Loading...
        </div>
      );
    }

    if (!this.props.data.length) {
      return (
        <div className="styled-block">
          <h4 className="block-header mb-4">Last Booking Requests</h4>
          There are no items!
        </div>
      );
    }

    return (
      <div className="styled-block">
        <h4 className="block-header mb-4">Last Booking Requests</h4>
        <div className="table-responsive styled-table">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Start</TableCell>
                <TableCell>Project</TableCell>
                {!this.state.isAvordFirst && <TableCell>Virtual<br/>Team</TableCell>}
                <TableCell>Consultant/Tester</TableCell>
                <TableCell>PO number</TableCell>
                <TableCell className="text-center">Need Answer</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.data.map(item => {
                return (
                  <TableRow key={item.id}>
                    <TableCell>
                      <Link to={`/client/booking-requests/${item.id}`}>
                        {item.hash + "-" + item.name}
                      </Link>
                      <div>
                        {item.dateFrom && formatDateWithTime(item.dateFrom)}
                      </div>
                    </TableCell>
                    <TableCell>{item.projectName}</TableCell>
                    {!this.state.isAvordFirst && (
                      <TableCell onClick={this.handleOnClick(item.tester)}>
                        {!!item.tester && item.tester.user && !item.tester.user.deletedAt && (
                          <div className="tester-image-name">
                            {(item.tester.isFavorite)
                              ? (
                                <Tooltip
                                  placement="bottom-end"
                                  TransitionComponent={Zoom}
                                  disableFocusListener
                                  disableTouchListener
                                  title="Remove from favorites"
                                >
                                  <span className="btn-manage"/>
                                </Tooltip>
                              )
                              : (
                                <Tooltip
                                  placement="bottom-end"
                                  TransitionComponent={Zoom}
                                  disableFocusListener
                                  disableTouchListener
                                  title="Add to favorites"
                                >
                                  <span className="btn-manage-inactive"/>
                                </Tooltip>
                              )}
                          </div>
                        )}
                      </TableCell>
                    )}
                    <TesterTableCell tester={item.tester} isAvordFirst={this.state.isAvordFirst}/>
                    <TableCell>
                      {!item.purchaseOrderNumber ? (
                              <div style={{marginLeft: "25px"}}>
                                <Tooltip
                                  placement="bottom-end"
                                  TransitionComponent={Zoom}
                                  disableFocusListener
                                  disableTouchListener
                                  title="Purchase Order number missing"
                                >
                                  <Icon style={{color:"#FF0000"}}>games_icon</Icon>
                                </Tooltip>
                              </div>
                      ) : (
                          <div style={{maxWidth:"100px", wordBreak: "break-all"}}>
                            {item.purchaseOrderNumber}
                          </div>
                      )}
                    </TableCell>
                    <TableCell className="text-center">
                      {item.testStatisticData
                        .unAnsweredQuestionsCountForPlanner > 0 &&
                      this.getStatusActionForComponent(
                        item,
                        <span className="notification">
                            {
                              item.testStatisticData
                                .unAnsweredQuestionsCountForPlanner
                            }
                          </span>
                      )}
                    </TableCell>
                    <TableCell>
                      {item.status && <StatusBlock status={item.status}/>}
                    </TableCell>
                    <TableCell>
                      <div className={`d-flex align-items-center ${this.getWrapperStyle(item)}`}>
                        {this.getStatusAction(item)}
                        <TableAction
                            item={item}
                            refreshItems={this.refreshItems}
                        />
                      </div>
                      </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={this.props.totalItems}
            rowsPerPage={this.props.itemsPerPage}
            page={this.props.pageNumber - 1}
            backIconButtonProps={{
              "aria-label": "Previous Page"
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page"
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </div>
      </div>
    );
  }
}

const routered = withRouter(BookingRequestsList);

export default connect<IAnyProps, IAnyProps, IAnyProps>(
  state => {
    return {
      data: selector.getItems(state),
      isLoaded: selector.getIsLoaded(state),
      itemsPerPage: selector.getItemsPerPage(state),
      pageNumber: selector.getPageNumber(state),
      showStatus: selector.getFilterActivityStatus(state),
      totalItems: selector.getTotalItems(state),
      filterText: selector.getFilterTextFields(state),
      searchValues: searchFormSelector(
        state,
        "project",
        "status",
        "dateFrom",
        "dateTo",
        "questionAsked"
      ),
    };
  },
  dispatch => ({
    loadItems: bindActionCreators(getBookingRequestsAction, dispatch),
    setItemsPerPage: bindActionCreators(clientBookingRequestLoader.setItemsPerPage,dispatch),
    setPageNumber: bindActionCreators(clientBookingRequestLoader.setPageNumber,dispatch),
    archiveItem: bindActionCreators(clientBookingRequestRequest.archiveItem,dispatch),
    showSuccessMessage: (message)=>dispatch(openSnackbar(message, 4000)),
  })
)(routered);
