import React, { useState, useContext, useEffect, ReactNode } from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import routerModal from "components/routerModal";
import { bindActionCreators, Dispatch } from "redux";
import { TimeSheetExpensesContext } from "../context/timeSheetsContext";
import { getTimeLogModalTitle } from "helpers/date-formatter";
import {
  clearSelectedDayDataAction,
  getTimesheetDayDataByIdAction
} from "actions/time-expenses";
import {
  DayByIdActionType,
  TimeExpensesCategory
} from "types/timesheets-and-expenses";
import selector from "selectors/time-expenses";
import {
  getCategoryNameById,
  getWeekDayData
} from "helpers/timesheets-expenses";
import { downloadDocumentFile } from "api/requests/document-download";
import { getExpensesTypeById } from "api/requests/timesheets-expenses-types";

interface DailyTimeExpensesViewModalProps
  extends RouteComponentProps<{ id: string }> {
  getTimesheetDayDataByIdAction: (id: string) => void;
  clearSelectedDayDataAction: () => void;
  downloadDocumentFile: (id: string) => void;
  selectedDayData: DayByIdActionType | null;
  timeCategoriesList: TimeExpensesCategory[];
  expensesTypesList: TimeExpensesCategory[];
  consultant: string;
}

type TimeExpensesItemProps = {
  label: string;
  children: ReactNode;
};
const TimeExpensesItem = ({ label, children }: TimeExpensesItemProps) => {
  if (!children) {
    return null;
  }

  return (
    <p className="time-expenses-view-item mb-2">
      <span>
        <b>{label}</b>
      </span>
      <span className="time-expenses-view-item__value"> {children}</span>
    </p>
  );
};

const DailyTimeExpensesViewModal: React.FC<DailyTimeExpensesViewModalProps> = ({
  match,
  getTimesheetDayDataByIdAction,
  clearSelectedDayDataAction,
  selectedDayData,
  downloadDocumentFile,
  timeCategoriesList,
  expensesTypesList,
  consultant
}) => {
  const dayId = match.params.id;
  const [dayTitle, setDayTitle] = useState<string>("");
  const [isReadyToRender, setIsReadyToRender] = useState<boolean>(false);
  const { goBackHandler, isConsultant } = useContext(TimeSheetExpensesContext);

  const timeCategory = selectedDayData?.timeCategory
    ? getCategoryNameById(selectedDayData.timeCategory, timeCategoriesList)
    : "";
  const expensesType = selectedDayData?.expensesType
    ? getCategoryNameById(selectedDayData.expensesType, expensesTypesList)
    : "";
  const expensesAmount = selectedDayData?.expensesAmount
    ? `£ ${selectedDayData.expensesAmount}`
    : "";
  const vat = selectedDayData?.vat ? `£ ${selectedDayData.vat}` : "";

  useEffect(() => {
    getTimesheetDayDataByIdAction(dayId);
    return clearSelectedDayDataAction;
  }, []);

  useEffect(() => {
    if (selectedDayData) {
      const { day } = selectedDayData;
      const { date } = getWeekDayData(day);
      setDayTitle(getTimeLogModalTitle(date));
      setIsReadyToRender(true);
    }
  }, [selectedDayData]);

  const renderComponent = !isReadyToRender ? (
    <>Loading...</>
  ) : (
    <div>
      <h4>{dayTitle}</h4>
      <hr />
      {consultant && !isConsultant && (
        <div className="timesheet-form-section mb-0">
          <h5>Consultant</h5>
          <p className="mb-0 string-break-word">{consultant}</p>
        </div>
      )}
      {selectedDayData?.days && (
        <div className="timesheet-form-section mb-0">
          <h5>Time</h5>
          <TimeExpensesItem label="Days:">
            {selectedDayData?.days}
          </TimeExpensesItem>
          <TimeExpensesItem label="Time category:">
            {timeCategory}
          </TimeExpensesItem>
        </div>
      )}
      {selectedDayData?.expensesAmount && (
        <div className="timesheet-form-section mb-0">
          <h5>Expenses</h5>
          <TimeExpensesItem label="Expenses amount:">
            {expensesAmount}
          </TimeExpensesItem>
          {expensesType && (
            <TimeExpensesItem label="Expenses type:">
              {expensesType}
            </TimeExpensesItem>
          )}
          <TimeExpensesItem label="VAT:">{vat}</TimeExpensesItem>
        </div>
      )}
      {selectedDayData?.documents && !!selectedDayData.documents.length && (
        <div className="timesheet-form-section mb-0">
          <h5>Documents</h5>
          {selectedDayData.documents.map(({ document, type }) => {
            return (
              <div key={document.id} className="view-document-item">
                <TimeExpensesItem label="Expenses type:">
                  {getCategoryNameById(type, expensesTypesList)}
                </TimeExpensesItem>
                <button
                  className="btn-download"
                  onClick={() => downloadDocumentFile(document.id)}
                >
                  <span>{document.name}</span>
                </button>
              </div>
            );
          })}
        </div>
      )}
      {selectedDayData?.comment && (
        <div className="timesheet-form-section">
          <h5>Comment</h5>
          <p className="mb-0 string-break-word">{selectedDayData.comment}</p>
        </div>
      )}
      <hr />
      <div className="timesheet-form-section d-flex justify-content-end mb-0">
        <button className="btn-cancel" onClick={goBackHandler}>
          Close
        </button>
      </div>
    </div>
  );

  return <div className="timesheet-form">{renderComponent}</div>;
};

function mapStateToProps(state) {
  let consultant = "";
  const selectedDay: DayByIdActionType | null = selector.getSelectedDayData(
    state
  );
  if (selectedDay) {
    consultant = selector.getConsultantNameByConsultantProfileId(
      +selectedDay.consultant
    )(state);
  }
  return {
    selectedDayData: selectedDay,
    timeCategoriesList: selector.getTimeCategoriesList(state),
    expensesTypesList: selector.getExpensesTypesList(state),
    consultant
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getTimesheetDayDataByIdAction,
      clearSelectedDayDataAction,
      downloadDocumentFile
    },
    dispatch
  );
};
const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(DailyTimeExpensesViewModal);

export default routerModal(connected);
