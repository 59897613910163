import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import {Button} from "@material-ui/core";
import {ClientAdditionalDocumentsRowActions, ClientAdditionalDocumentsRowProps} from '../../../../../types';
import {formatDateWithTime} from "../../../../../helpers/date-formatter";
import {extractId} from "../../../../../helpers/converter-helper";
import {connect} from "react-redux";
import {IAnyProps} from "../../../../../interfaces/any-props.interface";
import {downloadDocumentFile} from "../../../../../api/requests/document-download";
import {bindActionCreators} from "redux";

//TODO fix the component props type
const AdditionalDocumentsRow = ({additionalDocument, downloadDocumentFile}) => {
  const {name, documentUploadedAt, document:documentLink} = additionalDocument;
  const documentId = documentLink.split("/").pop();

  return (
    <TableRow>
      <TableCell>
        {name}
      </TableCell>
      <TableCell>
        {formatDateWithTime(documentUploadedAt)}
      </TableCell>
      <TableCell align="right" style={{maxWidth: 100}}>
        <Button variant="contained" color="primary" onClick={() => downloadDocumentFile(documentId)}>Download</Button>
      </TableCell>
    </TableRow>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      downloadDocumentFile,
    },
    dispatch
  );
};

const connected = connect<IAnyProps, ClientAdditionalDocumentsRowActions, ClientAdditionalDocumentsRowProps>(null, mapDispatchToProps)(AdditionalDocumentsRow);

export default connected;
