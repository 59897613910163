import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  getIsLoadedPermissions,
  getRoles,
  getUserGroup,
  getUserPermissions
} from 'selectors/auth';
import { connect } from 'react-redux';
import { openSnackbar } from 'actions';
import { push } from "react-router-redux";
import { Permission } from "constants/permission";
import { difference } from 'lodash';
import * as Storage from "helpers/storage-helper";
import { REFRESH_TOKEN_KEY } from "constants/storage";
import { ROLE } from "constants/roles";

interface IStateProps {
  currentRoles: string[];
  isLoadedPermissions: boolean;
  userPermissions: Permission[];
  userGroup: string;
}

interface IDispatchProps {
  showNotice: (msg: string, time: number) => void;
  redirect: (url: string) => void;
}

interface IOwnProps {
  component: any;
  role?: ROLE;
  roles?: ROLE[];
  roles_with_permissions?: { [role: string]: Permission[] };
  redirectPath?: string;
  permissions?: Permission[];
  group?: string;

  [key: string]: any;
}

function getNotificationMessage() {
  let noticeMessage = 'Sorry, you don\'t have permissions to do this action.';

  if (!Storage.getValue(REFRESH_TOKEN_KEY)) {
    noticeMessage = ' Session expired!';
  }
  return noticeMessage;
}

class PrivateRoute extends React.Component<IOwnProps & IStateProps & IDispatchProps, {}> {
  get isAllowed() {
    const {
      permissions,
      userPermissions,
      group,
      userGroup,
      roles,
      role,
      currentRoles,
      roles_with_permissions,
    } = this.props;

    if (permissions && difference(permissions, userPermissions).length) {
      return false;
    }

    if (group && group !== userGroup) {
      return false;
    }

    if (roles_with_permissions) {
      for (const [roleKey, requiredPermissions] of Object.entries(roles_with_permissions)) {
        if (currentRoles.includes(roleKey as ROLE)) {
          const hasAllPermissions = requiredPermissions.every((perm) =>
            userPermissions.includes(perm)
          );
          if (!hasAllPermissions) {
            return false;
          }
        }
      }
    }

    if (roles || role) {
      return this.getMergedRoles().some((roleSeek: ROLE) => currentRoles.indexOf(roleSeek) !== -1);
    }

    return true;
  }

  render() {
    const {...rest} = this.props;

    if (!this.props.isLoadedPermissions) {
      return (
        <div className="page-content">
          <div className="container">
            Loading...
          </div>
        </div>
      );
    }


    /** Show information popup */
    if (!this.isAllowed) {
      const path = this.props.redirectPath || '/';

      this.props.showNotice(getNotificationMessage(), 6000);
      this.props.redirect(path);

      return <Redirect to={{
        pathname: path,
      }} />;
    }

    return (
      <Route {...rest} />
    );
  };

  private getMergedRoles() {
    const mergedRoles: ROLE[] = this.props.role ? [this.props.role] : [];

    if (this.props.roles && this.props.roles?.length > 0) {
      mergedRoles.push(...this.props.roles);
    }

    return mergedRoles;
  }
}

function mapStateToProps(state): IStateProps {
  return {
    currentRoles: getRoles(state),
    isLoadedPermissions: getIsLoadedPermissions(state),
    userPermissions: getUserPermissions(state),
    userGroup: getUserGroup(state),
  };
}

export default connect<IStateProps, IDispatchProps, IOwnProps>(mapStateToProps, {
  showNotice: openSnackbar,
  redirect: push,
})(PrivateRoute);
